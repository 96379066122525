import { Text, css, styled } from '@yarmill/component-library';

export const UploadFormAgreementHeadline = styled(Text)`
  ${({ theme }) => css`
      margin-bottom: ${theme.size.x25};
  `};
`;

export const AgreementLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  ${({ theme }) => css`
    row-gap: ${theme.size.x25};
    margin-bottom: ${theme.size.x4};
  `};
`;

export const Radio2LabelLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  ${({ theme }) => css`
    row-gap: ${theme.size.x1};
  `};
`;
