import { Check, Dot, PriorityLow, Question } from '@yarmill/icon-library';
import { ReactElement } from 'react';
import { ThemeDefinition, css, styled } from './theme-provider';

export enum BadgeAppearance {
  hint = 'hint',
  good = 'good',
  error = 'error',
  recommendation = 'recommendation',
  ready = 'ready',
  read = 'read',
}

type BadgeSize = 16 | 12;

interface BadgeProps {
  readonly appearance: BadgeAppearance;
  readonly size: BadgeSize;
  readonly icon?: ReactElement;
  readonly isActive?: boolean;
}

function getBackgroundColorForAppearance(
  appearance: BadgeAppearance,
  theme: ThemeDefinition,
  isActive: boolean
) {
  switch (appearance) {
    case BadgeAppearance.hint:
      return isActive
        ? theme.color.brand_yarmill
        : theme.color.neutral_neutral_36;
    case BadgeAppearance.good:
      return theme.color.other_green;
    case BadgeAppearance.error:
      return theme.color.other_red;
    case BadgeAppearance.recommendation:
      return theme.color.other_sand;
    case BadgeAppearance.read:
      return theme.color.brand_yarmill_35;
    case BadgeAppearance.ready:
      return theme.color.brand_yarmill;
  }
}

function getColorForAppearance(
  appearance: BadgeAppearance,
  theme: ThemeDefinition,
  isActive: boolean
) {
  switch (appearance) {
    case BadgeAppearance.hint:
      return isActive
        ? theme.color.brand_yarmill_20
        : theme.color.neutral_neutral_2;
    case BadgeAppearance.good:
      return theme.color.other_green_8;
    case BadgeAppearance.error:
      return theme.color.other_red_8;
    case BadgeAppearance.recommendation:
      return theme.color.other_sand_8;
    case BadgeAppearance.read:
      return theme.color.brand_yarmill_35;
    case BadgeAppearance.ready:
      return theme.color.brand_yarmill;
  }
}

function getIconForAppearance(appearance: BadgeAppearance) {
  switch (appearance) {
    case BadgeAppearance.hint:
    case BadgeAppearance.recommendation:
      return <Question />;
    case BadgeAppearance.good:
      return <Check />;
    case BadgeAppearance.error:
      return <PriorityLow />;
    case BadgeAppearance.ready:
    case BadgeAppearance.read:
      return <Dot />;
  }
}

const Layout = styled.div<{
  readonly $appearance: BadgeAppearance;
  readonly $size: BadgeSize;
  readonly $isActive: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ theme, $appearance, $size, $isActive }) => css`
    color: ${getColorForAppearance($appearance, theme, $isActive)};
    background-color: ${getBackgroundColorForAppearance($appearance, theme, $isActive)};
    width: ${$size === 16 ? 16 : 12}px;
    height: ${$size === 16 ? 16 : 12}px;

    svg {
      width: ${$size === 16 ? 12 : 8}px;
      height: ${$size === 16 ? 12 : 8}px;
    }
  `};
`;

export function Badge({
  appearance,
  size,
  icon,
  isActive = false,
}: BadgeProps) {
  return (
    <Layout $appearance={appearance} $size={size} $isActive={isActive}>
      {icon ?? getIconForAppearance(appearance)}
    </Layout>
  );
}
