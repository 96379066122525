import { TagInputOption } from '@yarmill/component-library';
import { IntlShape } from 'react-intl';
import { AGE_GROUPS, SPORTS, SUBJECTS, TAGS, TagFormField } from '../types';
import { getTagAppearance } from './get-tag-appearance';

export function getTagsForField(
  field: TagFormField,
  intl: IntlShape
): TagInputOption<string>[] {
  if (field === 'ageCategories') {
    return AGE_GROUPS.map(ageCategories => ({
      value: ageCategories,
      label: intl.formatMessage({
        id: `metodej.document.form.field.ageCategories.${ageCategories}`,
      }),
      appearance: getTagAppearance('ageCategories'),
    }));
  } else if (field === 'sports') {
    return SPORTS.map(sport => ({
      value: sport,
      label: intl.formatMessage({
        id: `metodej.document.form.field.sports.${sport}`,
      }),
      appearance: getTagAppearance('sports'),
    })).sort((a, b) => a.label.localeCompare(b.label, intl.locale));
  } else if (field === 'subjects') {
    return SUBJECTS.map(subject => ({
      value: subject,
      label: intl.formatMessage({
        id: `metodej.document.form.field.subjects.${subject}`,
      }),
      appearance: getTagAppearance('subjects'),
    })).sort((a, b) => a.label.localeCompare(b.label, intl.locale));
  } else if (field === 'tags') {
    return TAGS.map(tag => ({
      value: tag,
      label: intl.formatMessage({
        id: `metodej.document.form.field.tags.${tag}`,
      }),
      appearance: getTagAppearance('tags'),
    })).sort((a, b) => a.label.localeCompare(b.label, intl.locale));
  }

  return [];
}
