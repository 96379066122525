import { Button, ButtonAppearance } from '@yarmill/component-library';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DuckSkates, DuckTenis } from '../components';
import { InfoPageLayout } from '../components/info-page-layout';
import { getMetodejUrl } from '../utils/get-metodej-url';

export const EmptyLibrary = observer(function EmptyLibrary() {
  const intl = useIntl();
  const currentUser = useCurrentUserStore();
  const isAllowedToUpload = currentUser.isAllowedTo('metodej.upload');

  return (
    <InfoPageLayout
      showMarginTop
      headline={intl.formatMessage({
        id: `metodej.library.emptyLibrary.${isAllowedToUpload ? 'gestor' : 'user'}.headline`,
      })}
      text={intl.formatMessage<any, any>({
        id: `metodej.library.emptyLibrary.${isAllowedToUpload ? 'gestor' : 'user'}.text`,
      })}
      image={isAllowedToUpload ? <DuckTenis /> : <DuckSkates />}
      button={
        isAllowedToUpload ? (
          <Button
            as={Link}
            to={getMetodejUrl('upload')}
            appearance={ButtonAppearance.Primary}
          >
            {intl.formatMessage({
              id: 'metodej.library.emptyLibrary.goToUploadButtonLabel',
            })}
          </Button>
        ) : undefined
      }
    />
  );
});
