import { ReactElement } from 'react';
import { Radio, RadioProps } from './radio';
import { Text } from './text';
import { css, styled } from './theme-provider';

interface RadioInputProps extends Omit<RadioProps, 'aria-labelledby'> {
  readonly label: string | ReactElement;
  readonly id: string;
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme }) => css`
    column-gap: ${theme.size.x15};
  `};
`;

export function RadioInput({ label, id, ...radioProps }: RadioInputProps) {
  const labelId = `${id}-label`;

  return (
    <Layout>
      <Radio {...radioProps} id={id} aria-labelledby={labelId} size={2} />
      <Text appearance="_12M" as="label" id={labelId} htmlFor={id}>
        {label}
      </Text>
    </Layout>
  );
}
