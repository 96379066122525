import {
  Button,
  ButtonAppearance,
  Popover,
  TextInput,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';

interface FilterDocumentsListPopoverProps {
  readonly closePopover: () => void;
}
export const FilterDocumentsListPopover = observer(
  function FilterDocumentsListPopover({
    closePopover,
  }: FilterDocumentsListPopoverProps) {
    const intl = useIntl();
    const libraryStore = useDocumentLibraryStore();
    const [value, setValue] = useState<string>(
      libraryStore.documentFilter ?? ''
    );
    function applyFilter() {
      libraryStore.documentFilter = value || null;
      closePopover();
    }

    return (
      <form onSubmit={applyFilter}>
        <Popover
          label={intl.formatMessage({
            id: 'metodej.form.upload.filterDocumentsList.headline',
          })}
          buttons={
            <>
              <Button appearance={ButtonAppearance.Primary} type="submit">
                {intl.formatMessage({
                  id: 'metodej.form.upload.filterDocumentsList.apply',
                })}
              </Button>
            </>
          }
          noMinHeight
        >
          <TextInput
            autoFocus
            name="filter-documents"
            id="filter-documents"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </Popover>
      </form>
    );
  }
);
