import { useConfig } from '@yarmill/utils';
import { useEffect } from 'react';
import { api } from '../metodej-api';

export function useMetodejBaseApiUrl() {
  const metodejBaseApiUrl = useConfig('metodejBaseApiUrl');
  useEffect(() => {
    api.setBaseUrl(metodejBaseApiUrl);
  }, [metodejBaseApiUrl]);
}
