import { Text, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';
import { DocumentStudyButton, StudyButton } from './document-study-button';

interface DocumentListItemProps {
  readonly cover: ReactElement;
  readonly label: string;
  readonly author: string;
  readonly showStudyButton?: boolean;
}

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: background-color 250ms ease;

  ${({ theme }) => css`
    row-gap: ${theme.size.x15};
    padding: ${theme.size.x2} ${theme.size.x15} ${theme.size.x15};
    background-color: ${theme.color.background_background_02};
    border-radius: ${theme.borderRadius.x15};

    &:hover {
      background-color: ${theme.color.background_background_03};

      ${StudyButton} {
        pointer-events: all;
        opacity: 1;
      }
    }
  `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_dark};
    row-gap: ${theme.size.x05};
  `};
`;

const AuthorText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
  `};
`;

export function DocumentListItem({
  cover,
  label,
  author,
  showStudyButton,
}: DocumentListItemProps) {
  return (
    <Layout>
      {cover}
      {showStudyButton && <DocumentStudyButton />}
      <ContentContainer>
        <Text appearance="_12B" lineClamp={3}>
          {label}
        </Text>
        <AuthorText appearance="_12M">{author}</AuthorText>
      </ContentContainer>
    </Layout>
  );
}
