import {
  Text,
  TextAppearance,
  ThemeDefinition,
  css,
  styled,
} from '@yarmill/component-library';
import { ReactElement } from 'react';

export enum DocumentCoverSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum DocumentCoverAppearance {
  book = 'book',
  thesis = 'thesis',
  article = 'article',
  methodologicalGuidelines = 'methodologicalGuidelines',
  lecture = 'lecture',
  webPost = 'webPost',
  other = 'other',
}

interface DocumentCoverProps {
  readonly label: string;
  readonly size?: DocumentCoverSize;
  readonly appearance?: DocumentCoverAppearance;
}

function getTextAppearanceForCoverSize(
  size: DocumentCoverSize
): TextAppearance {
  switch (size) {
    case DocumentCoverSize.xsmall:
      return '_5B';
    case DocumentCoverSize.small:
      return '_7B';
    case DocumentCoverSize.medium:
      return '_8B';
    case DocumentCoverSize.large:
      return '_10B';
  }
}

function getColorForAppearance(
  $appearance: DocumentCoverAppearance,
  theme: ThemeDefinition
): string {
  switch ($appearance) {
    case DocumentCoverAppearance.book:
      return theme.color.other_blush_dark;
    case DocumentCoverAppearance.thesis:
      return theme.color.other_sand_dark;
    case DocumentCoverAppearance.article:
      return theme.color.other_pumpkin_dark;
    case DocumentCoverAppearance.methodologicalGuidelines:
      return theme.color.other_steel_dark;
    case DocumentCoverAppearance.lecture:
      return theme.color.other_cloud_dark;
    case DocumentCoverAppearance.webPost:
      return theme.color.other_frog_dark;
    case DocumentCoverAppearance.other:
      return theme.color.other_swamp_dark;
  }
}

export function getSize(size: DocumentCoverSize): number {
  switch (size) {
    case DocumentCoverSize.xsmall:
      return 50;
    case DocumentCoverSize.small:
      return 80;
    case DocumentCoverSize.medium:
      return 93;
    case DocumentCoverSize.large:
      return 118;
  }
}
function getPadding(size: DocumentCoverSize, theme: ThemeDefinition): string {
  switch (size) {
    case DocumentCoverSize.xsmall:
    case DocumentCoverSize.small:
      return theme.size.x05;
    case DocumentCoverSize.medium:
      return theme.size.x075;
    case DocumentCoverSize.large:
      return theme.size.x1;
  }
}

const Layout = styled.div<{ readonly $size: DocumentCoverSize }>`
  display: inline-grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  aspect-ratio: 50 / 66;
  justify-items: stretch;
  ${({ theme, $size }) => css`
    width: ${`${getSize($size)}px`};
    background-color: ${theme.color.background_background_02};
    box-shadow: 0 0 ${theme.size.x1} 0 rgba(74, 73, 98, 0.30);
    border-radius: ${theme.borderRadius.x025};
  `};
`;

const TypeCover = styled.div<{ readonly $appearance: DocumentCoverAppearance }>`
  aspect-ratio: 50 / 20;
  ${({ theme, $appearance }) => css`
    background-color: ${getColorForAppearance($appearance, theme)};
    border-top-right-radius: ${theme.borderRadius.x025};
    border-top-left-radius: ${theme.borderRadius.x025};
  `};
`;

const TextContainer = styled.div<{ readonly $size: DocumentCoverSize }>`
  ${({ theme, $size }) => css`
    padding: ${getPadding($size, theme)};
    color: ${theme.color.neutral_neutral_darker};
  `};
`;

export function DocumentCover({
  label,
  size = DocumentCoverSize.small,
  appearance = DocumentCoverAppearance.book,
}: DocumentCoverProps): ReactElement {
  return (
    <Layout $size={size}>
      <TypeCover $appearance={appearance} />
      <TextContainer $size={size}>
        <Text
          appearance={getTextAppearanceForCoverSize(size)}
          lineClamp={6}
          ellipsis
          as="div"
          wordBreak="break-word"
        >
          {label}
        </Text>
      </TextContainer>
    </Layout>
  );
}
