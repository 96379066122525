import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';
import { ForwardedRef, forwardRef, useContext } from 'react';
import { DropdownContext } from './dropdown-context';
import { DropdownListItem } from './dropdown-list-item';
import { DropdownOption, DropdownProvider } from './dropdown-provider';

function containsSelectedValue<V>(
  selectedValue: V,
  subOptions: DropdownOption<V>[] | undefined
): boolean {
  return Boolean(
    subOptions?.find(
      o =>
        o.value === selectedValue ||
        containsSelectedValue(selectedValue, o.children)
    )
  );
}
export const Option = forwardRef(function Option<V>(
  {
    children,
    value,
    label,
    icon,
    onClick,
    isAdditional,
    ...otherProps
  }: DropdownOption<V>,
  forwardedRef: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const {
    activeIndex,
    getItemProps,
    handleSelect,
    selectedValue,
    setHasFocusInside,
    multiSelect,
  } = useContext(DropdownContext);

  const { ref, index } = useListItem({
    label,
  });
  const tree = useFloatingTree();
  const mergedRef = useMergeRefs([ref, forwardedRef]);

  const isActive = activeIndex === index;
  const isSelected = Boolean(
    (((Array.isArray(selectedValue)
      ? selectedValue.includes(value)
      : selectedValue === value) ||
      containsSelectedValue(selectedValue, children)) &&
      value !== undefined) ||
      (isAdditional && otherProps.isSelected)
  );
  const hasChildren = (children?.length ?? 0) > 0;

  const option = (
    <DropdownListItem
      isActive={isActive}
      isSelected={isSelected}
      label={String(label)}
      icon={icon}
      tabIndex={isActive ? 0 : -1}
      showCheckbox={multiSelect && !hasChildren}
      hasSubmenu={hasChildren}
      ref={mergedRef}
      index={index}
      value={value}
      {...(!hasChildren
        ? getItemProps({
            ...otherProps,
            onClick: e => {
              onClick?.(e);
              handleSelect(index, value);
              tree?.events.emit('click');
            },
            onKeyDown: e => {
              if (e.key === ' ') {
                handleSelect(index, value, multiSelect);
              } else if (e.key === 'Enter') {
                handleSelect(index, value);
              }
            },
            onFocus() {
              setHasFocusInside(true);
            },
          })
        : {})}
    />
  );

  if (children?.length) {
    return (
      <DropdownProvider
        ref={mergedRef as any}
        options={children}
        selectedValue={selectedValue}
        handleSelect={value => handleSelect(index, value)}
        {...otherProps}
      >
        {option}
      </DropdownProvider>
    );
  } else {
    return option;
  }
});
