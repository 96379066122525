import { DataItem } from '../../reporting';
import { useScale } from '../hooks/use-scale';
import { DataLabelDefinition, FloatingBarChart } from '../types';
import { getBarLabelAttributes } from './get-bar-label-attributes';
import { getBarLabelY } from './get-bar-label-y';
import { getFloatingBarDimensions } from './get-floating-bar-dimensions';

export function getFloatingBarLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: FloatingBarChart,
  priority: number
): DataLabelDefinition | undefined {
  const dimensions = getFloatingBarDimensions(
    chartConfig,
    item,
    xScale,
    yScale
  );
  const showLabel = chartConfig.getShowLabels(item);
  if (!dimensions || !showLabel) {
    return undefined;
  }

  const { x, height: barHeight, width } = dimensions;
  const yValue = chartConfig.getYValue(item);
  const formattedValue =
    yValue !== null ? (chartConfig.formatLabelValue?.(yValue) ?? yValue) : '';
  const color = chartConfig.getLabelColor(item);
  const position = chartConfig.labelPosition;
  const y = getBarLabelY(yScale, yValue);
  const customAngle = chartConfig.getLabelAngle(item);

  const attributes = getBarLabelAttributes({
    formattedValue,
    customAngle,
    maxWidth: width,
    barHeight,
    yValue,
    position,
  });

  if (!attributes) {
    return undefined;
  }

  const {
    labelOffset,
    maxWidth: adjustedMaxWidth,
    angle,
    textSize,
  } = attributes;

  return {
    x: x + width / 2,
    y: y - labelOffset,
    originalValue: yValue,
    text: String(formattedValue),
    angle,
    color,
    priority,
    maxWidth: adjustedMaxWidth,
    maxHeight: undefined,
    textSize,
  };
}
