import { Button, Link } from '@yarmill/component-library';
import { AsyncStatus } from '@yarmill/types';
import { useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { UploadFailImage } from '../components/assets/upload-fail-image';
import { InfoPageLayout } from '../components/info-page-layout';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentUploadStore } from '../stores/document-upload-store';
import { getMetodejUrl } from '../utils/get-metodej-url';

export const UploadErrorDetail = observer(function UploadErrorDetail() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const logger = useLogger();

  const currentDocument = uploadStore.focusedItem as DocumentUploadStore;

  async function retryUpload() {
    try {
      if (currentDocument.fileUpload.status === AsyncStatus.rejected) {
        await currentDocument?.fileUpload.retryUpload();
      } else if (currentDocument.createDocumentError) {
        await currentDocument.createDocument();
      }
    } catch (e) {
      logger.error(e);
    }
  }

  return (
    <InfoPageLayout
      showMarginTop
      headline={intl.formatMessage({
        id: 'metodej.upload.detail.uploadError.headline',
      })}
      text={intl.formatMessage<any, any>(
        {
          id: 'metodej.upload.detail.uploadError.text',
        },
        {
          libraryLink: (content: string) => (
            <Link to={getMetodejUrl('library')}>{content}</Link>
          ),
        }
      )}
      image={<UploadFailImage />}
      button={
        <Button type="button" onClick={retryUpload}>
          {intl.formatMessage({
            id: 'metodej.upload.detail.uploadError.retryButtonLabel',
          })}
        </Button>
      }
    />
  );
});
