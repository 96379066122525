import { FileTypeResult, fileTypeFromBuffer } from 'file-type';

export async function validateFileTypes(
  files: File[],
  acceptedTypes: string[]
): Promise<{ validFiles: File[]; invalidFiles: File[] }> {
  const filesWithTypes: [FileTypeResult | undefined, File][] =
    await Promise.all(
      Array.from(files).map(async file => [
        await file.arrayBuffer().then(fileTypeFromBuffer),
        file,
      ])
    );

  const validFiles = filesWithTypes
    .filter(([type]) => acceptedTypes.includes(type?.mime ?? ''))
    .map(([_, file]) => file);

  const invalidFiles = filesWithTypes
    .filter(([type]) => !acceptedTypes.includes(type?.mime ?? ''))
    .map(([_, file]) => file);

  return { validFiles, invalidFiles };
}
