import {
  AsyncStatus,
  File as FileUploadFile,
  FileUploadStore,
} from '@yarmill/types';
import { GlobalLogger } from '@yarmill/utils';
import { makeObservable, observable, when } from 'mobx';
import { api } from '../metodej-api';
import { AgreementType } from '../types';
import { DocumentStore } from './document-store';

export class DocumentUploadStore {
  @observable
  private _document: DocumentStore | null = null;
  private readonly _fileUpload: FileUploadStore;
  private readonly _agreementType: AgreementType;
  private readonly _agreementFile: FileUploadFile | null = null;
  private readonly _agreementTimestamp: string;

  @observable
  private _createDocumentError: boolean = false;

  constructor(
    documentStore: FileUploadStore | DocumentStore,
    agreementType: AgreementType,
    agreementFile: FileUploadFile | null,
    agreementTimestamp: string
  ) {
    if (documentStore instanceof DocumentStore) {
      this._document = documentStore;
      this._fileUpload = {
        progress: 0,
        status: AsyncStatus.resolved,
        name: documentStore.fileName ?? documentStore.title ?? '',
      } as FileUploadStore;
    } else {
      this._fileUpload = documentStore;
    }
    this._agreementType = agreementType;
    this._agreementFile = agreementFile;
    this._agreementTimestamp = agreementTimestamp;
    makeObservable(this);

    if (!this.document) {
      when(
        () => this._fileUpload.status === AsyncStatus.resolved,
        () => this.createDocument()
      );
    }
  }

  get fileUpload() {
    return this._fileUpload;
  }

  get document() {
    return this._document;
  }

  get createDocumentError() {
    return this._createDocumentError;
  }

  cancelUpload() {
    this._fileUpload?.cancelUpload();
  }

  async createDocument() {
    const uploadedFile = this._fileUpload.uploadedFile;
    if (uploadedFile) {
      this._createDocumentError = false;

      try {
        const response = await api.createDocument({
          documentFileId: uploadedFile.FileId,
          uploadTimestamp: new Date().toISOString(),
          agreement:
            this._agreementType === 'bearer'
              ? {
                  type: 'bearer',
                  agreementTimestamp: this._agreementTimestamp,
                }
              : {
                  type: 'signedAgreement',
                  agreementFileTimestamp: this._agreementTimestamp,
                  agreementFileId: this._agreementFile?.FileId ?? '',
                },
        });

        if (response.status === 200) {
          this._document = new DocumentStore(response.data);
        } else {
          GlobalLogger.error(
            `Create document failed`,
            response.status,
            response.data
          );
          this._createDocumentError = true;
        }
      } catch (e) {
        GlobalLogger.error(e);
        this._createDocumentError = true;
      }
    }
  }
}
