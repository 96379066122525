import { Multiply } from '@yarmill/icon-library';
import { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';
import { MiniButton } from '../mini-button';
import { PanelToolbar } from '../panel-toolbar';
import { Text } from '../text';
import { ThemeProvider, css, styled } from '../theme-provider';

const CONTENT_MIN_HEIGHT = 100;
const MAX_WIDTH = 428;
const MIN_WIDTH = 360;
type PopoverProps = PropsWithChildren<{
  readonly label: string;
  readonly buttons?: ReactElement;
  readonly noMinWidth?: boolean;
  readonly noMinHeight?: boolean;
  readonly modal?: boolean;
  readonly handleCloseClick?: MouseEventHandler;
}>;

const Layout = styled.div<{ readonly $noMinWidth: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  flex-grow: 1;
  max-width: ${MAX_WIDTH}px;

  ${({ theme, $noMinWidth }) => css`
    min-width: ${$noMinWidth ? 0 : MIN_WIDTH}px;
    border-radius: ${theme.borderRadius.x2};
    background-color: ${theme.color.neutral_black};
  `};
`;

const ToolbarWrapper = styled.div`
  flex: 0;
`;

const ContentWrapper = styled.div<{ readonly $noMinHeight: boolean }>`
  flex-grow: 1;
  overflow: auto;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, $noMinHeight }) => css`
    min-height: ${$noMinHeight ? theme.size.x4 : CONTENT_MIN_HEIGHT}px;
    padding: ${theme.size.x1};
    color: ${theme.color.neutral_neutral_2};
  `};
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &:has(> button:only-child) {
    grid-template-columns: 100%;
  }

  ${({ theme }) => css`
    column-gap: ${theme.size.x1};
    padding: ${theme.size.x1};
  `};
`;

export function Popover({
  label,
  children,
  buttons,
  noMinHeight = false,
  noMinWidth = false,
  modal = true,
  handleCloseClick,
}: PopoverProps) {
  return (
    <ThemeProvider theme="master" dark>
      <Layout
        aria-role="dialog"
        aria-labelledby="popover-label"
        $noMinWidth={noMinWidth}
      >
        <ToolbarWrapper>
          <PanelToolbar
            position="header"
            center={
              <Text appearance="_12B" id="popover-label" as="h2">
                {label}
              </Text>
            }
            right={
              !modal || handleCloseClick ? (
                <MiniButton type="button" onClick={handleCloseClick}>
                  <Multiply />
                </MiniButton>
              ) : undefined
            }
          />
        </ToolbarWrapper>
        <ContentWrapper $noMinHeight={noMinHeight}>{children}</ContentWrapper>
        {buttons && <ButtonsWrapper>{buttons}</ButtonsWrapper>}
      </Layout>
    </ThemeProvider>
  );
}
