export const SPORTS = [
  'alpineSkiing',
  'americanFootball',
  'archery',
  'athletics',
  'badminton',
  'baseballSoftball',
  'basketball',
  'beachVolleyball',
  'biathlon',
  'bobsleigh',
  'bodybuilding',
  'boxing',
  'canoeingSlalom',
  'canoeingSprint',
  'cricket',
  'crossCountrySkiing',
  'curling',
  'cyclingBmx',
  'cyclingMtb',
  'cyclingRoad',
  'cyclingTrack',
  'equestrian',
  'fencing',
  'fieldHockey',
  'figureSkating',
  'floorball',
  'football',
  'freestyleSkiingAerials',
  'freestyleSkiingHalfpipe',
  'freestyleSkiingMoguls',
  'freestyleSkiingSkiCross',
  'freestyleSkiingSlopestyle',
  'frisbee',
  'futsal',
  'general',
  'golf',
  'gymnasticsArtistic',
  'gymnasticsRhythmic',
  'gymnasticsTrampoline',
  'handball',
  'iceHockey',
  'inlineSpeedSkating',
  'judo',
  'karate',
  'lacross',
  'luge',
  'modernPentathlon',
  'nordicCombined',
  'orienteering',
  'other',
  'parkour',
  'rowing',
  'rugbySevens',
  'sailing',
  'shooting',
  'shortTrack',
  'skateboarding',
  'skeleton',
  'skiJumping',
  'snowboardBigAir',
  'snowboardCross',
  'snowboardHalfpipe',
  'snowboardPgs',
  'snowboardSlopestyle',
  'speedSkating',
  'speedway',
  'sportClimbing',
  'squash',
  'surfing',
  'swimmingDiving',
  'swimmingOpenWater',
  'swimmingPool',
  'swimmingSynchro',
  'tableTennis',
  'taekwondo',
  'tennis',
  'triathlon',
  'volleyball',
  'waterPolo',
  'weightlifting',
  'wrestlingFreestyle',
  'wrestlingGreco',
] as const;

export const SUBJECTS = [
  'phyPrep',
  'techSkills',
  'sportPsyc',
  'socCultAsp',
  'nutrition',
  'health',
] as const;

export const AGE_GROUPS = ['children', 'youth', 'adults'];

export const TAGS = [
  'aerobicPerformance',
  'anaerobicPerformance',
  'anthropomotorics',
  'biomechanics',
  'bodyComposition',
  'climateAdaptation',
  'coachWork',
  'cognition',
  'communication',
  'concentration',
  'conditioning',
  'coordination',
  'diagnostics',
  'didactics',
  'doping',
  'eatingDisorders',
  'education',
  'emotions',
  'endurance',
  'energySystems',
  'equipment',
  'ethics',
  'fairPlay',
  'family',
  'fatigue',
  'firstAid',
  'flexibility',
  'gear',
  'health',
  'heartRate',
  'highAltitudeTraining',
  'highIntensity',
  'history',
  'hobbySport',
  'hydration',
  'hypoxia',
  'hypoxicTraining',
  'illness',
  'immunity',
  'individualSport',
  'injury',
  'intensity',
  'intermittentLoad',
  'lactate',
  'load',
  'lowIntensity',
  'massage',
  'material',
  'mediumIntensity',
  'mentalTraining',
  'metabolism',
  'motivation',
  'motorLearning',
  'muscleFatigue',
  'nutrition',
  'ontogeny',
  'overload',
  'overreaching',
  'overtraining',
  'paraSport',
  'peakForm',
  'pedagogy',
  'periodSpecificTraining',
  'philosophy',
  'physicalTherapy',
  'physiology',
  'physiotherapy',
  'preRacePreparation',
  'prevention',
  'professionalAthletes',
  'recovery',
  'recreationalAthletes',
  'reds',
  'rehabilitation',
  'school',
  'selfConfidence',
  'selfRegulation',
  'somatotype',
  'speed',
  'sportAcademies',
  'sportPerformance',
  'sportPsychology',
  'sportTraining',
  'sportTrainingChildren',
  'sportTrainingWomen',
  'sportsMedicine',
  'strength',
  'stress',
  'supplementation',
  'tactics',
  'talentSelection',
  'teamSport',
  'technique',
  'testing',
  'trainingEvidence',
  'trainingExtremeConditions',
  'trainingPlanning',
  'vo2Max',
  'weightGain',
  'weightLoss',
  'willEffort',
  'youthSportCenters',
];
