import {
  MiniButton,
  Text,
  ThemeProvider,
  Tippy,
  css,
  styled,
} from '@yarmill/component-library';
import { Multiply } from '@yarmill/icon-library';
import { MouseEventHandler, ReactElement } from 'react';
import { UploadListItemPreview } from './assets';
import { ProgressBar } from './progress-bar';

interface DocumentUploadListItemProps {
  readonly label: string;
  readonly progress?: number;
  readonly tag?: ReactElement;
  readonly isActive?: boolean;
  readonly handleCancelClick?: MouseEventHandler;
  readonly onClick?: MouseEventHandler;
  readonly cover?: ReactElement;
  readonly cancelButtonAriaLabel?: string;
}

const Layout = styled.div<{
  readonly $hasCancelButton: boolean;
  readonly $isActive: boolean;
}>`
  display: grid;
  cursor: pointer;

  ${({ theme, $hasCancelButton, $isActive }) => css`
    column-gap: ${theme.size.x2};
    padding: ${theme.size.x15};
    border-radius: ${theme.size.x15};
    background-color: ${$isActive ? theme.color.brand_yarmill : theme.color.background_background_03};
    color: ${$isActive ? theme.color.neutral_white : theme.color.neutral_neutral_dark};

    ${
      $hasCancelButton
        ? css`grid-template-columns: auto 1fr auto;`
        : css`grid-template-columns: auto 1fr;`
    };

    ${
      !$isActive &&
      css`
        &:hover {
          background-color: ${theme.color.background_background_04};
        }
      `
    }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => css`
    row-gap: ${theme.size.x1};
  `};
`;

const PreviewWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.size.x075} ${theme.size.x1} ${theme.size.x1};
    color: ${theme.color.neutral_neutral_36};
  `};
`;

const ProgressWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  ${({ theme }) => css`
    padding-top: ${theme.size.x1};
  `};
`;
export function DocumentUploadListItem({
  label,
  tag,
  isActive = false,
  progress,
  handleCancelClick,
  onClick,
  cancelButtonAriaLabel,
  cover,
}: DocumentUploadListItemProps) {
  return (
    <Layout
      $hasCancelButton={Boolean(handleCancelClick)}
      $isActive={isActive}
      onClick={onClick}
      className="document-upload-list-item"
    >
      {cover ?? (
        <PreviewWrapper>
          <UploadListItemPreview />
        </PreviewWrapper>
      )}
      <ContentWrapper>
        <Text appearance="_10B" lineClamp={2} wordBreak="break-word">
          {label}
        </Text>
        <ThemeProvider theme="master" dark={!isActive}>
          {tag}
        </ThemeProvider>
      </ContentWrapper>
      {handleCancelClick && (
        <Tippy tooltipContent={cancelButtonAriaLabel} translateValue={false}>
          <MiniButton onClick={handleCancelClick} type="button">
            <Multiply />
          </MiniButton>
        </Tippy>
      )}
      {typeof progress !== 'undefined' && (
        <ProgressWrapper>
          <ThemeProvider theme="master" dark={isActive}>
            <ProgressBar value={progress} max={100} />
          </ThemeProvider>
        </ProgressWrapper>
      )}
    </Layout>
  );
}
