import { Text, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';

interface UploadingPageLayoutProps {
  readonly image: ReactElement;
  readonly headline: string;
  readonly text: string | ReactElement;
  readonly button?: ReactElement;
  readonly showMarginTop?: boolean;
}

const Layout = styled.div<{ readonly $showMarginTop: boolean }>`
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;

  ${({ theme, $showMarginTop }) => css`
    grid-row-gap: ${theme.size.x6};
    margin-top: ${$showMarginTop ? theme.size.x9 : 0};
  `};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  justify-items: center;

  ${({ theme }) => css`
    grid-row-gap: ${theme.size.x25};
  `};
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.size.x15};
  `};
`;

export function InfoPageLayout({
  image,
  headline,
  text,
  button,
  showMarginTop = false,
}: UploadingPageLayoutProps) {
  return (
    <Layout $showMarginTop={showMarginTop}>
      {image}
      <ContentWrapper>
        <Text appearance="_16B" as="h2">
          {headline}
        </Text>
        <Text appearance="_14M" as="p">
          {text}
        </Text>
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </ContentWrapper>
    </Layout>
  );
}
