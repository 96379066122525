import { Text, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';

interface FormGuideStepProps {
  readonly image: ReactElement;
  readonly headline: string;
  readonly text: string;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    width: 100%;
  }

  ${({ theme }) => css`
    background: ${theme.color.background_background_02};
    border-radius: ${theme.borderRadius.x15};
    padding: ${theme.size.x2};
    row-gap: ${theme.size.x25};
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: ${theme.size.x1};
  `};
`;

export function FormGuideStep({ image, headline, text }: FormGuideStepProps) {
  return (
    <Layout>
      {image}
      <ContentWrapper>
        <Text appearance="_16B">{headline}</Text>
        <Text appearance="_12M">{text}</Text>
      </ContentWrapper>
    </Layout>
  );
}
