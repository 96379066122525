import { css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';
import { DocumentCoverSize, getSize } from './document-cover';

interface OriginalDocumentCoverProps {
  readonly label: string;
  readonly size?: DocumentCoverSize;
  readonly src: string;
}

const Layout = styled.div<{ readonly $size: DocumentCoverSize }>`
  display: inline-grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  aspect-ratio: 50 / 66;
  overflow: hidden;
  justify-items: stretch;
  ${({ theme, $size }) => css`
    width: ${`${getSize($size)}px`};
    background-color: ${theme.color.background_background_02};
    box-shadow: 0 0 ${theme.size.x1} 0 rgba(74, 73, 98, 0.30);
    border-radius: ${theme.borderRadius.x025};
  `};
`;

export function OriginalDocumentCover({
  label,
  size = DocumentCoverSize.small,
  src,
}: OriginalDocumentCoverProps): ReactElement {
  return (
    <Layout $size={size}>
      <img src={src} alt={label} width={getSize(size)} />
    </Layout>
  );
}
