import {
  Command,
  ExternalIconName,
  ModuleCode,
  ModuleDefinitionStore,
  NavigationLink,
} from '@yarmill/types';
import { createModuleLink } from '@yarmill/utils';
import { makeObservable } from 'mobx';
import { METODEJ_BASE_URL } from '../const';

export class MetodejModuleDefinitionStore implements ModuleDefinitionStore {
  private readonly _icon: ExternalIconName = 'UserCheck';
  readonly moduleCode: ModuleCode = 'metodej';
  private readonly _baseUrl: string = METODEJ_BASE_URL;

  constructor() {
    makeObservable(this);
  }

  get navigationLinks(): NavigationLink[] {
    return [
      {
        icon: this._icon,
        moduleCode: this.moduleCode,
        label: 'header.navigation.metodej',
        isActive: path => path.startsWith('/metodej'),
        createClickHandler: defaultSearchParams => location =>
          createModuleLink(
            `${this._baseUrl}/library`,
            defaultSearchParams,
            location
          ),
      },
    ];
  }

  get commandPaletteCommands(): Command[] {
    return [];
  }
}
