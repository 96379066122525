import { LabelPosition } from '../../reporting';
import { LABEL_FONT_SIZE, MIN_LABEL_FONT_SIZE } from '../../reporting/const';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { getRotatedWidth } from '../../reporting/utils/get-rotated-width';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { getBarLabelOffset } from './get-bar-label-offset';
import { getShrankLabelHeight } from './get-shrank-label-height';

interface GetBarLabelAttributesParams {
  readonly formattedValue: string;
  readonly customAngle: number;
  readonly maxWidth: number;
  readonly barHeight: number;
  readonly yValue: number | null;
  readonly position: LabelPosition | undefined;
}
export function getBarLabelAttributes({
  formattedValue,
  customAngle,
  maxWidth,
  barHeight,
  yValue,
  position,
}: GetBarLabelAttributesParams) {
  const labelWidth = getSSRStringWidth(formattedValue);
  const shrankLabelFontSize = getShrankLabelHeight(
    customAngle
      ? getRotatedWidth(labelWidth, LABEL_FONT_SIZE, customAngle)
      : labelWidth,
    maxWidth
  );

  const isSmallToShow =
    (!customAngle || Math.abs(customAngle) === 90) &&
    shrankLabelFontSize < MIN_LABEL_FONT_SIZE;

  const shouldRotate =
    !customAngle && isSmallToShow && maxWidth >= MIN_LABEL_FONT_SIZE;

  if (isSmallToShow && !shouldRotate) {
    return undefined;
  }

  const angle = shouldRotate ? -90 : customAngle;

  const labelOffset = getBarLabelOffset(
    barHeight,
    yValue,
    position,
    angle,
    labelWidth
  );

  const rotatedLabelHeight = getRotatedHeight(
    labelWidth,
    LABEL_FONT_SIZE,
    angle
  );

  if (
    rotatedLabelHeight + Math.abs(labelOffset) > barHeight &&
    position !== 'outside'
  ) {
    return undefined;
  }

  const rotatedLabelWidth = getRotatedWidth(labelWidth, LABEL_FONT_SIZE, angle);

  const textSize = Math.min(
    getShrankLabelHeight(rotatedLabelWidth, maxWidth),
    LABEL_FONT_SIZE
  );

  return {
    angle,
    maxWidth: shouldRotate ? undefined : maxWidth,
    labelOffset,
    textSize,
  };
}
