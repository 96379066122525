import 'styled-components';
import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { master } from './master';
import { ThemeDefinition } from './theme-definition';

const themes = {
  master,
};

function getTheme(theme: keyof typeof themes): ThemeDefinition {
  return themes[theme];
}

type ThemeProviderProps = PropsWithChildren<{
  readonly theme: keyof typeof themes;
  readonly dark?: boolean;
}>;
export function ThemeProvider({
  theme,
  dark,
  children,
}: ThemeProviderProps): ReactElement {
  const themeDefinition = useMemo(
    () => ({ ...getTheme(theme), dark }),
    [theme, dark]
  );

  return (
    <StyledComponentsThemeProvider theme={themeDefinition}>
      {children}
    </StyledComponentsThemeProvider>
  );
}
