import {
  Button,
  ButtonAppearance,
  ExternalLink,
  LayerProvider,
} from '@yarmill/component-library';
import { useConfig, useIntlStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import {
  FormGuideStep,
  FormGuideStep1,
  FormGuideStep2,
  FormGuideStep3,
} from '../components';
import {
  UploadFormGuideHeadline,
  UploadFormGuideLayout,
} from '../components/upload-form-step-0';
import { formatAuthorAgreementUrl } from '../utils/format-author-agreement-url';
import { UploadLayer } from './upload-layer';

export const UploadFormGuide = observer(function UploadFormGuide() {
  const intl = useIntl();
  const agreementFormUrl = useConfig('metodejAuthorAgreementFormUrl');
  const intlStore = useIntlStore();

  return (
    <>
      <UploadFormGuideHeadline appearance="_24B" as="h2">
        {intl.formatMessage({ id: 'metodej.upload.form.guide.headline' })}
      </UploadFormGuideHeadline>
      <UploadFormGuideLayout>
        <FormGuideStep
          image={<FormGuideStep1 />}
          headline={intl.formatMessage({
            id: 'metodej.upload.form.guide.step1.headline',
          })}
          text={intl.formatMessage({
            id: 'metodej.upload.form.guide.step1.text',
          })}
        />
        <FormGuideStep
          image={<FormGuideStep2 />}
          headline={intl.formatMessage({
            id: 'metodej.upload.form.guide.step2.headline',
          })}
          text={intl.formatMessage<any, any>(
            {
              id: 'metodej.upload.form.guide.step2.text',
            },
            {
              formLink: (content: string) => (
                <ExternalLink
                  href={formatAuthorAgreementUrl(
                    agreementFormUrl,
                    intlStore.locale
                  )}
                  target="_blank"
                >
                  {content}
                </ExternalLink>
              ),
            }
          )}
        />
        <FormGuideStep
          image={<FormGuideStep3 />}
          headline={intl.formatMessage({
            id: 'metodej.upload.form.guide.step3.headline',
          })}
          text={intl.formatMessage({
            id: 'metodej.upload.form.guide.step3.text',
          })}
        />
      </UploadFormGuideLayout>
      <LayerProvider
        content={closeLayer => <UploadLayer closeLayer={closeLayer} />}
      >
        <Button appearance={ButtonAppearance.Primary} as="div">
          {intl.formatMessage({ id: 'metodej.upload.form.guide.button.text' })}
        </Button>
      </LayerProvider>
    </>
  );
});
