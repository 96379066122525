import { css, styled } from '@yarmill/component-library';
import { Back } from '@yarmill/icon-library';
import { Children, Fragment, PropsWithChildren, isValidElement } from 'react';

const Layout = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  ${({ theme }) => css`
    column-gap: ${theme.size.x1};
  `};
`;

const ArrowWrapper = styled.li<{ readonly $isActive: boolean }>`
  padding: 0;
  margin: 0;
  transform: rotate(-180deg);

  ${({ theme, $isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.size.x2};
    height: ${theme.size.x2};
    color: ${$isActive ? theme.color.brand_yarmill : theme.color.neutral_neutral_64};

    svg {
      width: ${theme.size.x2};
      height: ${theme.size.x2};
    }
  `};
`;

type FormBreadcrumbsProps = PropsWithChildren<{ readonly activeItem: number }>;

export function FormBreadcrumbs({
  children,
  activeItem,
}: FormBreadcrumbsProps) {
  return (
    <Layout>
      {Children.map(children, (item, idx) => {
        if (!isValidElement(item)) {
          return null;
        }
        const key = item.key ?? undefined;

        return (
          <Fragment key={key}>
            {idx !== 0 && (
              <ArrowWrapper aria-hidden $isActive={activeItem === idx + 0.5}>
                <Back />
              </ArrowWrapper>
            )}
            {item}
          </Fragment>
        );
      })}
    </Layout>
  );
}
