import { Text, TextSize, Tippy, WhiteSpace } from '@yarmill/components';
import { Units } from '@yarmill/types';
import { formatNumber, formatValueByUnit, isBrowser } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { ReportContext } from '../../context/report-context';
import { useGetCellReportDefinition } from '../../hooks/use-get-cell-report-definition';
import { Report } from '../../report';
import { ReportData, ReportingDataBusinessFormat } from '../../types';
import { formatValueByBusinessFormat, mapColor } from '../../utils';
import { ReportingTableCellContext } from './reporting-table-cell-context';
import {
  IconWrapper,
  ReportLink,
  StyledReportingTableCell,
} from './styled-components';
import {
  ReportingTableColumnConfig,
  ReportingTableDataItem,
  ReportingTableRowData,
  ReportingTableUnit,
} from './types';
import { calculateColumnPrintWidth } from './utils/calculate-column-print-width';

export interface ReportingTableCellProps {
  readonly columnConfig: ReportingTableColumnConfig;
  readonly data: ReportingTableDataItem | undefined;
  readonly rowData: ReportingTableRowData | undefined;
  readonly formattedRowData: ReportingTableRowData | undefined;
  readonly rowIdx: number;
  readonly rowSpan: number | undefined;
}

function formatValue(
  value: string | number | undefined | null,
  unit?: ReportingTableUnit | null,
  businessFormat?: ReportingDataBusinessFormat | null,
  format?: string | null
): ReactNode {
  if (businessFormat === 'report') {
    return '';
  }

  if (businessFormat) {
    return formatValueByBusinessFormat(value, businessFormat, format || null, {
      monoSpace: true,
    });
  }

  if (unit && unit !== 'short-text') {
    switch (unit) {
      case 'report':
        return <></>;
      case 'number':
      case 'decimal-number':
        return formatNumber(value);
      default:
        return formatValueByUnit(value, unit);
    }
  }

  return value;
}

function shouldWrap(
  unit?: ReportingTableUnit | null,
  businessFormat?: ReportingDataBusinessFormat | null
): boolean {
  if (
    unit === Units.hod ||
    unit === Units.min ||
    unit === Units.longTime ||
    unit === 'number' ||
    unit === 'decimal-number'
  ) {
    return false;
  } else if (businessFormat === 'duration' || businessFormat === 'percent') {
    return false;
  }
  return true;
}

export const ReportingTableCell = observer(function ReportingTableCell(
  props: ReportingTableCellProps
): JSX.Element {
  const { data, rowData, columnConfig, formattedRowData, rowSpan } = props;
  const {
    dataType,
    businessFormat,
    format,
    valueAlignment,
    translateValue,
    link,
    handleLinkClick,
    borderLeft,
    borderRight,
    noWrap,
    getTooltipContent,
    getColor,
    valueVerticalAlignment,
    columnName,
    textSize,
  } = columnConfig;
  const intl = useIntl();
  const reportContext = useContext(ReportContext);
  const getCellReportDefinition = useGetCellReportDefinition(columnName);
  const translatedValue =
    translateValue && data && typeof data === 'string' && intl.messages[data]
      ? intl.formatMessage(
          { id: String(data) },
          formattedRowData as Record<string, string>
        )
      : data;

  const reportDefinition =
    businessFormat === 'report' ? getCellReportDefinition() : undefined;
  const value =
    businessFormat === 'report' ? (
      reportDefinition ? (
        <Report
          report={reportDefinition}
          data={data as ReportData}
          noReportWrapper
        />
      ) : null
    ) : (
      formatValue(
        translatedValue as string | number,
        dataType,
        businessFormat,
        format
      )
    );

  const Wrapper =
    isBrowser && link && rowData
      ? ({ children }: PropsWithChildren) => (
          <ReportLink
            to={() => link(rowData)}
            onMouseDown={
              handleLinkClick ? () => handleLinkClick(rowData) : undefined
            }
          >
            {children}
          </ReportLink>
        )
      : Fragment;

  const tooltipData =
    typeof window !== 'undefined' && getTooltipContent && rowData
      ? getTooltipContent(rowData)
      : null;

  const reportSectionRatio = reportContext
    ? (reportContext.sectionRatio ?? 0)
    : 0;
  const cellContextValue = useMemo(
    () => ({
      columnConfig: columnConfig,
      columnPrintWidth: columnConfig
        ? calculateColumnPrintWidth(columnConfig, reportSectionRatio)
        : 0,
    }),
    [columnConfig, reportSectionRatio]
  );

  const TooltipWrapper = tooltipData
    ? ({ children }: { children: JSX.Element }) => (
        <Tippy tooltipContent={tooltipData} noWrapper>
          {children}
        </Tippy>
      )
    : Fragment;

  const color = rowData ? getColor?.(rowData) : null;

  return (
    <ReportingTableCellContext.Provider value={cellContextValue}>
      <TooltipWrapper>
        <StyledReportingTableCell
          align={valueAlignment || 'right'}
          borderLeft={borderLeft}
          borderRight={borderRight}
          rowSpan={rowSpan}
          // colSpan={colSpan ?? undefined}
          whiteSpace={noWrap ? WhiteSpace.noWrap : undefined}
          color={mapColor(color ?? '#4a4a4a')}
          valueVerticalAlignment={valueVerticalAlignment}
          textSize={textSize}
        >
          <Wrapper>
            {businessFormat === 'icon' ? (
              <IconWrapper inheritColor align={valueAlignment || 'right'}>
                {value}
              </IconWrapper>
            ) : (
              <Text
                size={TextSize.inherit}
                monoSpace
                whiteSpace={
                  shouldWrap(dataType, businessFormat) && !noWrap
                    ? undefined
                    : WhiteSpace.noWrap
                }
                inheritColor
              >
                {value}
              </Text>
            )}
          </Wrapper>
        </StyledReportingTableCell>
      </TooltipWrapper>
    </ReportingTableCellContext.Provider>
  );
});
