import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { styled } from '../theme-provider';

type PopoverProviderProps = PropsWithChildren<{
  readonly content: (close: () => void) => ReactElement;
}>;

const PopoverButton = styled.button`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  :focus-visible {
    outline: none;
  }
`;

const FloatingContainer = styled.div`
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
`;

export function PopoverProvider({ children, content }: PopoverProviderProps) {
  const [isLayerOpened, setIsLayerOpened] = useState<boolean>(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isLayerOpened,
    onOpenChange: setIsLayerOpened,
    placement: 'bottom-start',
    middleware: [
      flip({
        mainAxis: false,
      }),
      shift({
        padding: { left: 10, right: 10 },
      }),
      offset({ mainAxis: 4 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    enabled: true,
    bubbles: true,
    ancestorScroll: false,
    outsidePress: true,
    outsidePressEvent: 'mousedown',
  });

  const role = useRole(context, { role: 'dialog' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);
  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: 'scale(0.95)',
      opacity: 0,
    },
    duration: 80,
    common: ({ side }) => ({
      transformOrigin: {
        top: 'bottom right',
        bottom: context.placement.includes('end') ? '100% -4px' : '0 -4px',
        left: '',
        right: '',
      }[side],
    }),
  });

  return (
    <>
      <PopoverButton
        ref={refs.setReference}
        {...getReferenceProps()}
        type="button"
      >
        {children}
      </PopoverButton>
      {isMounted && (
        <FloatingPortal>
          <div
            style={floatingStyles}
            {...getFloatingProps()}
            ref={refs.setFloating}
          >
            <FloatingFocusManager context={context}>
              <FloatingContainer style={styles}>
                {content(() => setIsLayerOpened(false))}
              </FloatingContainer>
            </FloatingFocusManager>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
