import {
  SideMenu,
  SideMenuContainer,
  SideMenuItem,
  SideMenuLayout,
} from '@yarmill/component-library';
import { Book, Upload } from '@yarmill/icon-library';
import { useCurrentUserStore, useLocation } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useDocumentsUploadStore } from './hooks/use-documents-upload-store';

export const MainMenu = observer(function MainMenu() {
  const intl = useIntl();
  const { path: basePath } = useRouteMatch();
  const currentUser = useCurrentUserStore();
  const { pathname } = useLocation();
  const uploadStore = useDocumentsUploadStore();

  return (
    <SideMenuLayout>
      <SideMenuContainer>
        <SideMenu
          label={intl.formatMessage({ id: 'metodej.mainMenu.label' })}
          headline={intl.formatMessage({ id: 'metodej.mainMenu.headline' })}
        >
          {currentUser.isAllowedTo('metodej.library') && (
            <SideMenuItem
              href={`${basePath}/library`}
              icon={<Book />}
              text={intl.formatMessage({ id: 'metodej.mainMenu.library' })}
              selected={pathname.startsWith(`${basePath}/library`)}
            />
          )}

          {currentUser.isAllowedTo('metodej.upload') && (
            <SideMenuItem
              href={`${basePath}/upload`}
              icon={<Upload />}
              text={intl.formatMessage({ id: 'metodej.mainMenu.upload' })}
              selected={pathname.startsWith(`${basePath}/upload`)}
              notification={
                uploadStore.documentsToCheck.size +
                  uploadStore.uploadingDocuments.length || undefined
              }
            />
          )}
        </SideMenu>
      </SideMenuContainer>
    </SideMenuLayout>
  );
});
