import { AsyncStatus } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';
import { PublishedDocumentDetail } from './published-document-detail';
import { UploadErrorDetail } from './upload-error-detail';
import { UploadFormDetail } from './upload-form-detail';
import { UploadPendingDetail } from './upload-pending-detail';

export const UploadDetail = observer(function UploadDetail() {
  const uploadStore = useDocumentsUploadStore();
  const currentDocument = uploadStore.focusedItem;

  if (!currentDocument) {
    return null;
  }

  const isReadyToCheck = currentDocument instanceof DocumentStore;
  const isRejected =
    !isReadyToCheck &&
    (currentDocument.fileUpload?.status === AsyncStatus.rejected ||
      currentDocument.createDocumentError);
  const isPending =
    !isReadyToCheck &&
    (currentDocument?.fileUpload?.status === AsyncStatus.pending ||
      (!currentDocument.document?.isAnnotated &&
        !currentDocument.createDocumentError));
  const isPublished =
    isReadyToCheck &&
    uploadStore.publishedDocuments.has(currentDocument.documentId);

  return (
    <>
      {isRejected && <UploadErrorDetail />}
      {isPending && <UploadPendingDetail />}
      {isReadyToCheck && !isPublished && <UploadFormDetail />}
      {isReadyToCheck && isPublished && <PublishedDocumentDetail />}
    </>
  );
});
