import { Text, ThemeDefinition, css, styled } from '@yarmill/component-library';
import { Check } from '@yarmill/icon-library';

interface FormBreadcrumbItemProps {
  readonly label: string;
  readonly stepNumber: number;
  readonly isDone?: boolean;
  readonly isActive?: boolean;
}

function getProgressBackgroundColor(
  isActive: boolean,
  isDone: boolean,
  theme: ThemeDefinition
): string {
  if (isActive) {
    return theme.color.brand_yarmill;
  }

  return isDone ? theme.color.neutral_neutral_64 : 'transparent';
}

const Progress = styled.div<{
  readonly $isActive: boolean;
  readonly $isDone: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${({ theme, $isActive, $isDone }) => css`
    color: ${$isActive || $isDone ? theme.color.neutral_neutral_2 : theme.color.neutral_neutral_64};
    width: ${theme.size.x2};
    height: ${theme.size.x2};
    background-color: ${getProgressBackgroundColor($isActive, $isDone, theme)};
    border: 1.5px solid ${$isActive ? theme.color.brand_yarmill : theme.color.neutral_neutral_64};

    svg {
      width: ${theme.size.x15};
      height: ${theme.size.x15};
    }
  `};
`;

const Layout = styled.li<{ readonly $isActive: boolean }>`
  list-style: none;
  display: flex;
  align-items: center;

  ${({ theme, $isActive }) => css`
    column-gap: ${theme.size.x1};
    color: ${$isActive ? theme.color.brand_yarmill : theme.color.neutral_neutral_64};
  `};
`;

const StepNumber = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function FormBreadcrumbItem({
  label,
  stepNumber,
  isDone = false,
  isActive = false,
}: FormBreadcrumbItemProps) {
  return (
    <Layout $isActive={isActive}>
      <Progress $isActive={isActive} $isDone={isDone}>
        {isDone ? (
          <Check />
        ) : (
          <StepNumber appearance="_10NumB">{stepNumber}</StepNumber>
        )}
      </Progress>
      <Text appearance="_12M">{label}</Text>
    </Layout>
  );
}
