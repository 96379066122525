import { css, styled } from '@yarmill/component-library';
import { Study } from '@yarmill/icon-library';
import { ReactElement } from 'react';

export const StudyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  transition: opacity 250ms ease, background-color 250ms ease;
  cursor: pointer;

  ${({ theme }) => css`
    background-color: ${theme.color.brand_yarmill};
    color: ${theme.color.neutral_white};
    width: ${theme.size.x3};
    height: ${theme.size.x3};

    top: ${theme.size.x15};
    left: ${theme.size.x15};

    svg {
      width: ${theme.size.x2};
      height: ${theme.size.x2};
    }

    :hover {
      background-color: ${theme.color.brand_yarmill_dark};
    }
  `};
`;

export function DocumentStudyButton(): ReactElement {
  return (
    <StudyButton>
      <Study />
    </StudyButton>
  );
}
