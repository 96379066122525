import { FormLayoutForDocumentFormatMap } from '../types';

export const formLayoutForDocumentFormat: FormLayoutForDocumentFormatMap = {
  scan: {
    book: [
      'isbn',
      'authors',
      'publisher',
      'edition',
      'publicationPlace',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    researchPaperArticle: [
      'issn',
      'authors',
      'hostEntity',
      'publicationYear',
      'languageCode',
    ],

    academicThesis: [
      'authors',
      'publicationPlace',
      'thesisType',
      'thesisInstitution',
      'thesisSupervisor',
      'publicationYear',
      'languageCode',
    ],

    methodologicalGuidelines: [
      'isbn',
      'authors',
      'publicationPlace',
      'publicationYear',
      'languageCode',
    ],

    lecture: ['authors', 'hostEntity', 'publicationYear', 'languageCode'],
    webPost: [
      'authors',
      'hostEntity',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    other: ['authors', 'publicationYear', 'languageCode'],
  },
  digital: {
    book: [
      'isbn',
      'doi',
      'authors',
      'publisher',
      'edition',
      'publicationPlace',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    researchPaperArticle: [
      'issn',
      'doi',
      'authors',
      'hostEntity',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    academicThesis: [
      'authors',
      'publicationPlace',
      'thesisType',
      'url',
      'thesisInstitution',
      'thesisSupervisor',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    methodologicalGuidelines: [
      'isbn',
      'authors',
      'publicationPlace',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    lecture: [
      'authors',
      'hostEntity',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    webPost: [
      'authors',
      'hostEntity',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
    other: [
      'authors',
      'hostEntity',
      'url',
      'publicationYear',
      'languageCode',
      'medium',
    ],
  },
};
