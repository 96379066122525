import { PropsWithChildren, useRef } from 'react';
import { MetodejStoreContext } from './context/metodej-store-context';
import { useMetodejBaseApiUrl } from './hooks/use-metodej-base-api-url';
import { MetodejStore } from './stores/metodej-store';

export function MetodejStoreProvider({ children }: PropsWithChildren) {
  useMetodejBaseApiUrl();
  const store = useRef(new MetodejStore());

  return (
    <MetodejStoreContext.Provider value={store.current}>
      {children}
    </MetodejStoreContext.Provider>
  );
}
