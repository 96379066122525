import { BadgeAppearance, showToastMessage } from '@yarmill/component-library';
import { Logger, ServerError } from '@yarmill/types';
import { isServerError } from '@yarmill/utils';
import { FormikHelpers } from 'formik';
import { IntlShape } from 'react-intl';
import { DocumentStore } from '../stores/document-store';
import { MetodejFormValues } from '../types';

export async function handleSaveFormValues(
  values: MetodejFormValues,
  documentStore: DocumentStore,
  intl: IntlShape,
  helpers: FormikHelpers<MetodejFormValues>,
  logger: Logger
): Promise<void> {
  try {
    const formValuesWithFilteredAuthors = {
      ...values,
      authors: values.authors.filter(
        author => author.firstName || author.lastName
      ),
    };
    const response = await documentStore.updateDocument(
      formValuesWithFilteredAuthors
    );

    function handleErrorResponse(response: ServerError) {
      if (
        response.Id &&
        response.Field &&
        Object.hasOwn(documentStore, response.Field)
      ) {
        const message = intl.formatMessage(
          { id: response.Id },
          response.Values ?? {}
        );
        helpers.setFieldError(response.Field, message);
      }
    }

    if (isServerError(response)) {
      handleErrorResponse(response);
    } else if (Array.isArray(response)) {
      response.forEach(r => {
        if (isServerError(r)) {
          handleErrorResponse(r);
        }
      });
    } else if (!response) {
      showToastMessage(
        intl.formatMessage({ id: 'metodej.form.updateForm.toast.error' }),
        BadgeAppearance.error
      );
    } else {
      showToastMessage(
        intl.formatMessage({ id: 'metodej.form.updateForm.toast.success' }),
        BadgeAppearance.good
      );
    }
  } catch (e) {
    logger.error(e);
  }
}
