import { Text, css, styled } from '@yarmill/component-library';
import { PropsWithChildren, ReactElement } from 'react';
import { DocumentDetailCategory } from './document-detail-category';

type DocumentDetailTagsCategoryProps = PropsWithChildren<{
  readonly headline: string;
  readonly sportHeadline?: string;
  readonly sportTags: ReactElement;
  readonly subjectHeadline?: string;
  readonly subjectTags: ReactElement;
  readonly ageHeadline?: string;
  readonly ageTags: ReactElement;
  readonly generalTagsHeadline?: string;
  readonly generalTags: ReactElement;
}>;

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  ${({ theme }) => css`
    column-gap: ${theme.size.x4};
    row-gap: ${theme.size.x2};
  `};
`;

const TagCategoryHeadline = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
  `};
`;

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme.size.x1};
  `};
`;

export function DocumentDetailTagsCategory({
  headline,
  sportHeadline,
  sportTags,
  subjectHeadline,
  subjectTags,
  ageHeadline,
  ageTags,
  generalTagsHeadline,
  generalTags,
}: DocumentDetailTagsCategoryProps) {
  return (
    <>
      <DocumentDetailCategory headline={headline}>
        <Layout>
          {sportHeadline && (
            <>
              <TagCategoryHeadline appearance="_12B">
                {sportHeadline}
              </TagCategoryHeadline>
              <TagsWrapper>{sportTags}</TagsWrapper>
            </>
          )}
          {subjectHeadline && (
            <>
              <TagCategoryHeadline appearance="_12B">
                {subjectHeadline}
              </TagCategoryHeadline>
              <TagsWrapper>{subjectTags}</TagsWrapper>
            </>
          )}
          {ageHeadline && (
            <>
              <TagCategoryHeadline appearance="_12B">
                {ageHeadline}
              </TagCategoryHeadline>
              <TagsWrapper>{ageTags}</TagsWrapper>
            </>
          )}
        </Layout>
      </DocumentDetailCategory>
      {generalTagsHeadline && (
        <>
          <DocumentDetailCategory headline={generalTagsHeadline} noBorder>
            <TagsWrapper>{generalTags}</TagsWrapper>
          </DocumentDetailCategory>
        </>
      )}
    </>
  );
}
