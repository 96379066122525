import { Tag } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { getTagAppearance } from '../utils/get-tag-appearance';

interface TagsProps {
  readonly tags: string[];
  readonly type: 'sports' | 'subjects' | 'ageCategories' | 'tags';
}
export const Tags = observer(function Tags({ tags, type }: TagsProps) {
  const intl = useIntl();
  return (
    <>
      {tags.map(tag => (
        <Tag
          key={tag}
          text={intl.formatMessage({
            id: `metodej.document.form.field.${type}.${tag}`,
          })}
          appearance={getTagAppearance(type)}
        />
      ))}
    </>
  );
});
