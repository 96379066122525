import { css, styled } from './theme-provider';

export type AvatarSize = 2 | 3 | 4;

export const Avatar = styled.img<{ readonly $size: AvatarSize }>`
  border-radius: 50%;
  ${({ theme, $size }) => css`
    width: ${theme.size[`x${$size}`]};
    height: ${theme.size[`x${$size}`]};
  `};
`;
