import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export type GetOkrsDefinitionResponse = {
  categories: string[];
};

export function getOkrsDefinition(
  cancelToken?: CancelTokenSource
): AxiosPromise<GetOkrsDefinitionResponse> {
  return axios.get(`${coreApiTarget}/api/okrs/def`, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
