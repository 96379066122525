import { Plus } from '@yarmill/icon-library';
import { useMemo } from 'react';
import { Button, ButtonAppearance } from './button';
import { DropdownProvider } from './dropdown-provider';
import { FormInputLabel } from './form-input-label';
import { FormInputLayout } from './form-input-layout';
import { Tag, TagAppearance } from './tag';
import { css, styled } from './theme-provider';

export interface TagInputOption<V> {
  readonly value: V;
  readonly label: string;
  readonly appearance?: TagAppearance;
}

interface TagInputProps<V> {
  readonly options: TagInputOption<V>[];
  readonly value?: V[];
  readonly id: string;
  readonly label?: string;
  readonly addButtonAriaLabel: string;
  readonly selectTag: (value: V) => void;
  readonly removeTagButtonAriaLabel: string;
  readonly removeTag: (value: V) => void;
  readonly searchInputPlaceholder: string;
}

const TagsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  ${({ theme }) => css`
    gap: ${theme.size.x1};
  `};
`;

export function TagInput<V>({
  id,
  label,
  options,
  value,
  removeTagButtonAriaLabel,
  removeTag,
  selectTag,
  searchInputPlaceholder,
}: TagInputProps<V>) {
  const labelId = `${id}-${label}`;

  const selectedTags = useMemo(
    () =>
      (value ?? [])
        .map(val => options.find(o => o.value === val))
        .filter((v): v is TagInputOption<V> => Boolean(v)),
    [options, value]
  );

  return (
    <FormInputLayout>
      {label && (
        <FormInputLabel htmlFor={id} id={labelId}>
          {label}
        </FormInputLabel>
      )}
      <TagsLayout>
        {selectedTags.map(tag => (
          <Tag
            key={String(tag.value)}
            text={tag.label}
            appearance={tag.appearance}
            editable
            removeButtonAriaLabel={removeTagButtonAriaLabel}
            onRemoveClick={() => removeTag(tag.value)}
          />
        ))}
        <DropdownProvider
          options={options}
          handleSelect={selectTag as any}
          searchable
          multiSelect
          searchInputPlaceholder={searchInputPlaceholder}
          selectedValue={value}
        >
          <Button
            as="div"
            icon={<Plus />}
            appearance={ButtonAppearance.Secondary}
          />
        </DropdownProvider>
      </TagsLayout>
    </FormInputLayout>
  );
}
