import { Button, ButtonAppearance, Tippy } from '@yarmill/component-library';
import { Rename } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { getMetodejUrl } from '../utils/get-metodej-url';

export const DetailEditButton = observer(function DetailEditButton() {
  const intl = useIntl();
  const label = 'metodej.upload.form.editButtonLabel';
  const {
    params: { id },
  } = useRouteMatch<{ readonly id: string }>();

  return (
    <Tippy tooltipContent={label} noWrapper>
      <Button
        as={Link}
        to={`${getMetodejUrl('library/all')}/${id}/edit`}
        appearance={ButtonAppearance.Tertiary}
        icon={<Rename />}
        aria-label={intl.formatMessage({
          id: label,
        })}
      />
    </Tippy>
  );
});
