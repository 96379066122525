import { Check, Down } from '@yarmill/icon-library';
import { ReactElement, forwardRef } from 'react';
import { Checkbox } from '../checkbox';
import { Text } from '../text';
import { Color, css, styled } from '../theme-provider';

interface DropdownListItemProps<V> {
  readonly color?: Color;
  readonly description?: string;
  readonly icon?: ReactElement;
  readonly isActive: boolean;
  readonly isSelected: boolean;
  readonly label: string;
  readonly showCheckbox?: boolean;
  readonly tabIndex: 0 | -1;
  readonly hasSubmenu?: boolean;
  readonly value?: V;
  readonly index: number;
  readonly handleSelect?: (
    index: number | null,
    value: V,
    isCheckboxClick?: boolean
  ) => void;
}

const Layout = styled.div<{
  readonly $color?: Color;
  readonly $isActive: boolean;
}>`
  list-style-type: none;
  margin: 0;
  cursor: pointer;

  ${({ theme, $color, $isActive }) => css`
    color: ${$color ?? theme.color.neutral_neutral_2};
    padding: ${theme.size.x1};
    border-radius: ${theme.size.x075};

    &:hover {
      background-color: ${theme.color.neutral_neutral_darker};
    }
    ${$isActive && css`background-color: ${theme.color.neutral_neutral_darker};`}
  `};

  :focus-visible {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.size.x2};
    height: ${theme.size.x2};
  `};
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    ${({ theme }) => css`
      column-gap: ${theme.size.x1};
    `};
`;

const DescriptionText = styled(Text)`
    color: ${({ theme }) => theme.color.neutral_neutral_64};
`;

const IndicatorWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.x2};
    height: ${theme.size.x2};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.color.neutral_neutral_2};
    justify-self: flex-end;
    margin-left: auto;
  `}
`;
const SubmenuIconWrapper = styled.div`
  margin-left: auto;
  ${({ theme }) => css`
    justify-self: flex-end;
    flex-shrink: 0;
    color: ${theme.color.neutral_neutral};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.size.x2};
    height: ${theme.size.x2};
    svg {
      width: ${theme.size.x2};
      height: ${theme.size.x2};
      transform: rotate(-90deg);
    }
  `};
`;

const ActiveSubmenuIndicator = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.size.x1};
    color: ${theme.color.neutral_neutral_2};
  `};
`;

export const DropdownListItem = forwardRef<
  HTMLDivElement,
  DropdownListItemProps<unknown>
>(function DropdownListItem(
  {
    color,
    icon,
    label,
    description,
    isSelected,
    isActive,
    showCheckbox,
    tabIndex,
    hasSubmenu,
    handleSelect,
    value,
    index,
    ...otherProps
  },
  ref
): ReactElement {
  return (
    <Layout
      $color={color}
      tabIndex={tabIndex}
      role="option"
      $isActive={isActive}
      ref={ref}
      {...otherProps}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <TextContainer>
        <Text
          appearance={isSelected ? '_12B' : '_12M'}
          as="span"
          id={label}
          wordBreak="break-word"
        >
          {label}
        </Text>
        {description && (
          <DescriptionText appearance="_10M" as="span">
            {description}
          </DescriptionText>
        )}
        {hasSubmenu && isSelected && (
          <ActiveSubmenuIndicator>
            <Check />
          </ActiveSubmenuIndicator>
        )}
        {showCheckbox && (
          <IndicatorWrapper>
            <Checkbox
              checked={isSelected}
              id={`${label}-checkbox`}
              aria-labelledby={label}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect?.(index, value, true);
              }}
            />
          </IndicatorWrapper>
        )}
        {!showCheckbox && isSelected && (
          <IndicatorWrapper>
            <Check />
          </IndicatorWrapper>
        )}
        {hasSubmenu && (
          <SubmenuIconWrapper>
            <Down />
          </SubmenuIconWrapper>
        )}
      </TextContainer>
    </Layout>
  );
});
