import { css, styled } from '@yarmill/component-library';

const MAX_WIDTH = 586;

export const DocumentLibraryList = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  ${({ theme }) => css`
    max-width: calc(${MAX_WIDTH}px + 2 * ${theme.size.x35});
  `};
`;

export const DocumentLibraryListItemWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.size.x1};
  `};
`;
