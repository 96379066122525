import { FileUploadService, FileUploadStore } from '@yarmill/types';
import { Dispatch, SetStateAction } from 'react';

export async function uploadAgreementFile(
  file: File,
  fileUploadService: FileUploadService,
  setAgreementFile: Dispatch<SetStateAction<FileUploadStore | null>>,
  applicationId: string
) {
  const fileUpload = fileUploadService.uploadFile(file, applicationId);
  setAgreementFile(fileUpload);
  await fileUpload.uploadFile();
}
