import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Multiply } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { CancelUploadPopover } from '../popovers/cancel-upload-popover';

interface CancelUploadButtonProps {
  readonly closeLayer: () => void;
}
export const CancelUploadButton = observer(function CancelUploadButton({
  closeLayer,
}: CancelUploadButtonProps) {
  const intl = useIntl();
  const label = 'metodej.upload.form.cancelUploadForm';

  return (
    <PopoverProvider
      content={closePopover => (
        <CancelUploadPopover
          closePopover={closePopover}
          closeLayer={closeLayer}
        />
      )}
    >
      <Tippy tooltipContent={label} noWrapper>
        <Button
          as="div"
          appearance={ButtonAppearance.Tertiary}
          icon={<Multiply />}
          aria-label={intl.formatMessage({
            id: label,
          })}
        />
      </Tippy>
    </PopoverProvider>
  );
});
