import { Text, css, styled } from '@yarmill/component-library';

export const FileUploadListHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ theme }) => css`
    column-gap: ${theme.size.x2};
    margin-top: ${theme.size.x6};
    margin-bottom: ${theme.size.x25};
  `};
`;

export const FileUploadList = styled.div`
  display: grid;
  grid-template-columns: 100%;

  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_darker};
    row-gap: ${theme.size.x1};
    margin-bottom: ${theme.size.x4};
  `};
`;

export const FileUploadListSecondaryText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_64};
  `};
`;
