export enum FileType {
  video = 'video',
  picture = 'picture',
  general = 'general',
}

export type FileId = string;

export interface File {
  FileId: FileId;
  FileName: string;
  FileUrl: string;
  DateAdded: string;
  FileType: FileType;
  StreamUrl?: string | null;
}

export interface FileStorageScope {
  readonly scope: string;
  readonly url: string;
  readonly uploadUrl: string;
}
