import { css } from 'styled-components';

export const HIGHLIGHT_COLORS = {
  orange: 'rgb(247, 204, 98)',
  pink: 'rgb(255, 191, 181)',
  green: 'rgb(181, 220, 175)',
  blue: 'rgb(214, 232, 250)',
  purple: 'rgb(216, 195, 255)',
};

export const richtextStyles = css<{
  enableVideos?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}>`
  box-sizing: border-box;
  color: #4a4a4a;
  height: 100%;
  word-break: break-word;

  [class*='highlight'] {
    padding: 0.1em 0;
    &:not(a) {
      color: #4a4a4a;
    }
  }

  .highlight {
    background-color: ${HIGHLIGHT_COLORS.orange};

    &-orange {
      background-color: ${HIGHLIGHT_COLORS.orange};
    }
    &-pink {
      background-color: ${HIGHLIGHT_COLORS.pink};
    }
    &-green {
      background-color: ${HIGHLIGHT_COLORS.green};
    }
    &-blue {
      background-color: ${HIGHLIGHT_COLORS.blue};
    }
    &-purple {
      background-color: ${HIGHLIGHT_COLORS.purple};
    }
  }
  strong {
    font-weight: bold;
  }

  s {
    text-decoration: line-through;
  }

  a {
    cursor: pointer;
    color: #4da1ff;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 32px;

    :last-child {
      margin-bottom: 0;
    }
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }
`;
