import {
  Canvas,
  CanvasLayout,
  PanelTitle,
  PanelToolbar,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { DetailEditCancelButton } from '../buttons/detail-edit-cancel-button';
import { SaveDetailFormButton } from '../buttons/save-detail-form-button';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { DocumentTitle } from '../components/document-detail';
import { LibraryCanvasContainer } from '../components/library-canvas-container';
import { DocumentForm } from '../form/document-form';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { MetodejFormik } from '../types';

export const DocumentDetailForm = observer(function DocumentDetailForm() {
  const libraryStore = useDocumentLibraryStore();
  const {
    params: { id },
  } = useRouteMatch<{ readonly id: string }>();
  const intl = useIntl();
  const document = id ? libraryStore.documentsById.get(Number(id)) : null;
  const formik = useRef<MetodejFormik>();

  return (
    <CanvasLayout>
      <Canvas
        header={
          <PanelToolbar
            left={<ToggleSideMenu />}
            center={
              <PanelTitle
                text={intl.formatMessage(
                  {
                    id: 'metodej.library.canvas.title.editDocument',
                  },
                  {
                    documentType: document?.documentType,
                  }
                )}
              />
            }
            right={
              <>
                <SaveDetailFormButton formik={formik} />
                <DetailEditCancelButton />
              </>
            }
          />
        }
        footer={<PanelToolbar position="footer" />}
      >
        <LibraryCanvasContainer>
          {document ? (
            <DocumentForm documentStore={document} formikRef={formik} />
          ) : libraryStore.allDocuments.length ? (
            <DocumentTitle appearance="_16B">
              {intl.formatMessage({
                id: 'metodej.library.detail.documentNotFound',
              })}
            </DocumentTitle>
          ) : null}
        </LibraryCanvasContainer>
      </Canvas>
    </CanvasLayout>
  );
});
