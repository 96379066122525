import {
  BadgeAppearance,
  Button,
  ButtonAppearance,
  Popover,
  Text,
  showToastMessage,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';

interface CancelUploadPopoverProps {
  readonly closePopover: () => void;
  readonly closeLayer: () => void;
}
export const CancelUploadPopover = observer(function CancelUploadPopover({
  closePopover,
  closeLayer,
}: CancelUploadPopoverProps) {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  function cancelUpload() {
    uploadStore.resetFormStep();
    uploadStore.setFilesToUpload([]);
    showToastMessage(
      intl.formatMessage({
        id: 'metodej.form.upload.cancelUploadAlert.toast.success',
      }),
      BadgeAppearance.good
    );
    closePopover();
    closeLayer();
  }

  return (
    <Popover
      label={intl.formatMessage({
        id: 'metodej.form.upload.cancelUploadAlert.headline',
      })}
      buttons={
        <>
          <Button appearance={ButtonAppearance.Tertiary} onClick={closePopover}>
            {intl.formatMessage({
              id: 'metodej.form.upload.cancelUploadAlert.continueUploadButtonLabel',
            })}
          </Button>
          <Button appearance={ButtonAppearance.Primary} onClick={cancelUpload}>
            {intl.formatMessage({
              id: 'metodej.form.upload.cancelUploadAlert.cancelUploadButtonLabel',
            })}
          </Button>
        </>
      }
    >
      <Text appearance="_12M" as="p" align="center">
        {intl.formatMessage({
          id: 'metodej.form.upload.cancelUploadAlert.text',
        })}
      </Text>
    </Popover>
  );
});
