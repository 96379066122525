import {
  ButtonHTMLAttributes,
  ElementType,
  ReactElement,
  forwardRef,
} from 'react';
import { Text } from './text';
import { ThemeDefinition, css, styled } from './theme-provider';

export enum ButtonAppearance {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Error = 'error',
  Success = 'success',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly icon?: ReactElement;
  readonly appearance?: ButtonAppearance;
  readonly shortcut?: ReactElement;
  readonly as?: ElementType;
  readonly to?: string;
}

interface StyledButtonProps {
  readonly $appearance: ButtonAppearance;
  readonly $icon: boolean;
  readonly $text: boolean;
}

function getColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_6;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_35;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral_28;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_6;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral;
    }
  }
}

function getBackgroundColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red_8;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green_8;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Secondary:
        return theme.color.neutral_neutral_dark;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral_dark;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_10;
      case ButtonAppearance.Tertiary:
        return theme.color.background_background_03;
    }
  }
}

function getBackgroundHoverColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red_16;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green_16;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_dark;
      case ButtonAppearance.Secondary:
        return theme.color.neutral_neutral;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_dark;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_20;
      case ButtonAppearance.Tertiary:
        return theme.color.background_background_04;
    }
  }
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  flex-shrink: 0;
  transition: background-color 200ms ease;
  text-decoration: none;
  text-align: center;
  user-select: none;
  text-transform: uppercase;


  ${({ theme, $icon, $appearance, $text }) => css`
    column-gap: ${theme.size.x05};
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x1};
    padding: ${$text ? theme.size.x1 : theme.size.x075} ${$icon ? ($text ? theme.size.x1 : theme.size.x125) : theme.size.x15};
    gap: ${theme.size.x05};
    color: ${getColor(theme, $appearance)};
    background-color: ${getBackgroundColor(theme, $appearance)};

    :hover, :focus-visible, button:focus-visible > & {
      background-color: ${getBackgroundHoverColor(theme, $appearance)};
      outline: none;
    }

    :disabled {
      cursor: not-allowed;
      color: ${theme.dark ? theme.color.neutral_neutral : theme.color.neutral_neutral_36};
      background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.background_background_03};
    }
  `};
`;

const ShortcutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${({ theme }) => theme.size.x05};
`;
const IconWrapper = styled.div<{ readonly $hasText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, $hasText }) => css`
    width: ${$hasText ? theme.size.x2 : theme.size.x25};
    height: ${$hasText ? theme.size.x2 : theme.size.x25};
    svg {
      width: ${$hasText ? theme.size.x2 : theme.size.x25};
      height: ${$hasText ? theme.size.x2 : theme.size.x25};
    }
  `};
`;

const ButtonText = styled(Text)`
  display: flex;
  align-items: center;
  line-height: unset;
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      icon,
      children,
      appearance = ButtonAppearance.Primary,
      shortcut,
      ...buttonProps
    },
    ref
  ) {
    return (
      <StyledButton
        $appearance={appearance}
        $icon={Boolean(icon)}
        $text={Boolean(children)}
        ref={ref}
        {...buttonProps}
      >
        {icon && <IconWrapper $hasText={Boolean(children)}>{icon}</IconWrapper>}
        {children && (
          <ButtonText appearance="_12B" upperCase>
            {children}
          </ButtonText>
        )}
        {shortcut && <ShortcutWrapper>{shortcut}</ShortcutWrapper>}
      </StyledButton>
    );
  }
);
