import { Button, ButtonAppearance, Tippy } from '@yarmill/component-library';
import { Back } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';

export const FormBackButton = observer(function FormBackButton() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const label = 'metodej.upload.form.backButtonLabel';

  return (
    <Tippy tooltipContent={label} noWrapper>
      <Button
        type="button"
        appearance={ButtonAppearance.Tertiary}
        icon={<Back />}
        onClick={() => uploadStore.goToPreviousStep()}
        aria-label={intl.formatMessage({
          id: label,
        })}
      />
    </Tippy>
  );
});
