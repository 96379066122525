import {
  Button,
  ButtonAppearance,
  Popover,
  Text,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { getMetodejUrl } from '../utils/get-metodej-url';

interface CancelEditPopoverProps {
  readonly closePopover: () => void;
}
export const CancelEditPopover = observer(function CancelEditPopover({
  closePopover,
}: CancelEditPopoverProps) {
  const intl = useIntl();
  const {
    params: { id },
  } = useRouteMatch<{ readonly id: string }>();

  return (
    <Popover
      label={intl.formatMessage({
        id: 'metodej.form.upload.cancelEditAlert.headline',
      })}
      buttons={
        <>
          <Button appearance={ButtonAppearance.Tertiary} onClick={closePopover}>
            {intl.formatMessage({
              id: 'metodej.form.upload.cancelEditAlert.continueEditButtonLabel',
            })}
          </Button>
          <Button
            as={Link}
            to={`${getMetodejUrl('library/all')}/${id}`}
            appearance={ButtonAppearance.Primary}
          >
            {intl.formatMessage({
              id: 'metodej.form.upload.cancelEditAlert.cancelEditButtonLabel',
            })}
          </Button>
        </>
      }
    >
      <Text appearance="_12M" as="p" align="center">
        {intl.formatMessage({
          id: 'metodej.form.upload.cancelEditAlert.text',
        })}
      </Text>
    </Popover>
  );
});
