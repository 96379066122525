export const GROUP_SEARCH_PARAM = 'group';
export const ATHLETE_SEARCH_PARAM = 'athlete';
export const WEEK_SEARCH_PARAM = 'week';
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const ROUTE_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const CGM_URL_PARAM = 'measurementDay';
export const WORKOUT_URL_PARAM = 'workout';
export enum groupCookies {
  GROUP = 'yarmill-selected-group',
}
export enum athleteCookies {
  ATHLETE = 'yarmill-selected-athlete',
}
export enum userCookies {
  USER = 'yarmill-current-user',
  IMPERSONATED_USER = 'yarmill-imp-user',
}
export const DRAWER_WIDTH = '320px';
