import { css, styled } from '@yarmill/component-library';

export const AuthorsFieldLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: span 2;
  ${({ theme }) => css`
    margin-top: ${theme.size.x1};
    margin-bottom: ${theme.size.x2};
    column-gap: ${theme.size.x2};
    row-gap: ${theme.size.x1};
  `};
`;

export const AdditionalAuthorLastNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${({ theme }) => css`
    column-gap: ${theme.size.x2};
  `};
`;
