import {
  ButtonHTMLAttributes,
  ElementType,
  ReactElement,
  forwardRef,
} from 'react';
import { Text } from './text';
import { css, styled } from './theme-provider';

export type IconPosition = 'left' | 'right';

interface ButtonLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly icon?: ReactElement;
  readonly as?: ElementType;
  readonly to?: string;
  readonly iconPosition?: IconPosition;
}

interface StyledButtonProps {
  readonly $icon: boolean;
  readonly $text: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  flex-shrink: 0;
  transition: color 200ms ease;
  text-decoration: none;
  text-align: center;
  user-select: none;
  padding: 0;
  background: none;
  border: none;


  ${({ theme }) => css`
    column-gap: ${theme.size.x05};
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x1};
    gap: ${theme.size.x05};
    color: ${theme.color.neutral_neutral_64};

    :hover, :focus-visible, button:focus-visible > & {
      color: ${theme.color.neutral_neutral_36};
      text-decoration: underline;
      outline: none;
    }

    :disabled {
      cursor: not-allowed;
      color: ${theme.color.neutral_neutral};
    }
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    width: ${theme.size.x25};
    height: ${theme.size.x25};
    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }
  `};
`;

const ButtonText = styled(Text)`
  display: flex;
  align-items: center;
  line-height: unset;
`;

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  function ButtonLink(
    { icon, children, iconPosition = 'left', ...buttonProps },
    ref
  ) {
    return (
      <StyledButton
        $icon={Boolean(icon)}
        $text={Boolean(children)}
        ref={ref}
        {...buttonProps}
      >
        {icon && iconPosition === 'left' && <IconWrapper>{icon}</IconWrapper>}
        {children && <ButtonText appearance="_10B">{children}</ButtonText>}
        {icon && iconPosition === 'right' && <IconWrapper>{icon}</IconWrapper>}
      </StyledButton>
    );
  }
);
