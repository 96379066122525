import { Author } from '../types';

export function getFormattedAuthorNames(authors: Author[]): string {
  if (authors.length <= 3) {
    return authors
      .map(author => `${author.firstName} ${author.lastName}`)
      .join(', ');
  }
  return `${authors[0].firstName} ${authors[0].lastName} et al.`;
}
