import { TextAppearance, css, styled } from './theme-provider';

export interface TextProps {
  readonly appearance: TextAppearance;
  readonly whiteSpace?: 'noWrap' | 'pre-wrap';
  readonly align?: 'left' | 'center' | 'right';
  readonly ellipsis?: boolean;
  readonly upperCase?: boolean;
  readonly strikeThrough?: boolean;
  readonly lineClamp?: number;
  readonly capitalize?: boolean;
  readonly wordBreak?: 'break-word' | 'break-all' | 'keep-all';
}

export function getTextAppearanceStyles(appearance: TextAppearance) {
  return css`
    font-size: ${({ theme }) => theme.text.appearance[appearance].fontSize};
    font-weight: ${({ theme }) => theme.text.appearance[appearance].fontWeight};
    line-height: ${({ theme }) => theme.text.appearance[appearance].lineHeight};
    letter-spacing: ${({ theme }) => theme.text.appearance[appearance].letterSpacing};
    font-feature-settings: ${({ theme }) => theme.text.appearance[appearance].fontFeatureSettings};
    font-variant-numeric: ${({ theme }) => theme.text.appearance[appearance].fontVariantNumeric};
  `;
}
export const Text = styled.span<TextProps>`
  font-family: ${({ theme }) =>
    `${theme.text.font.default}, ${theme.text.font.emoji}`};
  ${({ appearance }) => getTextAppearanceStyles(appearance)}

  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ align }) => align ?? 'inherit'};
  color: inherit;
  padding: 0;
  margin: 0;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
      hyphens: auto;
    `}

  ${({ strikeThrough }) =>
    strikeThrough &&
    css`
      text-decoration: line-through;
    `}

  ${({ lineClamp }) =>
    lineClamp &&
    css`
      display: -moz-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-pack: end;
      -moz-box-orient: vertical;
      line-clamp: ${lineClamp};
      -webkit-line-clamp: ${lineClamp};
      overflow: hidden;
    `}
`;
