import { Text, css, styled } from '@yarmill/component-library';
import { EmptyUploadListItemCheck, EmptyUploadListItemUpload } from './assets';

interface DocumentEmptyUploadListItemProps {
  readonly label: string;
  readonly type: 'control' | 'upload';
}

const Layout = styled.div`
  display: grid;
  align-items: center;

  ${({ theme }) => css`
    border: ${theme.size.x025} solid ${theme.color.background_background_03};
    column-gap: ${theme.size.x2};
    padding: ${theme.size.x15};
    border-radius: ${theme.size.x15};
    color: ${theme.color.neutral_neutral_36};
    grid-template-columns: auto 1fr;
  `}
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_36};
  `};
`;

export function DocumentEmptyUploadListItem({
  label,
  type,
}: DocumentEmptyUploadListItemProps) {
  return (
    <Layout>
      <PreviewWrapper>
        {type === 'upload' ? (
          <EmptyUploadListItemUpload />
        ) : (
          <EmptyUploadListItemCheck />
        )}
      </PreviewWrapper>
      <Text appearance="_12M">{label}</Text>
    </Layout>
  );
}
