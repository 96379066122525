import { PropsWithChildren, forwardRef } from 'react';
import { Text } from './text';
import { styled } from './theme-provider';

const StyledFormInputLabel = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.color.neutral_neutral_dark};
`;

type FormInputLabelProps = PropsWithChildren<{
  readonly align?: 'left' | 'right';
  readonly htmlFor: string;
  readonly id?: string;
}>;

export const FormInputLabel = forwardRef<HTMLLabelElement, FormInputLabelProps>(
  function FormInputLabel({ children, htmlFor, align, id }, ref) {
    return (
      <StyledFormInputLabel
        as="label"
        appearance="_12B"
        htmlFor={htmlFor}
        align={align}
        whiteSpace="noWrap"
        id={id}
        ref={ref}
      >
        {children}
      </StyledFormInputLabel>
    );
  }
);
