import { Down } from '@yarmill/icon-library';
import { ReactElement, useMemo } from 'react';
import { DropdownProvider } from './dropdown-provider';
import { Text } from './text';
import { Color, css, styled } from './theme-provider';

export interface SelectBoxInputOption<V> {
  readonly value: V;
  readonly icon?: ReactElement;
  readonly label: string;
  readonly color?: Color;
}

interface SelectBoxInputProps<T> {
  readonly id: string;
  readonly options: SelectBoxInputOption<T>[];
  readonly value?: T;
  readonly emptyValueLabel: string;
  readonly 'aria-labelledby'?: string;
  readonly searchable?: boolean;
  readonly onChange?: (value: T) => void;
}

const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_dark};
    background-color: ${theme.color.neutral_white};
    border: 1px solid ${theme.color.background_background_04};
    border-radius: ${theme.size.x075};
    padding: ${theme.size.x05} ${theme.size.x1};

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }

    :hover {
      background-color: ${theme.color.background_background_01};
    }

    button:focus-within &, button[aria-expanded='true'] & {
      border-color: ${theme.color.brand_yarmill};
    };
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }
  `};
`;

const EmptyValueText = styled(Text)`
    color: ${({ theme }) => theme.color.neutral_neutral};
`;

export function SelectBoxInput<V>({
  options,
  value,
  emptyValueLabel,
  id,
  'aria-labelledby': ariaLabelledby,
  searchable,
  onChange,
}: SelectBoxInputProps<V>) {
  const selectedOption = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  );

  return (
    <DropdownProvider
      options={options}
      selectedValue={value}
      aria-labelledby={ariaLabelledby}
      id={id}
      searchable={searchable}
      handleSelect={onChange as (value: unknown) => void}
    >
      <Input>
        {selectedOption && (
          <Text appearance="_12M">{selectedOption.label}</Text>
        )}
        {!selectedOption && (
          <EmptyValueText appearance="_12M">{emptyValueLabel}</EmptyValueText>
        )}
        <IconWrapper>
          <Down />
        </IconWrapper>
      </Input>
    </DropdownProvider>
  );
}
