import { useState } from 'react';
import { DataItem } from '../../reporting/types';
import { BarChart } from '../types';
import { getBarDimensions } from '../utils/get-bar-dimensions';
import { useXYChartContext } from '../xy-chart-context';
import { Bar } from './primitives/bar';

interface BarsProps {
  readonly config: BarChart;
  readonly data: DataItem[];
}

export function Bars({ config, data }: BarsProps) {
  const { xScale, yScale } = useXYChartContext();
  const highlightAttribute = config.highlightAttribute;
  const [highlightedValue, setHighlightedValue] = useState<
    number | string | null | undefined
  >(undefined);

  return (
    <>
      {data.map((item, idx) => {
        const dimensions = getBarDimensions(config, item, xScale, yScale);

        if (!dimensions) {
          return null;
        }

        const highlightColor = config.getHighlightColor(item);
        const isHighlighted = Boolean(
          highlightAttribute && item[highlightAttribute] === highlightedValue
        );

        const { x, y, width, height } = dimensions;

        let adjustedY = y;
        let adjustedHeight = height;

        // Adjust min height to show at least something if the value is not 0
        if (config.getYValue(item) && height === 0) {
          adjustedHeight = 0.5;
          adjustedY = y - adjustedHeight;
        }

        return (
          <Bar
            key={idx}
            x={x}
            y={adjustedY}
            width={width}
            height={adjustedHeight}
            color={config.getColor(item)}
            opacity={config.getOpacity(item) ?? 1}
            highlightColor={highlightColor}
            isHighlighted={isHighlighted}
            onMouseEnter={
              highlightAttribute
                ? () => setHighlightedValue(item[highlightAttribute])
                : undefined
            }
            onMouseLeave={
              highlightAttribute
                ? () => setHighlightedValue(undefined)
                : undefined
            }
          />
        );
      })}
    </>
  );
}
