import { Text } from '@visx/text';
import { LABEL_FONT_SIZE } from '../../reporting/const';
import { DataLabelDefinition, DataLabelPositionParamenters } from '../types';

interface DataLabelProps {
  readonly label: DataLabelDefinition & DataLabelPositionParamenters;
  readonly fontSize: number;
  readonly angle: number;
}
export function DataLabel({ label, fontSize, angle }: DataLabelProps) {
  return (
    <Text
      x={label.x}
      y={label.y}
      width={Math.abs(label.angle ?? 0) === 90 ? undefined : label.maxWidth}
      verticalAnchor="middle"
      textAnchor="middle"
      angle={angle}
      fontSize={fontSize ?? LABEL_FONT_SIZE}
      fill={label.color || '#4a4a4a'}
      pointerEvents="none"
    >
      {label.text}
    </Text>
  );
}
