import { useLogger } from '@yarmill/utils';
import { FormikContext, FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormContext } from '../context/document-form-context';
import { DocumentStore } from '../stores/document-store';
import { MetodejFormValues, MetodejFormik } from '../types';
import { handleSaveFormValues } from '../utils/handle-save-form-values';
import { FormLayout } from './form-layout';

interface DocumentFormProps {
  readonly documentStore: DocumentStore;
  readonly autoSave?: boolean;
  readonly formikRef?: MutableRefObject<MetodejFormik | undefined>;
}

export const DocumentForm = observer(function DocumentForm({
  documentStore,
  autoSave = false,
  formikRef,
}: DocumentFormProps) {
  const logger = useLogger();
  const intl = useIntl();

  const handleFormSubmit = useCallback(
    async (
      values: MetodejFormValues,
      helpers: FormikHelpers<MetodejFormValues>
    ) => {
      if (!autoSave) {
        await handleSaveFormValues(
          values,
          documentStore,
          intl,
          helpers,
          logger
        );
      }
    },
    [documentStore, logger, intl, autoSave]
  );

  const formik = useFormik<MetodejFormValues>({
    initialValues: documentStore.formValues,
    onSubmit: handleFormSubmit,
    validate: (values: MetodejFormValues) => {
      const errors: Record<string, string> = {};

      const requiredFields: (keyof MetodejFormValues)[] = [
        'title',
        'documentFormat',
        'documentType',
      ];
      requiredFields.forEach(field => {
        if (!values[field]) {
          errors[field] = intl.formatMessage(
            {
              id: 'metodej.document.form.field.error.required',
            },
            { name: field }
          );
        }
      });

      if (!values.authors[0]?.firstName) {
        errors['authors[0].firstName'] = intl.formatMessage(
          {
            id: 'metodej.document.form.field.error.required',
          },
          { name: 'firstName' }
        );
      }

      if (!values.authors[0]?.lastName) {
        errors['authors[0].lastName'] = intl.formatMessage(
          {
            id: 'metodej.document.form.field.error.required',
          },
          { name: 'lastName' }
        );
      }

      if (Object.keys(errors).length > 0) {
        return errors;
      }

      return undefined;
    },
  });

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  const documentFormContextValue = useMemo(
    () => ({ documentStore, autoSave }),
    [autoSave, documentStore]
  );

  return (
    <DocumentFormContext.Provider value={documentFormContextValue}>
      <FormikContext.Provider value={formik}>
        <FormLayout />
      </FormikContext.Provider>
    </DocumentFormContext.Provider>
  );
});
