import { observer } from 'mobx-react-lite';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { UploadFormStep1 } from './upload-form-step-1';
import { UploadFormStep2 } from './upload-form-step-2';

export const UploadForm = observer(function UploadForm() {
  const uploadStore = useDocumentsUploadStore();
  const currentStep = uploadStore.currentFormStep;

  return (
    <>
      {currentStep === 1 && <UploadFormStep1 />}
      {currentStep === 2 && <UploadFormStep2 />}
    </>
  );
});
