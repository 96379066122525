import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import { styled } from '../theme-provider';
import { FullscreenLayer } from './fullscreen-layer';
import { LayerContext } from './layer-context';
import { Overlay } from './overlay';

type LayerProviderProps = PropsWithChildren<{
  readonly content: (close: () => void) => ReactElement;
  readonly modal?: boolean;
}>;

const LayerButton = styled.button`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  :focus-visible {
    outline: none;
  }
`;

export function LayerProvider({ children, content }: LayerProviderProps) {
  const [isLayerOpened, setIsLayerOpened] = useState<boolean>(false);
  const { refs, context } = useFloating({
    open: isLayerOpened,
    onOpenChange: setIsLayerOpened,
  });

  const role = useRole(context, {
    role: 'dialog',
  });
  const click = useClick(context);

  const { isMounted, styles: layerTransitionStyles } = useTransitionStyles(
    context,
    {
      initial: {
        opacity: 0,
        transform: 'scale(0.95)',
      },
      close: { opacity: 0 },
      duration: 200,
    }
  );
  const { styles: overlayTransitionStyles } = useTransitionStyles(context, {
    initial: {
      background: 'rgba(47, 46, 69, 0)',
    },
    open: {
      background: 'rgba(47, 46, 69, 1)',
    },
    close: {
      background: 'rgba(47, 46, 69, 0)',
      transitionTimingFunction: `cubic-bezier(0, 0, 0.08, 1.02)`,
    },
    duration: 200,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
  ]);

  const contextValue = useMemo(
    () => ({ closeLayer: () => setIsLayerOpened(false) }),
    []
  );

  return (
    <>
      <LayerButton
        ref={refs.setReference}
        {...getReferenceProps()}
        type="button"
      >
        {children}
      </LayerButton>
      <FloatingPortal>
        {isMounted && (
          <Overlay style={overlayTransitionStyles}>
            <FullscreenLayer
              {...getFloatingProps()}
              style={layerTransitionStyles}
              ref={refs.setFloating}
            >
              <FloatingFocusManager context={context}>
                <LayerContext.Provider value={contextValue}>
                  {content(() => setIsLayerOpened(false))}
                </LayerContext.Provider>
              </FloatingFocusManager>
            </FullscreenLayer>
          </Overlay>
        )}
      </FloatingPortal>
    </>
  );
}
