import { Button, ButtonAppearance, Tippy } from '@yarmill/component-library';
import { Back } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getMetodejUrl } from '../utils/get-metodej-url';

export const DetailBackButton = observer(function DetailBackButton() {
  const intl = useIntl();
  const label = 'metodej.upload.form.backButtonLabel';

  return (
    <Tippy tooltipContent={label} noWrapper>
      <Button
        as={Link}
        to={getMetodejUrl('library')}
        appearance={ButtonAppearance.Tertiary}
        icon={<Back />}
        aria-label={intl.formatMessage({
          id: label,
        })}
      />
    </Tippy>
  );
});
