import {
  ButtonLink,
  ExternalLink,
  Popover,
  Text,
} from '@yarmill/component-library';
import { Back, Forward } from '@yarmill/icon-library';
import { useConfig, useIntlStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormGuidePopoverStep1 } from '../components/assets/form-guide-popover-step-1';
import { FormGuidePopoverStep2 } from '../components/assets/form-guide-popover-step-2';
import { FormGuidePopoverStep3 } from '../components/assets/form-guide-popover-step-3';
import {
  FormGuidePopoverLayout,
  List,
  Paragraph,
} from '../components/form-guide-popover-layout';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';
import { formatAuthorAgreementUrl } from '../utils/format-author-agreement-url';

interface FormGuidePopoverProps {
  readonly closePopover: () => void;
}

function getImageForStep(formStep: number) {
  switch (formStep) {
    case 1:
      return <FormGuidePopoverStep1 />;
    case 2:
      return <FormGuidePopoverStep2 />;
    case 3:
      return <FormGuidePopoverStep3 />;
    default:
      return <></>;
  }
}

export const FormGuidePopover = observer(function FormGuidePopover({
  closePopover,
}: FormGuidePopoverProps) {
  const intl = useIntl();
  const intlStore = useIntlStore();
  const uploadStore = useDocumentsUploadStore();
  const agreementFormUrl = useConfig('metodejAuthorAgreementFormUrl');
  const formStep = uploadStore.currentFormStep;
  const focusedItem = uploadStore.focusedItem;
  const adjustedFormStep = focusedItem instanceof DocumentStore ? 3 : formStep;
  const [guideStep, setGuideStep] = useState(adjustedFormStep);

  useEffect(() => {
    if (guideStep < 1 || guideStep > 3) {
      closePopover();
    }
  }, [guideStep, closePopover]);

  const handleBackButtonClick = useCallback(() => {
    setGuideStep(s => (s > 1 ? s - 1 : s));
  }, []);
  const handleNextButtonClick = useCallback(() => {
    setGuideStep(s => (s < 3 ? s + 1 : s));
  }, []);

  return (
    <Popover
      label={intl.formatMessage({
        id: `metodej.form.upload.guidePopover.headline.step${guideStep}`,
      })}
      handleCloseClick={closePopover}
      noMinWidth
    >
      <FormGuidePopoverLayout
        image={getImageForStep(guideStep)}
        headline={intl.formatMessage({
          id: `metodej.upload.form.guidePopover.step${guideStep}.title`,
        })}
        stepName={intl.formatMessage({
          id: `metodej.upload.form.guidePopover.step${guideStep}.stepBadge`,
        })}
        text={intl.formatMessage<any, any>(
          {
            id: `metodej.upload.form.guidePopover.step${guideStep}.text`,
          },
          {
            p: (content: string) => (
              <Paragraph as="p" appearance="_12M">
                {content}
              </Paragraph>
            ),
            ol: (content: string) => (
              <List as="ol" appearance="_12M">
                {content}
              </List>
            ),
            ul: (content: string) => (
              <List as="ul" appearance="_12M">
                {content}
              </List>
            ),
            li: (content: string) => (
              <Text as="li" appearance="_12M">
                {content}
              </Text>
            ),
            formLink: (content: string) => (
              <ExternalLink
                href={formatAuthorAgreementUrl(
                  agreementFormUrl,
                  intlStore.locale
                )}
                target="_blank"
              >
                {content}
              </ExternalLink>
            ),
          }
        )}
        description={
          intl.messages[
            `metodej.upload.form.guidePopover.step${guideStep}.description`
          ]
            ? intl.formatMessage({
                id: `metodej.upload.form.guidePopover.step${guideStep}.description`,
              })
            : undefined
        }
        backButton={
          guideStep > 1 ? (
            <ButtonLink onClick={handleBackButtonClick} icon={<Back />}>
              {intl.formatMessage({
                id: `metodej.upload.form.guidePopover.previousStepButtonLabel`,
                defaultMessage: 'Předchozí',
              })}
            </ButtonLink>
          ) : undefined
        }
        nextButton={
          guideStep < 3 ? (
            <ButtonLink
              onClick={handleNextButtonClick}
              icon={<Forward />}
              iconPosition="right"
            >
              {intl.formatMessage({
                id: `metodej.upload.form.guidePopover.nextStepButtonLabel`,
                defaultMessage: 'Následující',
              })}
            </ButtonLink>
          ) : undefined
        }
      />
    </Popover>
  );
});
