import {
  Button,
  ButtonAppearance,
  LayerProvider,
  Tippy,
} from '@yarmill/component-library';
import { Plus } from '@yarmill/icon-library';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { UploadLayer } from '../upload/upload-layer';

export const UploadFilesButton = observer(function UploadFilesButton() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const label = 'metodej.upload.form.uploadFiles';

  function onClick() {
    runInAction(() => {
      uploadStore.resetFormStep();
      uploadStore.setFocusedItem(null);
      uploadStore.setFilesToUpload([]);
    });
  }

  return (
    <Tippy tooltipContent={label} noWrapper>
      <LayerProvider
        content={closeLayer => <UploadLayer closeLayer={closeLayer} />}
      >
        <Button
          as="div"
          appearance={ButtonAppearance.Primary}
          icon={<Plus />}
          onClick={onClick}
          aria-label={intl.formatMessage({
            id: label,
          })}
        />
      </LayerProvider>
    </Tippy>
  );
});
