import {
  AppLogo,
  Avatar,
  Totem,
  TotemButton,
  TotemLayout,
} from '@yarmill/component-library';
import { Menu } from '@yarmill/icon-library';
import {
  apiTarget,
  instance,
  useAvatar,
  useConfig,
  useCurrentUserStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const defaultLogo = `https://${instance}.api.yarmill.com/Assets/fe/instance/logo/logo.png`;

export const MetodejTotem = observer(function MetodejTotem(): ReactElement {
  const currentUser = useCurrentUserStore();
  const logo = useConfig('logoSmall');
  const logoUrl = logo ? `${apiTarget}/${logo}` : defaultLogo;
  const avatar = useAvatar(currentUser?.avatar);
  const intl = useIntl();

  function openCommandPalette() {
    document.dispatchEvent(
      new KeyboardEvent('keydown', {
        metaKey: true,
        key: 'k',
      })
    );
  }

  return (
    <TotemLayout>
      <Totem
        logo={<AppLogo src={logoUrl} alt="logo" />}
        avatar={<Avatar src={avatar} $size={3} />}
        avatarAriaLabel={intl.formatMessage({
          id: 'metodej.totem.avatar.ariaLabel',
        })}
        handleAvatarClick={openCommandPalette}
        sideMenuToggle={
          <TotemButton type="button">
            <Menu />
          </TotemButton>
        }
      />
    </TotemLayout>
  );
});
