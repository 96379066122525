import { Multiply } from '@yarmill/icon-library';
import { MouseEventHandler, forwardRef } from 'react';
import { Text } from './text';
import { ThemeDefinition, css, styled } from './theme-provider';

export enum TagAppearance {
  neutral = 'neutral',
  plum = 'plum',
  orangineMild = 'orangine-mild',
  yellow = 'yellow',
  frog = 'frog',
  brand = 'brand',
}

interface TagProps {
  readonly text: string;
  readonly editable?: boolean;
  readonly onRemoveClick?: MouseEventHandler;
  readonly removeButtonAriaLabel?: string;
  readonly appearance?: TagAppearance;
}

function getAppearanceColors(
  appearance: TagAppearance,
  theme: ThemeDefinition
) {
  switch (appearance) {
    case TagAppearance.neutral:
      return {
        color: theme.color.neutral_neutral,
        background: theme.color.background_background_04,
        iconColor: theme.color.neutral_neutral_64,
      };
    case TagAppearance.plum:
      return {
        color: theme.color.other_plum_dark,
        background: theme.color.other_plum_24,
        iconColor: theme.color.other_plum,
      };
    case TagAppearance.orangineMild:
      return {
        color: theme.color.other_orangine_mild_dark,
        background: theme.color.other_orangine_mild_24,
        iconColor: theme.color.other_orangine_mild,
      };
    case TagAppearance.yellow:
      return {
        color: theme.color.other_yellow_dark,
        background: theme.color.other_yellow_24,
        iconColor: theme.color.other_yellow,
      };
    case TagAppearance.frog:
      return {
        color: theme.color.other_frog_dark,
        background: theme.color.other_frog_24,
        iconColor: theme.color.other_frog,
      };
    case TagAppearance.brand:
      return {
        color: theme.color.brand_yarmill,
        background: theme.color.brand_yarmill_10,
        iconColor: theme.color.brand_yarmill,
      };
  }
}

const Layout = styled.div<{
  readonly $appearance: TagAppearance;
  readonly editable: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ theme, $appearance, editable }) => {
    const appearanceColors = getAppearanceColors($appearance, theme);
    return css`
      column-gap: ${theme.size.x05};
      padding: ${editable ? theme.size.x075 : theme.size.x025} ${theme.size.x1};
      border-radius: ${theme.borderRadius.x1};
      color: ${appearanceColors?.color};
      background-color: ${appearanceColors?.background};
  `;
  }}
`;

const RemoveButton = styled.button<{ readonly $appearance: TagAppearance }>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;


  ${({ theme, $appearance }) => {
    const appearanceColors = getAppearanceColors($appearance, theme);
    return css`
      width: ${theme.size.x25};
      height: ${theme.size.x25};
      color: ${appearanceColors?.iconColor};

      :hover {
        color: ${appearanceColors?.color};
      }

      svg {
        width: ${theme.size.x25};
        height: ${theme.size.x25};
      }
  `;
  }}
`;

export const Tag = forwardRef<HTMLDivElement, TagProps>(function Tag(
  {
    text,
    appearance = TagAppearance.plum,
    editable = false,
    onRemoveClick,
    removeButtonAriaLabel,
  },
  ref
) {
  return (
    <Layout ref={ref} $appearance={appearance} editable={editable}>
      <Text appearance="_12M">{text}</Text>
      {editable && (
        <RemoveButton
          type="button"
          onClick={onRemoveClick}
          aria-label={removeButtonAriaLabel}
          $appearance={appearance}
        >
          <Multiply />
        </RemoveButton>
      )}
    </Layout>
  );
});
