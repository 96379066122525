import { Link, StyledTd, Text, TextSize, styled } from '@yarmill/components';
import {
  ReportingTableAlignment,
  ReportingTableVerticalAlignment,
} from './types';

interface StyledReportingTableCellProps {
  borderRight?: boolean;
  borderLeft?: boolean;
  color?: string;
  valueVerticalAlignment?: ReportingTableVerticalAlignment | null;
  textSize?: TextSize | null;
}

export const StyledReportingTableCell = styled(
  StyledTd
)<StyledReportingTableCellProps>`
  padding: 4px 8px;
  font-family: "Roboto Mono", "Apple Color Emoji", "Noto Color Emoji", serif;
  text-align: right;
  ${({ borderRight }) => borderRight && 'border-right: 1px solid #e0e0e0;'}
  ${({ borderLeft }) => borderLeft && 'border-left: 1px solid #e0e0e0;'}
  border-left-color: #eaeaea;
  border-right-color: #eaeaea;
  font-size: ${({ textSize }) => (textSize ? textSize : 12)}px;
  ${({ color }) => color && `color: ${color};`};
  vertical-align: ${({ valueVerticalAlignment }) =>
    valueVerticalAlignment === 'center'
      ? 'middle'
      : (valueVerticalAlignment ?? 'middle')};

  @media print {
    page-break-inside: avoid;
  }
`;
export const IconWrapper = styled(Text)<{
  readonly align?: ReportingTableAlignment;
}>`
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
  justify-content: ${({ align }) =>
    align === 'right'
      ? 'flex-end'
      : align === 'center'
        ? 'center'
        : 'flex-start'};
`;
export const ReportLink = styled(Link)`
  color: inherit;

  &:has(svg[data-cy="icon"]) {
    &:hover {
      color: inherit;
      opacity: 0.85;
    }
  }
`;
