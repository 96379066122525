import { Text, css, styled } from '@yarmill/component-library';
import { Upload } from '@yarmill/icon-library';
import {
  ChangeEventHandler,
  DragEvent,
  DragEventHandler,
  RefObject,
  useState,
} from 'react';

interface SingleUploadFrameProps {
  readonly buttonText: string;
  readonly primaryText?: string;
  readonly secondaryText: string;
  readonly accept?: string;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly onChange?: ChangeEventHandler<HTMLInputElement>;
  readonly onDrop?: DragEventHandler<HTMLLabelElement>;
}

const FileInput = styled.input`
  display: none;
`;

interface LayoutProps {
  readonly $hasDragOver: boolean;
}
const Layout = styled.label.attrs<LayoutProps>(({ $hasDragOver, theme }) => ({
  style: $hasDragOver
    ? { backgroundColor: theme.color.brand_yarmill_6 }
    : undefined,
}))<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease;
  cursor: pointer;

  ${({ theme }) => css`
    border: ${theme.size.x0125} dashed ${theme.color.brand_yarmill_56};
    border-radius: ${theme.size.x15};
    padding:${theme.size.x2};
    column-gap: ${theme.size.x1};

    &:hover {
      background-color: ${theme.color.brand_yarmill_6};
    }
  `}
`;

const UnderlineText = styled(Text)`
  text-decoration: underline;
  ${({ theme }) => css`
    color: ${theme.color.brand_yarmill};
  `}
`;
const PrimaryText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_dark};
  `}
`;
const SecondaryText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_64};
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.brand_yarmill};
    width: ${theme.size.x3};
    height: ${theme.size.x3};

    svg {
      width: ${theme.size.x3};
      height: ${theme.size.x3};
    }
  `};
`;
export function SingleUploadFrame({
  buttonText,
  primaryText,
  secondaryText,
  inputRef,
  onChange,
  onDrop,
  accept,
}: SingleUploadFrameProps) {
  const [hasDragOver, setHasDragOver] = useState(false);

  function onDragOver(e: DragEvent<HTMLLabelElement>) {
    e.preventDefault();
    setHasDragOver(true);
  }

  return (
    <Layout
      $hasDragOver={hasDragOver}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={() => setHasDragOver(false)}
    >
      <FileInput
        type="file"
        ref={inputRef}
        onChange={onChange}
        accept={accept}
      />
      <IconWrapper>
        <Upload />
      </IconWrapper>
      <Text as="span" appearance="_12M">
        <UnderlineText appearance="_12M" as="span">
          {buttonText}
        </UnderlineText>
        <PrimaryText appearance="_12M" as="span">
          {primaryText}
        </PrimaryText>
        <SecondaryText appearance="_12M" as="span">
          {secondaryText}
        </SecondaryText>
      </Text>
    </Layout>
  );
}
