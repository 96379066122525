import { DrawerSection } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { DocumentEmptyUploadListItem } from '../components';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentToCheck } from './document-to-check';

export const DocumentsToCheckList = observer(function DocumentsToCheckList() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const documents = uploadStore.documentsToCheck;

  return (
    <DrawerSection
      label={intl.formatMessage({
        id: 'metodej.upload.drawer.documentsToCheckHeadline',
      })}
    >
      {documents.size > 0 ? (
        Array.from(documents.values()).map(documentStore => (
          <DocumentToCheck
            key={documentStore.documentId}
            documentStore={documentStore}
          />
        ))
      ) : (
        <DocumentEmptyUploadListItem
          label={intl.formatMessage({
            id: 'metodej.upload.drawer.emptyCheckList',
          })}
          type="control"
        />
      )}
    </DrawerSection>
  );
});
