import { AriaAttributes, SyntheticEvent, forwardRef, useCallback } from 'react';
import { css, styled } from './theme-provider';

type RadioSize = 3 | 2;

export type HandleRadioEvent = (
  e: SyntheticEvent<HTMLInputElement>,
  props: RadioProps
) => void;
export interface RadioProps
  extends Omit<AriaAttributes, 'aria-hidden' | 'aria-live' | 'aria-relevant'> {
  readonly checked?: boolean;
  readonly defaultChecked?: boolean;
  readonly disabled?: boolean;
  readonly name?: string;
  readonly id: string;
  readonly onBlur?: HandleRadioEvent;
  readonly onChange?: HandleRadioEvent;
  readonly onClick?: HandleRadioEvent;
  readonly onFocus?: HandleRadioEvent;
  readonly required?: boolean;
  readonly tabIndex?: -1 | 0;
  readonly value?: string;
  readonly 'aria-labelledby': string;
  readonly size?: RadioSize;
}
const noop = () => undefined;

const RadioContainer = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const Bullet = styled.div<{ readonly $size: RadioSize }>`
    display: flex;
    background-color: currentColor;
    border-radius: 50%;

    ${({ theme, $size }) => css`

        width: ${$size === 3 ? theme.size.x125 : theme.size.x075};
        height: ${$size === 3 ? theme.size.x125 : theme.size.x075};
    `};

`;

const RadioWrapper = styled.div<{
  readonly disabled?: boolean;
  readonly $size: RadioSize;
}>`
	position: relative;
	display: flex;
  justify-content: center;
	align-items: center;
  border-style: solid;
  transition: background 250ms ease, border 250ms ease;

  ${({ theme, disabled, $size }) => css`
    width: ${$size === 3 ? theme.size.x3 : theme.size.x2};
    height: ${$size === 3 ? theme.size.x3 : theme.size.x2};
    border-radius: 50%;
    border-width: ${theme.size.x025};
    border-color: ${theme.dark ? theme.color.neutral_neutral : theme.color.brand_yarmill};
    background-color: ${theme.dark ? theme.color.neutral_black : theme.color.neutral_white};

    &:hover {
        ${
          !disabled &&
          css`
            color: ${theme.dark ? theme.color.neutral_neutral_64 : theme.color.brand_yarmill_35};
            background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_16};
        `
        };
    }

    ${Bullet} {
      opacity: 0;
    }
  `};
`;

const StyledInput = styled.input<{
  readonly disabled?: boolean;
}>`
	position: absolute;
	opacity: 0;
	outline: 0;
	user-select: none;
	pointer-events: none;

  ${({ theme, disabled }) => css`
    &:focus-visible {
      + ${RadioWrapper} {
        color: ${theme.dark ? theme.color.neutral_neutral_64 : theme.color.brand_yarmill_35};
        background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_16};
      }
    }
    &:checked {
      + ${RadioWrapper} {
        background-color: ${theme.dark ? theme.color.neutral_neutral : theme.color.brand_yarmill};
        color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_6};

        ${Bullet} {
          opacity: 1;
        }

        &:hover {
          ${
            !disabled &&
            css`
              border-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_dark};
              background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_dark};
              color: ${theme.dark ? theme.color.neutral_black : theme.color.brand_yarmill_6};
            `
          }
        }
      }

      &:focus-visible {
        + ${RadioWrapper} {
          background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.brand_yarmill_dark};
        }
      }
    }
  `};

	:disabled {
		+ ${RadioWrapper} {
			cursor: not-allowed;
			opacity: 0.75;
		}
	}
`;
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  function Radio(props, ref) {
    const {
      checked,
      defaultChecked,
      disabled,
      name,
      value,
      required,
      tabIndex,
      id,
      size = 3,
      'aria-labelledby': ariaLabelledBy,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onClick = noop,
    } = props;

    const handleChange = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onChange(e, props);
      },
      [onChange, props]
    );
    const handleBlur = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onBlur(e, props);
      },
      [onBlur, props]
    );
    const handleFocus = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onFocus(e, props);
      },
      [onFocus, props]
    );
    const handleClick = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onClick(e, props);
      },
      [onClick, props]
    );

    return (
      <RadioContainer>
        <StyledInput
          aria-labelledby={ariaLabelledBy}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={handleClick}
          onFocus={handleFocus}
          ref={ref}
          required={required}
          tabIndex={tabIndex}
          type="radio"
          value={value}
        />
        <RadioWrapper aria-hidden disabled={disabled} $size={size}>
          <Bullet $size={size} />
        </RadioWrapper>
      </RadioContainer>
    );
  }
);
