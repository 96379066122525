import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { AllDocuments } from '../library/all-documents';
import { DocumentDetail } from '../library/document-detail';
import { DocumentDetailForm } from '../library/document-detail-form';
import { Overview } from '../library/overview';
import { RecentDocuments } from '../library/recent-documents';
import { getMetodejUrl } from '../utils/get-metodej-url';

export const LibraryPage = observer(function LibraryPage() {
  return (
    <Switch>
      <Route exact path={getMetodejUrl('library')}>
        <Overview />
      </Route>
      <Route exact path={getMetodejUrl('library/recent')}>
        <RecentDocuments />
      </Route>
      <Route exact path={getMetodejUrl('library/all')}>
        <AllDocuments />
      </Route>
      <Route path={`${getMetodejUrl('library/all')}/:id/edit`}>
        <DocumentDetailForm />
      </Route>
      <Route path={`${getMetodejUrl('library/all')}/:id`}>
        <DocumentDetail />
      </Route>
    </Switch>
  );
});
