import { PropsWithChildren } from 'react';
import { Text } from './text';
import { css, styled } from './theme-provider';

type DrawerSectionProps = PropsWithChildren<{
  readonly label: string;
}>;

const Label = styled(Text)`
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  ${({ theme }) => css`
    height: ${theme.size.x4};
    color: ${theme.color.neutral_neutral};
    margin-bottom: ${theme.size.x1};
  `};
`;

const Layout = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.size.x1};

    & + & {
      margin-top: ${theme.size.x1};
    }
  `}
`;

const ChildrenWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  ${({ theme }) => css`
    row-gap: ${theme.size.x1};
  `}
`;

export function DrawerSection({ label, children }: DrawerSectionProps) {
  return (
    <Layout>
      <Label appearance="_12B" as="div">
        {label}
      </Label>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Layout>
  );
}
