import { createContext } from 'react';
import { DocumentStore } from '../stores/document-store';

interface DocumentFormContextValue {
  readonly documentStore: DocumentStore;
  readonly autoSave: boolean;
}
export const DocumentFormContext = createContext<
  DocumentFormContextValue | undefined
>(undefined);
