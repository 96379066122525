import { observer } from 'mobx-react-lite';
import { DocumentStore } from '../stores/document-store';
import { getCoverAppearance } from '../utils/get-cover-appearance';
import { DocumentCover, DocumentCoverSize } from './document-cover';
import { OriginalDocumentCover } from './original-document-cover';

interface AutomaticDocumentCover {
  readonly documentStore: DocumentStore;
  readonly size: DocumentCoverSize;
}

export const AutomaticDocumentCover = observer(function AutomaticDocumentCover({
  documentStore,
  size,
}: AutomaticDocumentCover) {
  const coverPage = documentStore.coverPage;

  if (coverPage && coverPage.useOriginalCover) {
    return (
      <OriginalDocumentCover
        label={documentStore.title ?? ''}
        src={coverPage.coverPageUrl}
        size={size}
      />
    );
  } else {
    return (
      <DocumentCover
        label={documentStore.title ?? ''}
        size={size}
        appearance={
          documentStore.documentType
            ? getCoverAppearance(documentStore.documentType)
            : undefined
        }
      />
    );
  }
});
