import {
  ReportingModuleConfig,
  RootStore,
  ModulesStore as iModulesStore,
} from '@yarmill/types';
import { computed, makeObservable } from 'mobx';
import { Announcement } from '../../announcement/types';

export class ModulesStore implements iModulesStore {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @computed
  get plan(): boolean {
    return this.rootStore.configStore.modules.get('plan');
  }
  @computed
  get reality(): boolean {
    return this.rootStore.configStore.modules.get('reality');
  }
  @computed
  get annualPlan(): boolean {
    return this.rootStore.configStore.modules.get('annualPlan');
  }
  @computed
  get analytics(): boolean {
    return this.rootStore.configStore.modules.get('analytics');
  }
  @computed
  get attendance(): boolean {
    return this.rootStore.configStore.modules.get('attendance');
  }
  @computed
  get externalServices(): boolean {
    return this.rootStore.configStore.modules.get('externalServices');
  }
  @computed
  get video(): boolean {
    return this.rootStore.configStore.modules.get('video');
  }
  @computed
  get userEvidence(): boolean {
    return this.rootStore.configStore.modules.get('userEvidence');
  }
  @computed
  get settings(): boolean {
    return this.rootStore.configStore.modules.get('settings');
  }
  @computed
  get announcement(): Announcement[] {
    return this.rootStore.configStore.modules.get('announcement') || [];
  }
  @computed
  get filesOverview(): boolean {
    return this.rootStore.configStore.modules.get('filesOverview');
  }
  @computed
  get seasonEvaluation(): boolean {
    return this.rootStore.configStore.modules.get('seasonEvaluation');
  }

  @computed
  get reporting(): ReportingModuleConfig {
    return this.rootStore.configStore.modules.get('reporting');
  }

  @computed
  get evidence(): boolean {
    return this.rootStore.configStore.modules.get('evidence');
  }

  @computed
  get okr(): boolean {
    return this.rootStore.configStore.modules.get('okr');
  }

  @computed
  get yollanda(): boolean {
    return this.rootStore.configStore.modules.get('yollanda');
  }

  @computed
  get planner(): boolean {
    return this.rootStore.configStore.modules.get('planner');
  }

  @computed
  get metodej(): boolean {
    return this.rootStore.configStore.modules.get('metodej');
  }
}
