import { Button, ButtonAppearance } from '@yarmill/component-library';
import { useHistory } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { MutableRefObject } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { MetodejFormik } from '../types';
import { getMetodejUrl } from '../utils/get-metodej-url';

interface SaveDetailFormButtonProps {
  readonly formik: MutableRefObject<MetodejFormik | undefined>;
}

export const SaveDetailFormButton = observer(function SaveDetailFormButton({
  formik,
}: SaveDetailFormButtonProps) {
  const intl = useIntl();
  const label = 'metodej.library.detail.form.save';
  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ readonly id: string }>();

  async function handleSave() {
    await formik.current?.submitForm();
    if (formik.current?.isValid) {
      history.push(`${getMetodejUrl('library/all')}/${id}`);
    }
  }

  return (
    <Button
      type="button"
      appearance={ButtonAppearance.Primary}
      onClick={handleSave}
    >
      {intl.formatMessage({ id: label })}
    </Button>
  );
});
