import { DrawerSection } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { DocumentEmptyUploadListItem } from '../components';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { getFileKey } from '../utils/get-file-key';
import { UploadingDocument } from './uploading-document';

export const UploadingDocumentsList = observer(
  function UploadingDocumentsList() {
    const intl = useIntl();
    const uploadStore = useDocumentsUploadStore();

    return (
      <DrawerSection
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.uploadingDocumentsHeadline',
        })}
      >
        {uploadStore.uploadingDocuments.length > 0 ? (
          uploadStore.uploadingDocuments.map(document => (
            <UploadingDocument
              key={
                document.document?.documentId ??
                getFileKey(document.fileUpload.originalFile)
              }
              documentUploadStore={document}
            />
          ))
        ) : (
          <DocumentEmptyUploadListItem
            label={intl.formatMessage({
              id: 'metodej.upload.drawer.emptyUploadList',
            })}
            type="upload"
          />
        )}
      </DrawerSection>
    );
  }
);
