import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Search } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { FilterDocumentsListPopover } from '../popovers/filter-documents-list-popover';

export const FilterDocumentsListButton = observer(
  function FilterDocumentsListButton() {
    const intl = useIntl();
    const label = 'metodej.library.list.search';
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      function openPopover(e: KeyboardEvent) {
        if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'f') {
          e.preventDefault();
          e.stopPropagation();
          buttonRef.current?.click();
        }
      }

      document.addEventListener('keydown', openPopover);

      return () => {
        document.removeEventListener('keydown', openPopover);
      };
    }, []);

    return (
      <PopoverProvider
        content={closePopover => (
          <FilterDocumentsListPopover closePopover={closePopover} />
        )}
      >
        <Tippy tooltipContent={label} noWrapper>
          <Button
            ref={buttonRef}
            as="div"
            appearance={ButtonAppearance.Tertiary}
            icon={<Search />}
            aria-label={intl.formatMessage({
              id: label,
            })}
          />
        </Tippy>
      </PopoverProvider>
    );
  }
);
