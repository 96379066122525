import { useIntl } from 'react-intl';
import { FormBreadcrumbItem, FormBreadcrumbs } from '../components';
interface BreadcrumbsProps {
  readonly activeItem: number;
}
export function Breadcrumbs({ activeItem }: BreadcrumbsProps) {
  const intl = useIntl();

  return (
    <FormBreadcrumbs activeItem={activeItem}>
      <FormBreadcrumbItem
        label={intl.formatMessage({
          id: 'metodej.upload.form.breadcrumbs.chooseFiles',
        })}
        stepNumber={1}
        isActive={activeItem === 1}
        isDone={activeItem > 1}
      />
      <FormBreadcrumbItem
        label={intl.formatMessage({
          id: 'metodej.upload.form.breadcrumbs.setRights',
        })}
        stepNumber={2}
        isActive={activeItem === 2}
        isDone={activeItem > 2}
      />
      <FormBreadcrumbItem
        label={intl.formatMessage({
          id: 'metodej.upload.form.breadcrumbs.checkFiles',
        })}
        stepNumber={3}
        isActive={activeItem === 3}
        isDone={activeItem > 3}
      />
    </FormBreadcrumbs>
  );
}
