import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import {
  DocumentCoverSize,
  DocumentUploadListItem,
  StateTag,
  StateTagAppearance,
} from '../components';
import { AutomaticDocumentCover } from '../components/automatic-document-cover';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';

interface DocumentToCheckProps {
  readonly documentStore: DocumentStore;
}

function getTag(
  documentStore: DocumentStore,
  intl: IntlShape,
  isActive: boolean
): ReactElement | undefined {
  if (documentStore.isAnnotated && documentStore.isEdited) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.concept',
        })}
        appearance={StateTagAppearance.concept}
        isActive={isActive}
      />
    );
  } else if (
    documentStore.isAnnotated &&
    !documentStore.isEdited &&
    documentStore.isRead
  ) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.read',
        })}
        appearance={StateTagAppearance.read}
        isActive={isActive}
      />
    );
  } else if (
    documentStore.isAnnotated &&
    !documentStore.isEdited &&
    !documentStore.isRead
  ) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.ready',
        })}
        appearance={StateTagAppearance.ready}
        isActive={isActive}
      />
    );
  }
  return undefined;
}

export const DocumentToCheck = observer(function DocumentToCheck({
  documentStore,
}: DocumentToCheckProps) {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const isActive = uploadStore.focusedItem === documentStore;

  function onClick() {
    uploadStore.setFocusedItem(documentStore);
  }

  const label =
    documentStore.title ??
    intl.formatMessage({ id: 'metodej.upload.drawer.document.emptyTitle' });

  return (
    <DocumentUploadListItem
      label={label}
      onClick={onClick}
      isActive={isActive}
      tag={getTag(documentStore, intl, isActive)}
      cover={
        <AutomaticDocumentCover
          documentStore={documentStore}
          size={DocumentCoverSize.xsmall}
        />
      }
    />
  );
});
