import { Canvas, CanvasLayout, PanelToolbar } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { FormBackButton } from '../buttons/form-back-button';
import { FormGuideButton } from '../buttons/form-guide-button';
import { PublishButton } from '../buttons/publish-button';
import { RemoveUnpublishedDocumentButton } from '../buttons/remove-unpublished-document-button';
import { UploadCanvasLayout } from '../components';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';
import { UploadCanvasTitle } from './upload-canvas-title';
import { UploadDetail } from './upload-detail';
import { UploadFormGuide } from './upload-form-guide';
import { UploadingDocuments } from './uploading-documents';

export const UploadCanvas = observer(function UploadCanvas() {
  const uploadStore = useDocumentsUploadStore();
  const showUploadDetail = Boolean(uploadStore.focusedItem);
  const currentFormStep = uploadStore.currentFormStep;
  const showUploadGuide =
    !showUploadDetail && uploadStore.uploadingDocuments.length === 0;
  const showUploadingFiles =
    !showUploadDetail && uploadStore.uploadingDocuments.length !== 0;

  const showPublishButton =
    uploadStore.focusedItem instanceof DocumentStore &&
    uploadStore.documentsToCheck.has(uploadStore.focusedItem.documentId);
  const showRemoveButton = showPublishButton;
  const showFormBackButton = currentFormStep === 2;

  return (
    <CanvasLayout>
      <Canvas
        header={
          <PanelToolbar
            left={
              <>
                {showFormBackButton && <FormBackButton />}
                {showRemoveButton && <RemoveUnpublishedDocumentButton />}
              </>
            }
            center={<UploadCanvasTitle />}
            right={
              <>
                {showUploadGuide && <FormGuideButton />}
                {showPublishButton && <PublishButton />}
              </>
            }
          />
        }
        footer={<PanelToolbar position="footer" />}
      >
        <UploadCanvasLayout>
          {showUploadGuide && <UploadFormGuide />}
          {showUploadingFiles && <UploadingDocuments />}
          {showUploadDetail && <UploadDetail />}
        </UploadCanvasLayout>
      </Canvas>
    </CanvasLayout>
  );
});
