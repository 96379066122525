import { TagInput } from '@yarmill/component-library';
import { useLogger } from '@yarmill/utils';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormContext } from '../context/document-form-context';
import { MetodejFormValues, MetodejFormik, TagFormField } from '../types';
import { getTagsForField } from '../utils/get-tags-for-field';
import { handleAutoSaveFormValues } from '../utils/handle-auto-save-form-values';

interface TagFieldProps {
  readonly name: TagFormField;
  readonly noLabel?: boolean;
}

const emptyTags: string[] = [];
export const TagField = observer(function TagField({
  name,
  noLabel,
}: TagFieldProps) {
  const intl = useIntl();
  const formik = useFormikContext<MetodejFormValues>();
  const selectedTags = formik.values[name] ?? emptyTags;
  const options = useMemo(() => getTagsForField(name, intl), [name, intl]);
  const documentForm = useContext(DocumentFormContext);
  const autoSave = documentForm?.autoSave;
  const documentStore = documentForm?.documentStore;
  const logger = useLogger();

  const selectTag = useCallback(
    (tag: string) => {
      if (selectedTags.includes(tag)) {
        const updatedTags = [...selectedTags];
        updatedTags.splice(updatedTags.indexOf(tag), 1);
        formik.setFieldValue(name, updatedTags);
      } else {
        formik.setFieldValue(name, [...selectedTags, tag]);
      }
    },
    [formik, selectedTags, name]
  );

  const removeTag = useCallback(
    (tag: string) => {
      const idx = selectedTags.indexOf(tag);
      if (idx !== -1) {
        const updatedTags = [...selectedTags];
        updatedTags.splice(idx, 1);
        formik.setFieldValue(name, updatedTags);
      }
    },
    [formik, selectedTags, name]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (autoSave && documentStore) {
      handleAutoSaveFormValues(
        formik as MetodejFormik,
        documentStore,
        intl,
        logger
      );
    }
  }, [selectedTags, selectedTags.length, logger, autoSave, documentStore]);

  return (
    <TagInput
      label={
        noLabel
          ? undefined
          : intl.formatMessage({
              id: `metodej.document.form.field.${name}.label`,
            })
      }
      options={options}
      value={selectedTags}
      selectTag={selectTag}
      id={name}
      addButtonAriaLabel={intl.formatMessage({
        id: `metodej.document.form.field.${name}.addButton.ariaLabel`,
      })}
      removeTag={removeTag}
      removeTagButtonAriaLabel={intl.formatMessage({
        id: `metodej.document.form.field.${name}.removeTag.ariaLabel`,
      })}
      searchInputPlaceholder={intl.formatMessage({
        id: `metodej.document.form.field.${name}.dropdown.searchInputPlaceholder`,
      })}
    />
  );
});
