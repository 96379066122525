import { css, styled } from '../theme-provider';

export const FullscreenLayer = styled.div`
  transition-timing-function: ease-out;
  ${({ theme }) => css`
    width: calc(100vw - 2 * ${theme.size.x1});
    height: calc(100vh - 2 * ${theme.size.x1});
    margin: ${theme.size.x1};
  `}
`;
