import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Question } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { FormGuidePopover } from '../popovers/form-guide-popover';

export const FormGuideButton = observer(function FormGuideButton() {
  const intl = useIntl();
  const label = 'metodej.upload.form.showFormHelp';

  return (
    <PopoverProvider
      content={closePopover => <FormGuidePopover closePopover={closePopover} />}
    >
      <Tippy tooltipContent={label} noWrapper>
        <Button
          as="div"
          appearance={ButtonAppearance.Tertiary}
          icon={<Question />}
          aria-label={intl.formatMessage({
            id: label,
          })}
        />
      </Tippy>
    </PopoverProvider>
  );
});
