import { css, styled } from '@yarmill/component-library';

const FORM_MAX_WIDTH = 568;

export const UploadCanvasLayout = styled.div`
  margin: 0 auto;

  ${({ theme }) => css`
    max-width: calc(${FORM_MAX_WIDTH}px + 2 * ${theme.size.x35});
    padding: ${theme.size.x55} ${theme.size.x35};
  `};
`;
export const BreadcrumbsWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.size.x6};
  `};
`;
