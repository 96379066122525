import {
  AsyncStatus,
  RequestStore,
  RootStore,
  File as YtdFile,
  FileUploadStore as iFileUploadStore,
} from '@yarmill/types';
import { AxiosProgressEvent, CancelTokenSource } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { uploadFile } from '../api/upload-file';

export class FileUploadStore implements iFileUploadStore {
  private readonly file: File;
  private readonly _rootStore: RootStore;
  private readonly _scopeApplicationId: string | undefined;

  @observable
  private _uploadedFile: YtdFile | null = null;

  @observable
  private readonly request: RequestStore<YtdFile>;
  @observable
  private uploadProgress: number = 0;

  private _toastId: number | string | null = null;

  @observable
  private _isCanceled: boolean = false;

  constructor(rootStore: RootStore, file: File, scopeAppId?: string) {
    makeObservable(this);
    this.file = file;
    this._rootStore = rootStore;
    this._scopeApplicationId = scopeAppId;
    this.request = this.upload();
  }

  private upload() {
    const fileUploadUrl = this.scopedFileUploadUrl;
    const formData = new FormData();
    formData.append('file', this.file);

    return this._rootStore.requestsStore.createRequest(
      (cancelToken: CancelTokenSource) =>
        uploadFile(
          fileUploadUrl,
          formData,
          cancelToken,
          this.setUploadProgress,
          this._scopeApplicationId
        )
    );
  }

  @computed
  private get scopedFileUploadUrl(): string {
    const defaultUrl = this._rootStore.configStore.fileUploadUrl;
    const scope = this._scopeApplicationId;

    const scopes = this._rootStore.configStore.fileStorageScopes;
    const applicationScopeConfig = scopes.find(s => s.scope === 'application');
    const defaultScopeConfig = scopes.find(s => s.scope === 'yarmill');

    return scope
      ? (applicationScopeConfig?.uploadUrl ??
          defaultScopeConfig?.uploadUrl ??
          defaultUrl)
      : (defaultScopeConfig?.uploadUrl ?? defaultUrl);
  }

  get progress(): number {
    return this.uploadProgress;
  }

  @computed
  get name(): string {
    return this.uploadedFile?.FileName || this.file.name;
  }

  @computed
  get size(): number {
    return this.file.size;
  }

  @computed
  get status(): AsyncStatus {
    if (this.request.status === AsyncStatus.resolved) {
      return this.uploadedFile ? AsyncStatus.resolved : AsyncStatus.pending;
    }
    return this.request.status;
  }

  get uploadedFile(): YtdFile | null {
    return this._uploadedFile;
  }

  cancelUpload(): void {
    this.request.cancel();
    this._isCanceled = true;
  }

  get isCanceled(): boolean {
    return this._isCanceled;
  }

  get toastId(): number | string | null {
    return this._toastId;
  }

  setToastId(toastId: number | string | null): void {
    this._toastId = toastId;
  }

  get originalFile() {
    return this.file;
  }

  @action
  async uploadFile(): Promise<void> {
    const response = await this.request.getResponse();

    if (response) {
      this._uploadedFile = response;
    }
  }

  @action
  async retryUpload(): Promise<void> {
    this.upload();
    await this.uploadFile();
  }

  @action
  private readonly setUploadProgress = (e: AxiosProgressEvent) => {
    this.uploadProgress = e && e.total ? e.loaded / e.total : 0;
  };
}
