import {
  Canvas,
  CanvasLayout,
  PanelTitle,
  PanelToolbar,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { FilterDocumentsListButton } from '../buttons/filter-documents-list-button';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { AllDocumentsList } from './all-documents-list';

export const Overview = observer(function Overview() {
  const intl = useIntl();
  return (
    <CanvasLayout>
      <Canvas
        header={
          <PanelToolbar
            left={<ToggleSideMenu />}
            center={
              <PanelTitle
                text={intl.formatMessage({
                  id: 'metodej.library.canvas.title.overview',
                })}
              />
            }
            right={<FilterDocumentsListButton />}
          />
        }
        footer={<PanelToolbar position="footer" />}
      >
        <AllDocumentsList />
      </Canvas>
    </CanvasLayout>
  );
});
