import { css, styled } from '@yarmill/component-library';

const MAX_WIDTH = 586;

export const LibraryCanvasContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: calc(${MAX_WIDTH}px + 2 * ${theme.size.x35});
    padding: ${theme.size.x35}
  `}
`;
