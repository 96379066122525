import { FormFieldLayoutDefinitionMap } from '../types';

export const formFieldLayoutDefinitions: FormFieldLayoutDefinitionMap = {
  authors: 2,
  isbn: 1,
  publisher: 2,
  edition: 1,
  publicationPlace: 1,
  publicationYear: 1,
  languageCode: 1,
  medium: 2,
  issn: 1,
  hostEntity: 2,
  thesisType: 1,
  thesisInstitution: 2,
  thesisSupervisor: 2,
  url: 2,
  doi: 1,
};
