import { Check, Minus } from '@yarmill/icon-library';
import { AriaAttributes, SyntheticEvent, forwardRef, useCallback } from 'react';
import { css, styled } from './theme-provider';

export type HandleCheckboxEvent = (
  e: SyntheticEvent<HTMLInputElement>,
  props: CheckboxProps
) => void;

type CheckboxSize = 2 | 3;
export interface CheckboxProps
  extends Omit<AriaAttributes, 'aria-hidden' | 'aria-live' | 'aria-relevant'> {
  readonly checked?: boolean;
  readonly defaultChecked?: boolean;
  readonly disabled?: boolean;
  readonly name?: string;
  readonly id: string;
  readonly onBlur?: HandleCheckboxEvent;
  readonly onChange?: HandleCheckboxEvent;
  readonly onClick?: HandleCheckboxEvent;
  readonly onFocus?: HandleCheckboxEvent;
  readonly required?: boolean;
  readonly tabIndex?: -1 | 0;
  readonly value?: string;
  readonly 'aria-labelledby': string;
  readonly useMinusIcon?: boolean;
  readonly size?: CheckboxSize;
}
const noop = () => undefined;

const CheckboxContainer = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const CheckboxWrapper = styled.span<{
  readonly disabled?: boolean;
  readonly $size: CheckboxSize;
}>`
	position: relative;
	display: flex;
  justify-content: center;
	align-items: center;
  border-style: solid;
  transition: background 250ms ease;

  ${({ theme, disabled, $size }) => css`
    width: ${$size === 2 ? theme.size.x2 : theme.size.x3};
    height: ${$size === 2 ? theme.size.x2 : theme.size.x3};
    border-radius: ${$size === 2 ? theme.size.x05 : theme.size.x075};
    border-width: ${theme.size.x025};
    border-color: ${theme.dark ? theme.color.neutral_neutral : theme.color.brand_yarmill};
    background-color: ${theme.dark ? theme.color.neutral_black : theme.color.neutral_white};

    &:hover {
        ${
          !disabled &&
          css`
            color: ${theme.dark ? theme.color.neutral_neutral_64 : theme.color.brand_yarmill_35};
            background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_16};
            svg {
              opacity: 1;
              transition: opacity 250ms ease;
            }
        `
        };
    }

    svg {
      opacity: 0;
      width: ${$size === 2 ? theme.size.x2 : theme.size.x3};
      height: ${$size === 2 ? theme.size.x2 : theme.size.x3};
    }
  `};
`;

const StyledInput = styled.input<{
  readonly disabled?: boolean;
}>`
	position: absolute;
	opacity: 0;
	outline: 0;
	user-select: none;
	pointer-events: none;

  ${({ theme, disabled }) => css`
    &:focus-visible {
      + ${CheckboxWrapper} {
        color: ${theme.dark ? theme.color.neutral_neutral_64 : theme.color.brand_yarmill_35};
        background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.brand_yarmill_16};
        svg {
          opacity: 1;
        }
      }
    }
    &:checked {
      + ${CheckboxWrapper} {
        background-color: ${theme.dark ? theme.color.neutral_neutral : theme.color.brand_yarmill};
        color: ${theme.dark ? theme.color.neutral_neutral_2 : theme.color.brand_yarmill_6};

        svg {
          opacity: 1;
        }

        &:hover {
          ${
            !disabled &&
            css`
              background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.brand_yarmill_dark};
            `
          }
        }
      }

      &:focus-visible {
        + ${CheckboxWrapper} {
          background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.brand_yarmill_dark};
        }
      }
    }
  `};

	:disabled {
		+ ${CheckboxWrapper} {
			cursor: not-allowed;
			opacity: 0.75;
		}
	}
`;
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const {
      checked,
      defaultChecked,
      disabled,
      name,
      value,
      required,
      tabIndex,
      useMinusIcon,
      id,
      'aria-labelledby': ariaLabelledBy,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onClick = noop,
      size = 2,
    } = props;

    const handleChange = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onChange(e, props);
      },
      [onChange, props]
    );
    const handleBlur = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onBlur(e, props);
      },
      [onBlur, props]
    );
    const handleFocus = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onFocus(e, props);
      },
      [onFocus, props]
    );
    const handleClick = useCallback(
      (e: SyntheticEvent<HTMLInputElement>): void => {
        onClick(e, props);
      },
      [onClick, props]
    );

    return (
      <CheckboxContainer>
        <StyledInput
          aria-labelledby={ariaLabelledBy}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={handleClick}
          onFocus={handleFocus}
          ref={ref}
          required={required}
          tabIndex={tabIndex}
          type="checkbox"
          value={value}
        />
        <CheckboxWrapper aria-hidden disabled={disabled} $size={size}>
          {useMinusIcon ? <Minus /> : <Check />}
        </CheckboxWrapper>
      </CheckboxContainer>
    );
  }
);
