import { observer } from 'mobx-react-lite';
import { FormFieldWrapper } from '../components/form-field-wrapper';
import { MetodejDocument } from '../types';
import { AuthorsField } from './authors-field';
import { formFieldLayoutDefinitions } from './form-field-layout-definitions';
import { SelectField } from './select-field';
import { TextField } from './text-field';

interface FormFieldsProps {
  readonly visibleFields: (keyof MetodejDocument)[];
}
export const FormFields = observer(function FormFields({
  visibleFields,
}: FormFieldsProps) {
  return (
    <>
      {visibleFields.map(field => {
        switch (field) {
          case 'authors':
            return <AuthorsField key={field} />;
          case 'languageCode':
          case 'thesisType':
            return <SelectField name={field} key={field} />;
          default:
            return (
              <FormFieldWrapper
                $span={formFieldLayoutDefinitions[field] ?? 2}
                key={field}
              >
                <TextField name={field} key={field} />
              </FormFieldWrapper>
            );
        }
      })}
    </>
  );
});
