import { Tippy } from '@yarmill/components';
import {
  Category,
  DropdownButtonWrapper,
  DropdownOption,
  DropdownProvider,
} from '@yarmill/components-2';
import { Folder } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { CategoryDropdownIconWrapper } from '../components/detail/okr-form';
import { OkrCategory, getAppearanceForCategory } from '../okr-category';
import { useOkrsStore } from '../okrs-store-context';
import { OkrRootCategory, OkrCategory as iOkrCategory } from '../types';

function createCategoryOption(
  intl: IntlShape,
  category: iOkrCategory,
  isSubcategory = false
): DropdownOption<iOkrCategory> {
  return {
    label: intl.formatMessage({ id: `okrs.category.${category}` }),
    icon: isSubcategory ? (
      <CategoryDropdownIconWrapper>
        <Folder />
      </CategoryDropdownIconWrapper>
    ) : undefined,
    value: category,
    children: [],
  };
}

const createOkrCategoryOptions = (
  categories: string[],
  intl: IntlShape
): DropdownOption<iOkrCategory>[] =>
  categories
    .map(c => c.split('.'))
    .reduce<DropdownOption<iOkrCategory>[]>((options, category) => {
      const rootCategory = category[0] as OkrRootCategory;
      if (category.length === 1) {
        options.push(createCategoryOption(intl, rootCategory));
      } else if (category.length === 2) {
        const parentCategoryIndex = options.findIndex(
          o => o.value === rootCategory
        );

        if (parentCategoryIndex !== -1) {
          options[parentCategoryIndex]?.children?.push(
            createCategoryOption(intl, category.join('.') as iOkrCategory, true)
          );
        }
      }

      return options;
    }, []);

interface CategoryDropdownProps {
  readonly selectedValue: iOkrCategory | null;
  readonly handleSelect: (value: iOkrCategory) => void;
  readonly disabled?: boolean;
}
export const CategoryDropdown = observer(function CategoryDropdown({
  selectedValue,
  handleSelect,
  disabled,
}: CategoryDropdownProps): JSX.Element {
  const intl = useIntl();
  const okrsStore = useOkrsStore();
  const categoriesDefinition = okrsStore.categories;
  const categories = useMemo(
    () => createOkrCategoryOptions(categoriesDefinition, intl),
    [categoriesDefinition, intl]
  );

  return (
    <Tippy tooltipContent="okrs.category" noWrapper touch={false}>
      <DropdownProvider
        keyboardShortcut="C"
        options={categories}
        selectedValue={selectedValue ?? undefined}
        searchInputPlaceholder={intl.formatMessage({
          id: 'okrs.form.category.label',
        })}
        handleSelect={handleSelect as any}
        disabled={disabled}
      >
        {selectedValue ? (
          <DropdownButtonWrapper
            appearance={getAppearanceForCategory(selectedValue)}
            disabled={disabled}
          >
            <OkrCategory category={selectedValue} />
          </DropdownButtonWrapper>
        ) : (
          <DropdownButtonWrapper appearance="sand" disabled={disabled}>
            <Category
              category={intl.formatMessage({
                id: 'okrs.form.category.selectCategory',
              })}
              appearance="sand"
            />
          </DropdownButtonWrapper>
        )}
      </DropdownProvider>
    </Tippy>
  );
});
