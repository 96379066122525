import { formLayoutForDocumentFormat } from '../form/form-layout-for-document-type';
import {
  DOCUMENT_TYPE_CITE_FORM_FIELDS,
  DocumentFormat,
  DocumentType,
  MetodejDocument,
} from '../types';

export function getDefaultFormLayout(
  format: DocumentFormat,
  type: DocumentType,
  values: MetodejDocument
): (keyof MetodejDocument)[] {
  const baseFields = formLayoutForDocumentFormat[format][type];
  const additionalFields = DOCUMENT_TYPE_CITE_FORM_FIELDS.filter(key =>
    Boolean(!baseFields.includes(key) && values[key])
  );

  return [...baseFields, ...additionalFields];
}
