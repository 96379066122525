import { ParentSize } from '@visx/responsive';
import { VariableSizeList } from 'react-window';
import {
  DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT,
  DocumentCoverSize,
  DocumentLibraryListItem,
} from '../components';
import { AutomaticDocumentCover } from '../components/automatic-document-cover';
import {
  DocumentLibraryList,
  DocumentLibraryListItemWrapper,
} from '../components/document-library-list';
import { DocumentStore } from '../stores/document-store';
import { getFormattedAuthorNames } from '../utils/get-formatted-author-names';
import { getMetodejUrl } from '../utils/get-metodej-url';

interface DocumentListProps {
  readonly documents: DocumentStore[];
}

const PADDING = 8;

export function DocumentList({ documents }: DocumentListProps) {
  return (
    <DocumentLibraryList>
      <ParentSize>
        {({ width, height }) => (
          <VariableSizeList
            width={width + PADDING}
            height={height}
            itemCount={documents.length}
            estimatedItemSize={DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT + PADDING}
            itemSize={idx =>
              idx === documents.length - 1
                ? DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT + PADDING * 2
                : DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT + PADDING
            }
            itemKey={idx => documents[idx].documentId}
          >
            {({ index, style }) => {
              const documentStore = documents[index];
              return (
                <DocumentLibraryListItemWrapper
                  style={style}
                  key={documentStore.documentId}
                >
                  <DocumentLibraryListItem
                    cover={
                      <AutomaticDocumentCover
                        size={DocumentCoverSize.xsmall}
                        documentStore={documentStore}
                      />
                    }
                    author={getFormattedAuthorNames(documentStore.authors)}
                    title={documentStore.title ?? ''}
                    description={documentStore.subTitle ?? ''}
                    to={`${getMetodejUrl('library/all')}/${documentStore.documentId}`}
                  />
                </DocumentLibraryListItemWrapper>
              );
            }}
          </VariableSizeList>
        )}
      </ParentSize>
    </DocumentLibraryList>
  );
}
