import { Text, css, styled } from '@yarmill/component-library';

export const DocumentDetailCiteLayout = styled.cite`
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  font-style: normal;
  ${({ theme }) => css`
    row-gap: ${theme.size.x2};
    column-gap: ${theme.size.x4};
  `};
`;

export const DocumentDetailCiteAttributeHeadline = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
  `};
`;
export const DocumentDetailCiteAttributeText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_darker};
  `};`;
