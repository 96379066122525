import { SelectBoxInputOption } from '@yarmill/component-library/dist/select-box-input';
import { IntlShape } from 'react-intl';
import { LANGUAGE_CODES } from '../form/language-codes';
import {
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  DocumentFormat,
  MetodejDocument,
  THESIS_TYPES,
} from '../types';
import { translateCiteAttribute } from './translate-cite-attribute';

export function getOptionsForField(
  field: keyof MetodejDocument,
  intl: IntlShape,
  documentFormat: DocumentFormat | null
): SelectBoxInputOption<string>[] {
  if (field === 'documentFormat') {
    return DOCUMENT_FORMATS.map(format => ({
      value: format,
      label: intl.formatMessage({
        id: `metodej.document.form.field.documentFormat.${format}`,
      }),
    }));
  } else if (field === 'documentType') {
    return DOCUMENT_TYPES.map(type => ({
      value: type,
      label: intl.formatMessage({
        id: `metodej.document.form.field.documentType.${type}`,
      }),
    })).filter(({ value }) =>
      documentFormat === 'scan' ? value !== 'webPost' : true
    );
  } else if (field === 'languageCode') {
    return LANGUAGE_CODES.map(languageCode => ({
      value: languageCode,
      label: translateCiteAttribute(intl, field, languageCode),
    }));
  } else if (field === 'thesisType') {
    return THESIS_TYPES.map(thesisType => ({
      value: thesisType,
      label: translateCiteAttribute(intl, field, thesisType),
    }));
  }

  return [];
}
