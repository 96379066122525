import { Text, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface DocumentLibraryListItemProps {
  readonly cover: ReactElement;
  readonly author: string;
  readonly title: string;
  readonly description: string;
  readonly to: string;
}

export const DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT = 98;
const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  text-decoration: none;
  ${({ theme }) => css`
    height: ${DOCUMENT_LIBRARY_LIST_ITEM_HEIGHT}px;
    border-radius: ${theme.borderRadius.x15};
    background-color: ${theme.color.background_background_02};
    padding: ${theme.size.x2};
    column-gap: ${theme.size.x25};

    &:hover {
      background-color: ${theme.color.background_background_03};
    }
  `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_dark};
  `};
`;

const Author = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
    margin-top: ${theme.size.x05};
  `};
`;

const Description = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
  `};
`;

export function DocumentLibraryListItem({
  to,
  cover,
  author,
  title,
  description,
}: DocumentLibraryListItemProps) {
  return (
    <Layout as={Link} to={to}>
      {cover}
      <ContentContainer>
        <Text appearance="_12B" lineClamp={1} as="h2">
          {title}
        </Text>
        <Description appearance="_12M" lineClamp={2} as="p">
          {description}
        </Description>
        <Author appearance="_12M" lineClamp={1} as="div">
          {author}
        </Author>
      </ContentContainer>
    </Layout>
  );
}
