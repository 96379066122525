import { useLocation } from '@yarmill/utils';
import { useMemo } from 'react';
import { getMetodejUrl } from '../utils/get-metodej-url';

const drawerPages = [getMetodejUrl('upload')];

export function usePageHasDrawer() {
  const { pathname } = useLocation();

  return useMemo(
    () => Boolean(drawerPages.find(page => page.startsWith(pathname))),
    [pathname]
  );
}
