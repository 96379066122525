import {
  Canvas,
  CanvasLayout,
  PanelTitle,
  PanelToolbar,
} from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { LibraryCanvasContainer } from '../components/library-canvas-container';
import { AllDocumentsList } from './all-documents-list';

export const AllDocuments = observer(function AllDocuments() {
  const intl = useIntl();

  return (
    <CanvasLayout>
      <Canvas
        header={
          <PanelToolbar
            left={<ToggleSideMenu />}
            center={
              <PanelTitle
                text={intl.formatMessage({
                  id: 'metodej.library.canvas.title.allDocuments',
                })}
              />
            }
            right={<></>}
          />
        }
        footer={<PanelToolbar position="footer" />}
      >
        <LibraryCanvasContainer>
          <AllDocumentsList />
        </LibraryCanvasContainer>
      </Canvas>
    </CanvasLayout>
  );
});
