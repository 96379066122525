import { css, styled } from './theme-provider';

export const MiniButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: ${theme.size.x3};
    height: ${theme.size.x3};
    color: ${theme.color.neutral_neutral_64};
    border: none;
    cursor: pointer;
    background-color: transparent;

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }

    &:hover {
      color: ${theme.color.neutral_neutral};
    }

    &:focus-visible {
      outline: none;
      color: ${theme.color.brand_yarmill_56};
    }
  `};
`;
