import { useContext } from 'react';
import { MetodejStoreContext } from '../context/metodej-store-context';
import { MetodejStore } from '../stores/metodej-store';

export function useMetodejStore(): MetodejStore {
  const store = useContext(MetodejStoreContext);

  if (!store) {
    throw new Error('Metodej Store not available');
  }

  return store;
}
