import { Text, css, styled } from '@yarmill/component-library';
import { EmptyCiteForm } from './assets';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-column: 1 / -1;

  ${({ theme }) => css`
    row-gap: ${theme.size.x4};
    color: ${theme.color.neutral_neutral_64};
  `};
`;

interface DocumentFormEmptyCiteSectionProps {
  readonly text: string;
}
export function DocumentFormEmptyCiteSection({
  text,
}: DocumentFormEmptyCiteSectionProps) {
  return (
    <Layout>
      <Text appearance="_14M" as="p">
        {text}
      </Text>
      <EmptyCiteForm />
    </Layout>
  );
}
