import { Drawer, DrawerLayout, PanelToolbar } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { UploadFilesButton } from '../buttons/upload-files-button';
import { DocumentsToCheckList } from './documents-to-check-list';
import { UploadingDocumentsList } from './uploading-documents-list';

export const UploadDrawer = observer(function UploadDrawer() {
  return (
    <DrawerLayout>
      <Drawer
        header={
          <PanelToolbar
            left={
              <>
                <ToggleSideMenu />
                <UploadFilesButton />
              </>
            }
          />
        }
      >
        <DocumentsToCheckList />
        <UploadingDocumentsList />
      </Drawer>
    </DrawerLayout>
  );
});
