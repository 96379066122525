import { observer } from 'mobx-react-lite';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DocumentDetailCategory } from '../components/document-detail-category';
import {
  DocumentDetailCiteAttributeHeadline,
  DocumentDetailCiteAttributeText,
  DocumentDetailCiteLayout,
} from '../components/document-detail-cite-layout';
import { DocumentStore } from '../stores/document-store';
import { getDefaultFormLayout } from '../utils/get-default-form-layout';
import { translateCiteAttribute } from '../utils/translate-cite-attribute';

interface DocumentDetailCiteSectionProps {
  readonly documentStore: DocumentStore;
}

export const DocumentDetailCiteSection = observer(
  function DocumentDetailCiteSection({
    documentStore,
  }: DocumentDetailCiteSectionProps) {
    const intl = useIntl();
    const attributes = useMemo(
      () =>
        documentStore.documentFormat && documentStore.documentType
          ? getDefaultFormLayout(
              documentStore.documentFormat,
              documentStore.documentType,
              documentStore
            ).filter(
              attr =>
                attr !== 'authors' &&
                attr !== 'abstract' &&
                attr !== 'coverPage'
            )
          : [],
      [documentStore, documentStore.documentType]
    );

    return (
      <DocumentDetailCategory
        headline={intl.formatMessage({
          id: 'metodej.library.detail.cite.headline',
        })}
      >
        <DocumentDetailCiteLayout>
          {attributes
            .filter(attr => documentStore[attr])
            .map(attribute => (
              <Fragment key={attribute}>
                <DocumentDetailCiteAttributeHeadline appearance="_12M">
                  {intl.formatMessage(
                    {
                      id: `metodej.document.form.field.${attribute}.label`,
                    },
                    {
                      documentType: documentStore.documentType,
                    }
                  )}
                </DocumentDetailCiteAttributeHeadline>
                <DocumentDetailCiteAttributeText appearance="_12M">
                  {translateCiteAttribute(
                    intl,
                    attribute,
                    documentStore[attribute]
                  )}
                </DocumentDetailCiteAttributeText>
              </Fragment>
            ))}
        </DocumentDetailCiteLayout>
      </DocumentDetailCategory>
    );
  }
);
