import {
  Badge,
  BadgeAppearance,
  Text,
  ThemeDefinition,
  css,
  styled,
} from '@yarmill/component-library';
import {
  Check,
  PriorityLow,
  Reload,
  Rename,
  Upload,
} from '@yarmill/icon-library';

export enum StateTagAppearance {
  concept = 'concept',
  ready = 'ready',
  read = 'read',
  error = 'error',
  readyToUpload = 'readyToUpload',
  uploading = 'uploading',
  processing = 'processing',
}

interface StateTagProps {
  readonly label?: string;
  readonly appearance: StateTagAppearance;
  readonly isActive?: boolean;
}

function getIconForAppearance(appearance: StateTagAppearance) {
  switch (appearance) {
    case StateTagAppearance.concept:
      return <Rename />;
    case StateTagAppearance.error:
      return <PriorityLow />;
    case StateTagAppearance.readyToUpload:
      return <Check />;
    case StateTagAppearance.uploading:
      return <Upload />;
    case StateTagAppearance.processing:
      return <Reload />;
  }
}

function getBadgeAppearance(appearance: StateTagAppearance): BadgeAppearance {
  switch (appearance) {
    case StateTagAppearance.concept:
      return BadgeAppearance.recommendation;
    case StateTagAppearance.error:
      return BadgeAppearance.error;
    case StateTagAppearance.read:
      return BadgeAppearance.read;
    case StateTagAppearance.ready:
      return BadgeAppearance.ready;
    default:
      return BadgeAppearance.hint;
  }
}

function getBackgroundColor(theme: ThemeDefinition, isActive: boolean) {
  if (theme.dark) {
    return isActive
      ? theme.color.brand_yarmill_20
      : theme.color.background_background_04;
  } else {
    return isActive
      ? theme.color.brand_yarmill_20
      : theme.color.background_background_03;
  }
}

const Layout = styled.div<{ readonly $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme, $isActive }) => css`
    column-gap: ${theme.size.x075};
    border-radius: ${theme.borderRadius.x1};
    background-color: ${getBackgroundColor(theme, $isActive)};
    color: ${$isActive ? theme.color.brand_yarmill : theme.color.neutral_neutral};
    padding: ${theme.size.x025} ${theme.size.x075} ${theme.size.x025} ${theme.size.x05};

    ${
      !$isActive &&
      css`
        &:hover, .document-upload-list-item:hover & {
          background-color: ${theme.dark ? theme.color.background_background_05 : theme.color.background_background_03};
        }
      `
    };
  `}
`;

export function StateTag({
  label,
  appearance,
  isActive = false,
}: StateTagProps) {
  return (
    <Layout $isActive={isActive}>
      <Badge
        size={12}
        appearance={getBadgeAppearance(appearance)}
        icon={getIconForAppearance(appearance)}
        isActive={isActive}
      />
      <Text appearance="_10M">{label}</Text>
    </Layout>
  );
}
