export function EmptyCiteForm() {
  return (
    <svg
      width="376"
      height="80"
      viewBox="0 0 376 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8H368"
        stroke="#F8F8F8"
        strokeWidth="16"
        strokeLinecap="round"
      />
      <path
        d="M8 40H368"
        stroke="#F8F8F8"
        strokeWidth="16"
        strokeLinecap="round"
      />
      <path
        d="M8 72H208"
        stroke="#F8F8F8"
        strokeWidth="16"
        strokeLinecap="round"
      />
    </svg>
  );
}
