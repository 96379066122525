import { Link as UnStyledLink } from 'react-router-dom';
import styled from 'styled-components';
import { css } from './theme-provider';

const linkStyles = css`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-feature-settings: inherit;
  font-variant-numeric: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover, &:focus-visible {
    text-decoration: underline;
  }

  ${({ theme }) => css`
    color: ${theme.dark ? theme.color.other_blush : theme.color.brand_yarmill};
  `};
`;

export const Link = styled(UnStyledLink)`
    ${linkStyles};
`;

export const ExternalLink = styled.a`
  ${linkStyles};
`;
