import {
  Button,
  ButtonAppearance,
  Text,
  css,
  styled,
} from '@yarmill/component-library';
import { Plus } from '@yarmill/icon-library';
import {
  ChangeEventHandler,
  DragEvent,
  DragEventHandler,
  RefObject,
  useState,
} from 'react';
import { UploadFrameImage } from './assets';

interface MultiUploadFrameProps {
  readonly buttonText: string;
  readonly primaryText?: string;
  readonly secondaryText: string;
  readonly accept?: string;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly onChange?: ChangeEventHandler<HTMLInputElement>;
  readonly onDrop?: DragEventHandler<HTMLLabelElement>;
}

const FileInput = styled.input`
  display: none;
`;

interface LayoutProps {
  readonly $hasDragOver: boolean;
}
const Layout = styled.label.attrs<LayoutProps>(({ $hasDragOver, theme }) => ({
  style: $hasDragOver
    ? { backgroundColor: theme.color.brand_yarmill_6 }
    : undefined,
}))<LayoutProps>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  transition: background-color 300ms ease;
  cursor: pointer;

  ${({ theme }) => css`
    border: ${theme.size.x0125} dashed ${theme.color.brand_yarmill_56};
    border-radius: ${theme.size.x15};
    padding: ${theme.size.x5} ${theme.size.x2} ${theme.size.x3};
    row-gap: ${theme.size.x3};

    &:hover {
      background-color: ${theme.color.brand_yarmill_6};
    }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
`;
const PrimaryText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.size.x05};
    color: ${theme.color.neutral_neutral_darker};
  `}
`;
const SecondaryText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_64};
  `}
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.size.x1};
  `};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export function MultiUploadFrame({
  buttonText,
  primaryText,
  secondaryText,
  inputRef,
  onChange,
  accept,
  onDrop,
}: MultiUploadFrameProps) {
  const [hasDragOver, setHasDragOver] = useState(false);

  function onDragOver(e: DragEvent<HTMLLabelElement>) {
    e.preventDefault();
    setHasDragOver(true);
  }

  return (
    <Layout
      $hasDragOver={hasDragOver}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={() => setHasDragOver(false)}
    >
      <ImageWrapper>
        <UploadFrameImage />
      </ImageWrapper>
      <ContentWrapper>
        <ButtonWrapper>
          <FileInput
            type="file"
            multiple
            ref={inputRef}
            onChange={onChange}
            accept={accept}
          />
          <Button
            icon={<Plus />}
            as="div"
            appearance={ButtonAppearance.Secondary}
          >
            {buttonText}
          </Button>
        </ButtonWrapper>
        {primaryText && (
          <PrimaryText appearance="_14M">{primaryText}</PrimaryText>
        )}
        <SecondaryText appearance="_12M">{secondaryText}</SecondaryText>
      </ContentWrapper>
    </Layout>
  );
}
