import { Text, css, styled } from '@yarmill/component-library';
import { PropsWithChildren, ReactElement } from 'react';

type DocumentDetailCategoryProps = PropsWithChildren<{
  readonly headline: string | ReactElement;
  readonly noBorder?: boolean;
}>;

const Layout = styled.section`
  ${({ theme }) => css`
    & + & {
      border-top: ${theme.size.x0125} solid ${theme.color.background_background_04};
      padding-top: ${theme.size.x35};
      margin-top: ${theme.size.x35};
    }
  `};

  &.no-border {
    border-top: 0;
    padding-top: 0;
  }
`;

const Headline = styled(Text)`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    column-gap: ${theme.size.x15};
    color: ${theme.color.neutral_neutral_darker};
    margin-bottom: ${theme.size.x2};
  `};
`;

export function DocumentDetailCategory({
  headline,
  children,
  noBorder = false,
}: DocumentDetailCategoryProps) {
  return (
    <Layout className={`${noBorder ? 'no-border' : ''}`}>
      <Headline as="h2" appearance="_16B">
        {headline}
      </Headline>
      {children}
    </Layout>
  );
}
