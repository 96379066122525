import { SelectBox } from '@yarmill/component-library';
import { useLogger } from '@yarmill/utils';
import { useField, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormContext } from '../context/document-form-context';
import { MetodejDocument, MetodejFormValues, MetodejFormik } from '../types';
import { getOptionsForField } from '../utils/get-options-for-field';
import { handleAutoSaveFormValues } from '../utils/handle-auto-save-form-values';

interface SelectFieldProps {
  readonly name: keyof MetodejDocument;
  readonly required?: boolean;
}
export const SelectField = observer(function SelectField({
  name,
  required,
}: SelectFieldProps) {
  const intl = useIntl();
  const formik = useFormikContext<MetodejFormValues>();
  const value = formik.values[name];
  const { documentFormat } = formik.values;
  const documentForm = useContext(DocumentFormContext);
  const autoSave = documentForm?.autoSave;
  const documentStore = documentForm?.documentStore;
  const logger = useLogger();

  async function onChange(value: string) {
    await formik.setFieldValue(name, value);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (autoSave && documentStore) {
      handleAutoSaveFormValues(
        formik as MetodejFormik,
        documentStore,
        intl,
        logger
      );
    }
  }, [value, logger, autoSave, documentStore]);

  const [_, meta] = useField(name);

  const options = useMemo(
    () =>
      getOptionsForField(name, intl, documentFormat).sort((a, b) =>
        a.label.localeCompare(b.label, intl.locale)
      ),
    [name, intl, documentFormat]
  );

  return (
    <SelectBox
      label={intl.formatMessage({
        id: `metodej.document.form.field.${name}.label`,
      })}
      options={options}
      emptyValueLabel={intl.formatMessage({
        id: `metodej.document.form.field.select.emptyValue`,
      })}
      id={name}
      onChange={onChange}
      value={value as string}
      error={meta.error ? meta.error : undefined}
      searchable
      hint={
        required
          ? intl.formatMessage({ id: 'metodej.document.form.field.required' })
          : undefined
      }
    />
  );
});
