import { Button, ButtonAppearance, Text } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { DragEvent, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BreadcrumbsWrapper,
  DocumentFormUploadListItem,
  MultiUploadFrame,
} from '../components';
import {
  FileUploadList,
  FileUploadListHeadline,
  FileUploadListSecondaryText,
} from '../components/upload-form-step-1';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { getFileKey } from '../utils/get-file-key';
import { isValidFileExtension } from '../utils/is-valid-file-extension';
import { isValidUploadList } from '../utils/is-valid-upload-list';
import { validateFileTypes } from '../utils/validate-file-types';
import { Breadcrumbs } from './breadcrumbs';

const acceptedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const UploadFormStep1 = observer(function UploadFormStep0() {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadStore = useDocumentsUploadStore();
  const [filesToUpload, setFilesToUpload] = useState<File[]>(
    uploadStore.filesToUpload
  );
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);

  async function handleSetFiles(newFiles: File[]) {
    const { validFiles, invalidFiles } = await validateFileTypes(
      newFiles,
      acceptedFileTypes
    );

    setFilesToUpload(existingFiles =>
      Array.from(new Set([...existingFiles, ...validFiles]))
    );
    setInvalidFiles(existingFiles =>
      Array.from(new Set([...existingFiles, ...invalidFiles]))
    );
  }

  async function handleFilesChange() {
    const input = inputRef.current;

    if (input?.files?.length) {
      const newFiles = Array.from(input.files);
      await handleSetFiles(newFiles);
    }
    if (input) {
      input.value = '';
    }
  }
  async function handleFilesDrop(event: DragEvent<HTMLLabelElement>) {
    event.preventDefault();

    let files: File[];
    if (event.dataTransfer.items) {
      files = Array.from(event.dataTransfer.items)
        .map(item => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            return item.getAsFile();
          }
          return null;
        })
        .filter<File>((f): f is File => Boolean(f));
    } else {
      files = Array.from(event.dataTransfer.files);
    }

    const newFiles = Array.from(files);
    await handleSetFiles(newFiles);
  }

  function removeFileFromList(file: File) {
    setFilesToUpload(files => files?.filter(f => f !== file));
  }

  function removeInvalidFileFromList(file: File) {
    setInvalidFiles(files => files?.filter(f => f !== file));
  }

  function goToNextStep() {
    uploadStore.setFilesToUpload(filesToUpload);
    uploadStore.goToNextStep();
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs activeItem={1} />
      </BreadcrumbsWrapper>
      <MultiUploadFrame
        buttonText={intl.formatMessage({
          id: 'metodej.upload.form.chooseFiles.uploadFrame.buttonLabel',
        })}
        primaryText={intl.formatMessage({
          id: 'metodej.upload.form.chooseFiles.uploadFrame.primaryText',
        })}
        secondaryText={intl.formatMessage({
          id: 'metodej.upload.form.chooseFiles.uploadFrame.secondaryText',
        })}
        inputRef={inputRef}
        onChange={handleFilesChange}
        onDrop={handleFilesDrop}
        accept={acceptedFileTypes.join(',')}
      />
      <FileUploadListHeadline>
        <Text appearance="_14B" as="h3">
          {intl.formatMessage({
            id: 'metodej.upload.form.chooseFiles.uploadList.headline',
          })}
        </Text>
        {Boolean(filesToUpload.length) && (
          <FileUploadListSecondaryText appearance="_12M" as="div">
            {intl.formatMessage(
              {
                id: 'metodej.upload.form.chooseFiles.uploadList.description',
              },
              { count: filesToUpload.length + invalidFiles.length }
            )}
          </FileUploadListSecondaryText>
        )}
      </FileUploadListHeadline>
      <FileUploadList>
        {!filesToUpload?.length && !invalidFiles?.length ? (
          <FileUploadListSecondaryText appearance="_12M" as="p">
            {intl.formatMessage({
              id: 'metodej.upload.form.chooseFiles.uploadList.emptyList',
            })}
          </FileUploadListSecondaryText>
        ) : (
          <>
            {Array.from(filesToUpload).map(file => (
              <DocumentFormUploadListItem
                key={getFileKey(file)}
                label={file.name}
                handleCancelClick={() => removeFileFromList(file)}
                error={!isValidFileExtension(file.name)}
                errorText={intl.formatMessage({
                  id: 'metodej.upload.form.chooseFiles.uploadList.file.invalidType',
                })}
              />
            ))}
            {invalidFiles.map(file => (
              <DocumentFormUploadListItem
                key={getFileKey(file)}
                label={file.name}
                handleCancelClick={() => removeInvalidFileFromList(file)}
                error
                errorText={intl.formatMessage({
                  id: 'metodej.upload.form.chooseFiles.uploadList.file.invalidType',
                })}
              />
            ))}
          </>
        )}
      </FileUploadList>
      <Button
        appearance={ButtonAppearance.Primary}
        onClick={goToNextStep}
        disabled={
          !isValidUploadList(filesToUpload) || invalidFiles.length !== 0
        }
      >
        {intl.formatMessage({
          id: 'metodej.upload.form.chooseFiles.nextStepButtonLabel',
        })}
      </Button>
    </>
  );
});
