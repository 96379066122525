import { DocumentCoverAppearance } from '../components';
import { DocumentType } from '../types';

export function getCoverAppearance(
  documentType: DocumentType
): DocumentCoverAppearance | undefined {
  switch (documentType) {
    case 'book':
      return DocumentCoverAppearance.book;
    case 'academicThesis':
      return DocumentCoverAppearance.thesis;
    case 'researchPaperArticle':
      return DocumentCoverAppearance.article;
    case 'methodologicalGuidelines':
      return DocumentCoverAppearance.methodologicalGuidelines;
    case 'lecture':
      return DocumentCoverAppearance.lecture;
    case 'webPost':
      return DocumentCoverAppearance.webPost;
    case 'other':
      return DocumentCoverAppearance.other;
  }
}
