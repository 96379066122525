import { FileUploadService as iFileUploadService } from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import { action, makeObservable, observable } from 'mobx';
import { FileUploadStore } from './file-upload-store';

export class FileUploadService implements iFileUploadService {
  private readonly rootStore: RootStore;
  @observable
  private readonly fileUploads: FileUploadStore[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  uploadFile(file: File, scope?: string): FileUploadStore {
    const store = new FileUploadStore(this.rootStore, file, scope);
    this.fileUploads.push(store);

    return store;
  }
}
