import { Text, ThemeProvider, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';
import { DocumentStudyButton, StudyButton } from './document-study-button';

interface DocumentRecentProps {
  readonly cover: ReactElement;
  readonly label: string;
  readonly author: string;
  readonly tags?: ReactElement;
  readonly showStudyButton?: boolean;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_dark};
    row-gap: ${theme.size.x05};
  `};
`;

const Author = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
    margin-top: ${theme.size.x025};
  `};
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  transition: background-color 250ms ease;
  position: relative;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x15};
    padding: ${theme.size.x2};
    column-gap: ${theme.size.x2};
    background-color: ${theme.color.background_background_02};
    cursor: pointer;

    :hover {
      background-color: ${theme.color.background_background_03};

      ${StudyButton} {
        pointer-events: all;
        opacity: 1;
      }
    }
  `};
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;
const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme.size.x05};
  `};
`;

export function DocumentRecent({
  cover,
  label,
  author,
  tags,
  showStudyButton = false,
}: DocumentRecentProps) {
  return (
    <Layout>
      {cover}
      {showStudyButton && <DocumentStudyButton />}
      <ContentWrapper>
        <Text appearance="_12B" lineClamp={2}>
          {label}
        </Text>
        <Author appearance="_12M">{author}</Author>
        <BottomWrapper>
          {tags && (
            <TagsWrapper>
              <ThemeProvider theme="master" dark>
                {tags}
              </ThemeProvider>
            </TagsWrapper>
          )}
        </BottomWrapper>
      </ContentWrapper>
    </Layout>
  );
}
