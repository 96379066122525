import { css, styled } from '@yarmill/component-library';

export const ProgressBar = styled.progress`
  ${({ theme }) => css`
    display: block;
    height: ${theme.size.x05};
    width: 100%;
    margin: 0;
    background-color: ${theme.dark ? theme.color.brand_yarmill_dark : theme.color.brand_yarmill_35};
    border-radius: ${theme.size.x025};
    transition: width 200ms ease;

    -webkit-appearance: none;
    vertical-align: unset;
    block-size: ${theme.size.x05};
    inline-size: 100%;

    &::-webkit-progress-bar {
      background-color: ${theme.dark ? theme.color.brand_yarmill_dark : theme.color.brand_yarmill_35};
      border-radius: ${theme.size.x025};
      transition: width 200ms ease;
    }
    &::-webkit-progress-value {
      background-color: ${theme.dark ? theme.color.brand_yarmill_20 : theme.color.brand_yarmill};
      transition: width 200ms ease;
      border-radius: ${theme.size.x025};
    }
    &::-moz-progress-bar {
      background-color: ${theme.dark ? theme.color.brand_yarmill_20 : theme.color.brand_yarmill};
      border-radius: ${theme.size.x025};
      transition: width 200ms ease;
    }
  `}
`;
