import { Text, css, styled } from '@yarmill/component-library';
import { PropsWithChildren } from 'react';

const Layout = styled.fieldset`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: none;
  ${({ theme }) => css`
    column-gap: ${theme.size.x2};
    row-gap: ${theme.size.x3};

    & + & {
      border-top: ${theme.size.x0125} solid ${theme.color.background_background_04};
      padding-top: ${theme.size.x35};
      margin-top: ${theme.size.x35};
    }
  `};
`;

export const DocumentFormSectionRow = styled.div<{ readonly columns?: number }>`
  display: grid;
  grid-column: span 2;
  ${({ theme, columns = 1 }) => css`
    grid-template-columns: repeat(${columns}, 1fr);
    column-gap: ${theme.size.x2};
  `};
`;

const Headline = styled(Text)`
grid-column: 1 / -1`;

type DocumentFormSectionProps = PropsWithChildren<{
  readonly headline?: string;
}>;

export function DocumentFormSection({
  headline,
  children,
}: DocumentFormSectionProps) {
  return (
    <Layout>
      {headline && (
        <Headline appearance="_16B" as="h3">
          {headline}
        </Headline>
      )}
      {children}
    </Layout>
  );
}
