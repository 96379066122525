import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { UploadCanvas } from '../upload/upload-canvas';
import { UploadDrawer } from '../upload/upload-drawer';

export const UploadPage = observer(function UploadPage() {
  const uploadStore = useDocumentsUploadStore();

  useEffect(() => {
    runInAction(() => {
      uploadStore.setFocusedItem(null);
      if (uploadStore.uploadingDocuments.length === 0) {
        uploadStore.resetFormStep();
      }
    });
  }, [uploadStore]);

  return (
    <>
      <UploadDrawer />
      <UploadCanvas />
    </>
  );
});
