import { ReactElement } from 'react';
import { css, styled } from './theme-provider';

const Layout = styled.header<{
  readonly as: 'header' | 'footer';
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0;
  flex-shrink: 0;
  justify-content: space-between;

  ${({ theme, as }) => css`
    column-gap: ${theme.size.x1};
    min-height: ${theme.size.x6};
    padding: 0 ${theme.size.x1};
    background-color: ${theme.dark ? theme.color.neutral_neutral_darker : theme.color.background_background_02};

    ${
      as === 'header' &&
      css`
      border-top-left-radius: ${theme.borderRadius.x2};
      border-top-right-radius: ${theme.borderRadius.x2};
    `
    };
    ${
      as === 'footer' &&
      css`
      border-bottom-left-radius: ${theme.borderRadius.x2};
      border-bottom-right-radius: ${theme.borderRadius.x2};
    `
    };
  `};
`;

export const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;

  ${({ theme }) => css`
    gap: ${theme.size.x25};
    color: ${theme.dark ? theme.color.neutral_neutral_2 : theme.color.neutral_neutral_dark};
  `};
`;

const ButtonsContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

const ButtonsLayout = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: ${({ theme }) => theme.size.x1};
  max-width: 100%;
`;

interface PanelToolbarProps {
  readonly left?: ReactElement;
  readonly center?: ReactElement;
  readonly right?: ReactElement;
  readonly position?: 'header' | 'footer';
}

export function PanelToolbar({
  left,
  center,
  right,
  position = 'header',
}: PanelToolbarProps): ReactElement {
  return (
    <Layout as={position}>
      <ButtonsContainer>
        <ButtonsLayout>{left}</ButtonsLayout>
      </ButtonsContainer>
      <HeadlineContainer>{center}</HeadlineContainer>
      <ButtonsContainer>
        <ButtonsLayout>{right}</ButtonsLayout>
      </ButtonsContainer>
    </Layout>
  );
}
