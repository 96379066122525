import { useMemo } from 'react';
import {
  DEFAULT_MARKER_SIZE,
  LABEL_FONT_SIZE,
  LABEL_OFFSET,
  MIN_LABEL_FONT_SIZE,
} from '../../reporting/const';
import { AxisValue, DataItem, YAxisConfig } from '../../reporting/types';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { getRotatedWidth } from '../../reporting/utils/get-rotated-width';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { ChartConfig } from '../types';
import { createKeysScale } from '../utils/create-keys-scale';
import { getShrankLabelHeight } from '../utils/get-shrank-label-height';
import { isValidRange } from '../utils/is-valid-range';
import { useScale } from './use-scale';

export function useCalculateTopPadding(
  configs: ChartConfig[],
  data: DataItem[],
  xScale: ReturnType<typeof useScale>,
  yAxisConfig?: YAxisConfig<AxisValue>
) {
  const xRange = xScale.range();

  return useMemo(
    () =>
      isValidRange(xRange)
        ? Math.max(
            yAxisConfig?.showTickLabels ? LABEL_FONT_SIZE / 2 : 0,
            ...data.flatMap(item =>
              configs.flatMap(config => {
                if (config.type === 'BarGroup') {
                  if (
                    config.labelPosition !== 'outside' ||
                    !('bandwidth' in xScale)
                  ) {
                    return 0;
                  }

                  const keysScale = createKeysScale(
                    config.keys,
                    xScale.bandwidth()
                  );

                  return Math.max(
                    ...config.keys.map(key => {
                      const show = config.getShowLabels(item, key);
                      if (!show) {
                        return 0;
                      }

                      const maxWidth =
                        config.keys.length > 1
                          ? keysScale.step()
                          : xScale.step();

                      const customAngle = config.getLabelAngle(item, key);
                      const value = item[key];
                      const label = config.formatLabelValue(
                        value as number,
                        key
                      );
                      const labelWidth = getSSRStringWidth(label);

                      const shrankLabelHeight = getShrankLabelHeight(
                        customAngle
                          ? getRotatedWidth(
                              labelWidth,
                              LABEL_FONT_SIZE,
                              customAngle
                            )
                          : labelWidth,
                        maxWidth
                      );

                      const isSmallToShow =
                        (!customAngle || Math.abs(customAngle) === 90) &&
                        shrankLabelHeight < MIN_LABEL_FONT_SIZE;
                      const shouldRotate =
                        !customAngle &&
                        isSmallToShow &&
                        maxWidth >= MIN_LABEL_FONT_SIZE;

                      if (isSmallToShow && !shouldRotate) {
                        return 0;
                      }

                      const angle = shouldRotate ? -90 : customAngle;

                      return getRotatedHeight(
                        labelWidth,
                        LABEL_FONT_SIZE,
                        angle ?? 0
                      );
                    })
                  );
                } else if (config.type === 'Bar') {
                  const show = config.getShowLabels(item);
                  if (
                    !show ||
                    config.labelPosition !== 'outside' ||
                    !('bandwidth' in xScale)
                  ) {
                    return 0;
                  }

                  const maxWidth = xScale.step();
                  const customAngle = config.getLabelAngle(item);
                  const value = config.getYValue(item);
                  const label =
                    value !== null ? config.formatLabelValue(value) : '';
                  const labelWidth = getSSRStringWidth(label);

                  const shrankLabelHeight = getShrankLabelHeight(
                    customAngle
                      ? getRotatedWidth(
                          labelWidth,
                          LABEL_FONT_SIZE,
                          customAngle
                        )
                      : labelWidth,
                    maxWidth
                  );

                  const isSmallToShow =
                    (!customAngle || Math.abs(customAngle) === 90) &&
                    shrankLabelHeight < MIN_LABEL_FONT_SIZE;
                  const shouldRotate =
                    !customAngle &&
                    isSmallToShow &&
                    maxWidth >= MIN_LABEL_FONT_SIZE;

                  if (isSmallToShow && !shouldRotate) {
                    return 0;
                  }

                  const angle = shouldRotate ? -90 : customAngle;

                  return (
                    getRotatedHeight(labelWidth, LABEL_FONT_SIZE, angle ?? 0) +
                    LABEL_OFFSET
                  );
                } else if (config.type === 'FloatingBar') {
                  const show = config.getShowLabels(item);

                  if (!show || config.labelPosition !== 'outside') {
                    return 0;
                  }

                  const angle = config.getLabelAngle(item);
                  const value = config.getYValue(item);
                  const label =
                    value !== null ? config.formatLabelValue(value) : '';
                  const width = getSSRStringWidth(label);

                  return getRotatedHeight(width, LABEL_FONT_SIZE, angle ?? 0);
                } else if (
                  config.type === 'BarStack' ||
                  config.type === 'HorizontalBarGroup' ||
                  config.type === 'HorizontalBarStack'
                ) {
                  return 0;
                } else {
                  const show = config.getShowLabels(item);
                  const labelMargin = show ? LABEL_FONT_SIZE + LABEL_OFFSET : 0;
                  const markerMargin =
                    config.getMarkerSize?.(item) ?? DEFAULT_MARKER_SIZE;

                  return Math.max(labelMargin, markerMargin);
                }
              })
            )
          )
        : 0,
    [configs, data, xScale, yAxisConfig?.showTickLabels, xRange]
  );
}
