import { css, styled } from './theme-provider';

export const FormInputLayout = styled.div<{ readonly $stretch?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $stretch = true, theme }) => css`
    row-gap: ${theme.size.x1};
    justify-self: ${$stretch ? 'stretch' : 'flex-start'};
  `}
`;
