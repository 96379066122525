import { Text, css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';

const WIDTH = 304;
interface FormGuidePopoverLayoutProps {
  readonly image: ReactElement;
  readonly stepName: string;
  readonly headline: string;
  readonly text: string;
  readonly description?: string;
  readonly backButton?: ReactElement;
  readonly nextButton?: ReactElement;
}

const Layout = styled.div`
  width: ${WIDTH}px;

  ${({ theme }) => css`
    padding: ${theme.size.x15};
  `};
`;

const TopPart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${({ theme }) => css`
    margin-bottom: ${theme.size.x25};
  `};
`;

const StepNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => css`
    column-gap: ${theme.size.x1};
  `};
`;

const StepName = styled.div`
  ${({ theme }) => css`
    padding: ${theme.size.x05} ${theme.size.x1};
    background-color: ${theme.color.other_blush_24};
    color: ${theme.color.other_blush_dark};
    border-radius: ${theme.size.x1};
  `};
`;

const Headline = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.size.x1};
  `};
`;

const Description = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.size.x2};
    color: ${theme.color.neutral_neutral_36};
  `};
`;

export const Paragraph = styled(Text)`
    ${({ theme }) => css`
      &:not(:last-child) {
        margin-bottom: ${theme.size.x1};
      }
    `};
`;

export const List = styled(Text)`
  list-style-position: inside;
  ${({ theme }) => css`
      margin-left: ${theme.size.x05};
  `};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    margin-top: ${theme.size.x2};
  `};
`;

export function FormGuidePopoverLayout({
  image,
  stepName,
  headline,
  text,
  description,
  backButton,
  nextButton,
}: FormGuidePopoverLayoutProps) {
  return (
    <Layout>
      <TopPart>
        <StepNameWrapper>
          <StepName>
            <Text appearance="_14B">{stepName}</Text>
          </StepName>
        </StepNameWrapper>
        {image}
      </TopPart>
      <Headline appearance="_16B" as="h3">
        {headline}
      </Headline>
      <Text appearance="_12M" as="div">
        {text}
      </Text>
      {description && (
        <Description appearance="_10B" as="p">
          {description}
        </Description>
      )}
      <ButtonsWrapper>
        {backButton ?? <span />}
        {nextButton ?? <span />}
      </ButtonsWrapper>
    </Layout>
  );
}
