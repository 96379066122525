import {
  BadgeAppearance,
  Button,
  ButtonAppearance,
  showToastMessage,
} from '@yarmill/component-library';
import { useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';

export const PublishButton = observer(function PublishButton() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const libraryStore = useDocumentLibraryStore();
  const logger = useLogger();
  const focusedItem = uploadStore.focusedItem;

  async function publishDocument() {
    if (focusedItem instanceof DocumentStore) {
      try {
        await uploadStore.formikRef?.current.submitForm();
        if (uploadStore.formikRef?.current?.isValid) {
          await uploadStore.publishDocument(focusedItem);
          await libraryStore.loadDocuments();
        }
      } catch (error) {
        logger.error(error);
        showToastMessage(
          intl.formatMessage({
            id: 'metodej.form.upload.publishDocumentAlert.toast.error',
          }),
          BadgeAppearance.error
        );
      }
    }
  }

  return (
    <Button
      type="button"
      appearance={ButtonAppearance.Primary}
      onClick={publishDocument}
    >
      {intl.formatMessage({ id: 'metodej.upload.form.publishDocument' })}
    </Button>
  );
});
