import { css, styled } from '@yarmill/component-library';
import { ReactElement } from 'react';

interface CoverSelectorProps {
  readonly covers: ReactElement[];
  readonly selectedCover?: number;
  readonly onCoverChange: (cover: number) => void;
}

const Layout = styled.div`
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.size.x25};
  `};
`;
const CoverWrapper = styled.div<{ readonly $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme, $isSelected }) => css`
    padding: ${theme.size.x2};
    border-radius: ${theme.borderRadius.x1};
    background-color: ${theme.color.background_background_02};
    border: 1.5px solid ${$isSelected ? theme.color.brand_yarmill : 'transparent'};

    &:hover {
      background-color: ${theme.color.background_background_03};
    }
  `};
`;
export function CoverSelector({
  covers,
  selectedCover,
  onCoverChange,
}: CoverSelectorProps) {
  return (
    <Layout>
      {covers.map((cover, idx) => (
        <CoverWrapper
          key={cover.key ?? idx}
          onClick={() => onCoverChange(idx)}
          role="button"
          $isSelected={selectedCover === idx}
        >
          {cover}
        </CoverWrapper>
      ))}
    </Layout>
  );
}
