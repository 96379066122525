import {
  BadgeAppearance,
  Button,
  ButtonAppearance,
  Popover,
  Text,
  showToastMessage,
} from '@yarmill/component-library';
import { Bin } from '@yarmill/icon-library';
import { useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';

interface RemoveUnpublishedDocumentPopoverProps {
  readonly closePopover: () => void;
}
export const RemoveUnpublishedDocumentPopover = observer(
  function RemoveUnpublishedDocumentPopover({
    closePopover,
  }: RemoveUnpublishedDocumentPopoverProps) {
    const intl = useIntl();
    const uploadStore = useDocumentsUploadStore();
    const logger = useLogger();
    const focusedItem = uploadStore.focusedItem;

    async function removeDocument() {
      if (focusedItem instanceof DocumentStore) {
        try {
          closePopover();
          await uploadStore.removeDocument(focusedItem);
          showToastMessage(
            intl.formatMessage({
              id: 'metodej.form.upload.removeDocumentAlert.toast.success',
            }),
            BadgeAppearance.good
          );
        } catch (error) {
          logger.error(error);
          showToastMessage(
            intl.formatMessage({
              id: 'metodej.form.upload.removeDocumentAlert.toast.error',
            }),
            BadgeAppearance.error
          );
        }
      }
    }

    return (
      <Popover
        label={intl.formatMessage({
          id: 'metodej.form.upload.removeDocumentAlert.headline',
        })}
        buttons={
          <>
            <Button
              appearance={ButtonAppearance.Tertiary}
              onClick={closePopover}
            >
              {intl.formatMessage({
                id: 'metodej.form.upload.removeDocumentAlert.cancelButtonLabel',
              })}
            </Button>
            <Button
              appearance={ButtonAppearance.Error}
              onClick={removeDocument}
              icon={<Bin />}
            >
              {intl.formatMessage({
                id: 'metodej.form.upload.removeDocumentAlert.removeButtonLabel',
              })}
            </Button>
          </>
        }
      >
        <Text appearance="_12M" as="p" align="center">
          {intl.formatMessage(
            {
              id: 'metodej.form.upload.removeDocumentAlert.text',
            },
            {
              filesName:
                focusedItem instanceof DocumentStore ? focusedItem.title : '',
            }
          )}
        </Text>
      </Popover>
    );
  }
);
