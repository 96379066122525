import { useIntl } from 'react-intl';
import { DocumentStore } from '../stores/document-store';
import { DocumentUploadStore } from '../stores/document-upload-store';
import { useDocumentsUploadStore } from './use-documents-upload-store';

export function useUploadCanvasTitleText(): string {
  const uploadStore = useDocumentsUploadStore();
  const focusItem = uploadStore.focusedItem;
  const currentFormStep = uploadStore.currentFormStep;
  const intl = useIntl();

  if (focusItem instanceof DocumentStore) {
    if (uploadStore.documentsToCheck.has(focusItem.documentId)) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.documentCheck',
      });
    } else if (uploadStore.publishedDocuments.has(focusItem.documentId)) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.published',
      });
    }
  } else if (focusItem instanceof DocumentUploadStore) {
    return intl.formatMessage({
      id: 'metodej.upload.canvas.title.uploading',
    });
  } else if (!focusItem) {
    if (currentFormStep === 0) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.form.guide',
      });
    } else if (currentFormStep === 1) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.form.chooseFiles',
      });
    } else if (currentFormStep === 2) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.form.agreement',
      });
    } else if (currentFormStep === 3) {
      return intl.formatMessage({
        id: 'metodej.upload.canvas.title.uploading',
      });
    }
  }

  return '';
}
