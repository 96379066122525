import { TagAppearance } from '@yarmill/component-library';

export function getTagAppearance(
  type: 'sports' | 'subjects' | 'ageCategories' | 'tags'
): TagAppearance {
  switch (type) {
    case 'ageCategories':
      return TagAppearance.yellow;
    case 'sports':
      return TagAppearance.plum;
    case 'subjects':
      return TagAppearance.orangineMild;
    case 'tags':
      return TagAppearance.frog;
  }
}
