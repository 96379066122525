import { IntlShape } from 'react-intl';
import { MetodejDocument } from '../types';

export function translateCiteAttribute<K extends keyof MetodejDocument>(
  intl: IntlShape,
  name: K,
  value: MetodejDocument[K]
) {
  if (name === 'languageCode') {
    return intl.formatMessage({
      id: `general.language.${value}`,
    });
  }
  if (name === 'thesisType') {
    return intl.formatMessage({
      id: `metodej.document.form.field.thesisType.${value}`,
    });
  }

  return value ?? '';
}
