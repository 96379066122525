import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Bin } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { RemoveUnpublishedDocumentPopover } from '../popovers/remove-unpublished-document-popover';
import { DocumentStore } from '../stores/document-store';

export const RemoveUnpublishedDocumentButton = observer(
  function RemoveUnpublishedDocumentButton() {
    const intl = useIntl();
    const uploadStore = useDocumentsUploadStore();
    const label = 'metodej.upload.form.removeDocument';
    const focusedDocument = uploadStore.focusedItem;

    if (!(focusedDocument instanceof DocumentStore)) {
      return null;
    }

    return (
      <PopoverProvider
        content={closePopover => (
          <RemoveUnpublishedDocumentPopover closePopover={closePopover} />
        )}
      >
        <Tippy tooltipContent={label} noWrapper>
          <Button
            as="div"
            appearance={ButtonAppearance.Tertiary}
            icon={<Bin />}
            aria-label={intl.formatMessage({
              id: label,
            })}
          />
        </Tippy>
      </PopoverProvider>
    );
  }
);
