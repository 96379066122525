import { Text } from './text';
import { styled } from './theme-provider';

interface PanelTitleProps {
  readonly text: string;
}

const PanelTitleText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export function PanelTitle({ text }: PanelTitleProps) {
  return <PanelTitleText appearance="_12B">{text}</PanelTitleText>;
}
