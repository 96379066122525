import { PropsWithChildren, ReactElement } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
   @font-face {
     font-family: 'Haffer';
     font-style: normal;
     font-display: block;
     src:
       url(/fonts/haffer/HafferXHVF.woff2) format('woff2'),
       url(/fonts/haffer/HafferXHVF.woff) format('woff');
   }
   @font-face {
     font-family: 'Haffer';
     font-style: italic;
     font-display: block;
     src:
       url(/fonts/haffer/HafferXHVFItalics.woff2) format('woff2'),
       url(/fonts/haffer/HafferXHVFItalics.woff) format('woff');
   }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-synthesis-weight: none;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  #yarmill-diary-app, #ladle-root {
    width: 100%;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100% !important;
    position: fixed;
  }
`;

const StyledIntegratorRoot = styled.div`
  height: 100%;
  width: 100%;

  * {
    box-sizing: border-box;
  }

`;

export function IntegratorRoot(props: PropsWithChildren): ReactElement {
  return (
    <StyledIntegratorRoot>
      <GlobalStyle />
      {props.children}
    </StyledIntegratorRoot>
  );
}
