import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Multiply } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { CancelEditPopover } from '../popovers/cancel-edit-popover';

export const DetailEditCancelButton = observer(
  function DetailEditCancelButton() {
    const intl = useIntl();
    const label = 'metodej.upload.form.editCancelButtonLabel';

    return (
      <PopoverProvider
        content={closePopover => (
          <CancelEditPopover closePopover={closePopover} />
        )}
      >
        <Tippy tooltipContent={label} noWrapper>
          <Button
            as="div"
            appearance={ButtonAppearance.Tertiary}
            icon={<Multiply />}
            aria-label={intl.formatMessage({
              id: label,
            })}
          />
        </Tippy>
      </PopoverProvider>
    );
  }
);
