import { PropsWithChildren, forwardRef } from 'react';
import { Text } from './text';
import { styled } from './theme-provider';

const StyledFormInputError = styled(Text)`
  color: ${({ theme }) => theme.color.other_red};
`;

export const FormInputError = forwardRef<HTMLDivElement, PropsWithChildren>(
  function FormInputError({ children }, ref) {
    return (
      <StyledFormInputError
        as="div"
        appearance="_10M"
        whiteSpace="noWrap"
        ref={ref}
      >
        {children}
      </StyledFormInputError>
    );
  }
);
