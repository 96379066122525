import {
  BadgeAppearance,
  ExternalLink,
  showToastMessage,
} from '@yarmill/component-library';
import { useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { BreadcrumbsWrapper, DocumentPublishedImage } from '../components';
import { InfoPageLayout } from '../components/info-page-layout';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentStore } from '../stores/document-store';
import { Breadcrumbs } from './breadcrumbs';

export const PublishedDocumentDetail = observer(
  function PublishedDocumentDetail() {
    const intl = useIntl();
    const uploadStore = useDocumentsUploadStore();
    const libraryStore = useDocumentLibraryStore();
    const focusedItem = uploadStore.focusedItem;
    const logger = useLogger();

    if (
      !(focusedItem instanceof DocumentStore) ||
      !uploadStore.publishedDocuments.has(focusedItem.documentId)
    ) {
      return null;
    }

    async function unpublishDocument() {
      if (!(focusedItem instanceof DocumentStore)) {
        return;
      }
      try {
        await uploadStore.unpublishDocument(focusedItem);
        const libraryDocument = libraryStore.documentsById.get(
          focusedItem.documentId
        );
        if (libraryDocument) {
          libraryStore.unpublishDocument(libraryDocument);
        }

        showToastMessage(
          intl.formatMessage({
            id: 'metodej.form.upload.unpublishDocument.toast.success',
          }),
          BadgeAppearance.good
        );
      } catch (error) {
        logger.error(error);
        showToastMessage(
          intl.formatMessage({
            id: 'metodej.form.upload.unpublishDocument.toast.error',
          }),
          BadgeAppearance.error
        );
      }
    }

    return (
      <>
        <BreadcrumbsWrapper>
          <Breadcrumbs activeItem={4} />
        </BreadcrumbsWrapper>
        <InfoPageLayout
          showMarginTop
          headline={intl.formatMessage({
            id: 'metodej.upload.detail.documentPublished.headline',
          })}
          text={intl.formatMessage<any, any>(
            {
              id: 'metodej.upload.detail.documentPublished.text',
            },
            {
              undo: (content: string) => (
                <ExternalLink
                  as="span"
                  role="button"
                  onClick={unpublishDocument}
                >
                  {content}
                </ExternalLink>
              ),
            }
          )}
          image={<DocumentPublishedImage />}
        />
      </>
    );
  }
);
