import { Text, css, styled } from '@yarmill/component-library';

export const UploadFormGuideHeadline = styled(Text)`
  ${({ theme }) => css`
      margin-bottom: ${theme.size.x5};
  `};
`;

export const UploadFormGuideLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${({ theme }) => css`
    margin-bottom: ${theme.size.x4};
    column-gap: ${theme.size.x2};
  `};
`;
