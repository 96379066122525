import { useFormik } from 'formik';
import { DocumentFormat, DocumentType, MetodejDocument } from './document';

export type FormLayoutForDocumentTypeMap = {
  [key in DocumentType]: (keyof MetodejDocument)[];
};

export type FormLayoutForDocumentFormatMap = {
  [key in DocumentFormat]: FormLayoutForDocumentTypeMap;
};

export type FormFieldLayoutDefinitionMap = {
  [key in keyof Partial<MetodejDocument>]: 1 | 2;
};

export type MetodejFormValues = MetodejDocument;
export type MetodejFormik = ReturnType<typeof useFormik<MetodejFormValues>>;

export const DOCUMENT_TYPE_CITE_FORM_FIELDS = [
  'isbn',
  'authors',
  'publisher',
  'edition',
  'publicationPlace',
  'publicationYear',
  'languageCode',
  'medium',
  'issn',
  'hostEntity',
  'thesisType',
  'thesisInstitution',
  'thesisSupervisor',
  'url',
  'doi',
] as const;
export type CiteFormField = (typeof DOCUMENT_TYPE_CITE_FORM_FIELDS)[number];

export const TAG_FORM_FIELDS = [
  'sports',
  'subjects',
  'ageCategories',
  'tags',
] as const;
export type TagFormField = (typeof TAG_FORM_FIELDS)[number];
