import { useLogger } from '@yarmill/utils';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import {
  CoverSelector,
  DocumentCover,
  DocumentFormSectionRow,
} from '../components';
import { OriginalDocumentCover } from '../components/original-document-cover';
import { DocumentFormContext } from '../context/document-form-context';
import { MetodejFormValues, MetodejFormik } from '../types';
import { getCoverAppearance } from '../utils/get-cover-appearance';
import { handleAutoSaveFormValues } from '../utils/handle-auto-save-form-values';

export const CoverField = observer(function CoverField() {
  const formik = useFormikContext<MetodejFormValues>();
  const { title, coverPage, documentType } = formik.values;
  const { useOriginalCover, coverPageUrl } = coverPage ?? {
    useOriginalCover: false,
    coverPageUrl: null,
  };
  const intl = useIntl();
  const covers = useMemo(
    () =>
      [
        <DocumentCover
          label={title ?? ''}
          key="default"
          appearance={
            documentType ? getCoverAppearance(documentType) : undefined
          }
        />,
        coverPageUrl ? (
          <OriginalDocumentCover
            label={title ?? ''}
            src={coverPageUrl}
            key="original"
          />
        ) : undefined,
      ].filter(Boolean) as ReactElement[],
    [title, coverPageUrl, documentType]
  );
  const documentForm = useContext(DocumentFormContext);
  const autoSave = documentForm?.autoSave;
  const documentStore = documentForm?.documentStore;
  const logger = useLogger();

  const onCoverChange = useCallback(
    async (coverIdx: number) => {
      await formik.setFieldValue(
        'coverPage.useOriginalCover',
        Boolean(coverIdx)
      );
    },
    [formik]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (autoSave && documentStore) {
      handleAutoSaveFormValues(
        formik as MetodejFormik,
        documentStore,
        intl,
        logger
      );
    }
  }, [useOriginalCover, logger, autoSave, documentStore]);

  return (
    <DocumentFormSectionRow>
      <CoverSelector
        covers={covers}
        onCoverChange={onCoverChange}
        selectedCover={useOriginalCover ? 1 : 0}
      />
    </DocumentFormSectionRow>
  );
});
