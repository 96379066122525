import {
  AppContainer,
  AppLayout,
  IntegratorRoot,
  ThemeProvider,
} from '@yarmill/component-library';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, memo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useMetodejStore } from './hooks/use-metodej-store';
import { usePageHasDrawer } from './hooks/use-page-has-drawer';
import { MainMenu } from './main-menu';
import { MetodejStoreProvider } from './metodej-store-provider';
import { MetodejTotem } from './metodej-totem';
import { LibraryPage } from './pages/library-page';
import { UploadPage } from './pages/upload-page';
import { getMetodejUrl } from './utils/get-metodej-url';

const MetodejRoot = observer(function MetodejRoot(): ReactElement {
  const currentUser = useCurrentUserStore();
  const hasDrawer = usePageHasDrawer();
  const metodejStore = useMetodejStore();

  return (
    <AppContainer>
      <AppLayout
        $hasDrawer={hasDrawer}
        $showSideMenu={metodejStore.showMainMenu}
      >
        <MetodejTotem />
        <MainMenu />
        <Switch>
          {currentUser.isAllowedTo('metodej.library') && (
            <Route path={getMetodejUrl('library')}>
              <LibraryPage />
            </Route>
          )}
          {currentUser.isAllowedTo('metodej.upload') ? (
            <Route path={getMetodejUrl('upload')}>
              <UploadPage />
            </Route>
          ) : (
            <Redirect to={getMetodejUrl('library')} />
          )}
        </Switch>
      </AppLayout>
    </AppContainer>
  );
});

export const MetodejModule = memo(() => (
  <ThemeProvider theme="master">
    <Helmet>
      <meta name="theme-color" content="#cbcbd5" />
    </Helmet>
    <IntegratorRoot>
      <MetodejStoreProvider>
        <MetodejRoot />
      </MetodejStoreProvider>
    </IntegratorRoot>
  </ThemeProvider>
));
