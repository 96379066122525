import { action, makeObservable, observable } from 'mobx';
import { DocumentLibraryStore } from './document-library-store';
import { DocumentsUploadStore } from './documents-upload-store';

export class MetodejStore {
  private readonly _documentsUploadStore = new DocumentsUploadStore();
  private readonly _documentLibraryStore = new DocumentLibraryStore();

  @observable
  private _showMainMenu = true;

  constructor() {
    makeObservable(this);
  }

  get showMainMenu() {
    return this._showMainMenu;
  }

  get documentsUploadStore() {
    return this._documentsUploadStore;
  }

  get documentLibraryStore() {
    return this._documentLibraryStore;
  }

  @action
  toggleMainMenu() {
    this._showMainMenu = !this._showMainMenu;
  }
}
