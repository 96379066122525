import { AsyncStatus } from '@yarmill/types';
import { GlobalLogger } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import {
  IObservableArray,
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
  when,
} from 'mobx';
import { api } from '../metodej-api';
import { MetodejDocument } from '../types';
import { DocumentStore } from './document-store';

export class DocumentLibraryStore {
  @observable
  private readonly _documentsPerId: ObservableMap<number, DocumentStore> =
    observable.map();

  @observable
  private readonly _sortedDocuments: IObservableArray<DocumentStore> =
    observable.array();

  @observable
  public documentFilter: string | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  private _loadPublishedDocumentsRequest: AxiosPromise<
    MetodejDocument[]
  > | null = null;

  constructor() {
    when(
      () => api.hasBaseUrl,
      () => {
        this.loadDocuments();
      }
    );
    makeObservable(this);
  }

  @computed
  get recentDocuments() {
    return this._sortedDocuments.slice(0, 3);
  }

  @computed
  get overviewDocuments() {
    return this._sortedDocuments.slice(0, 12);
  }

  get allDocuments() {
    return Array.from(this._sortedDocuments);
  }

  @computed
  get filteredDocuments() {
    const filter = this.documentFilter;
    return this._sortedDocuments.filter(doc =>
      doc.title?.toLowerCase().includes(filter?.toLowerCase() ?? '')
    );
  }

  get documentsById() {
    return this._documentsPerId;
  }

  @action
  async loadDocuments() {
    this._status = AsyncStatus.pending;
    const documents = await this.loadPublishedDocuments();
    this._sortedDocuments.clear();
    documents.forEach(document => {
      const store = new DocumentStore(document);
      this._documentsPerId.set(store.documentId, store);
      this._sortedDocuments.push(store);
    });
    this._status = AsyncStatus.resolved;
  }

  @action
  unpublishDocument(documentStore: DocumentStore) {
    this._documentsPerId.delete(documentStore.documentId);
    this._sortedDocuments.remove(documentStore);
  }

  async removeDocument(document: DocumentStore) {
    await api.deleteDocument(document.documentId);
    this._documentsPerId.delete(document.documentId);
    this._sortedDocuments.remove(document);
  }

  get status(): AsyncStatus {
    return this._status;
  }

  private async loadPublishedDocuments(): Promise<MetodejDocument[]> {
    try {
      if (this._loadPublishedDocumentsRequest) {
        return [];
      }
      const request = api.getPublishedDocuments();
      this._loadPublishedDocumentsRequest = request;
      const response = await request;
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      GlobalLogger.error(e);
    } finally {
      this._loadPublishedDocumentsRequest = null;
    }

    return [];
  }
}
