import { Button, ButtonAppearance, Tippy } from '@yarmill/component-library';
import { SidemenuFull } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useMetodejStore } from '../hooks/use-metodej-store';

export const ToggleSideMenu = observer(function ToggleSideMenu() {
  const intl = useIntl();
  const metodejStore = useMetodejStore();
  const label = metodejStore.showMainMenu
    ? 'metodej.mainMenu.hideSideMenu'
    : 'metodej.mainMenu.showSideMenu';

  return (
    <Tippy tooltipContent={label} noWrapper>
      <Button
        type="button"
        appearance={ButtonAppearance.Tertiary}
        icon={<SidemenuFull />}
        onClick={() => metodejStore.toggleMainMenu()}
        aria-label={intl.formatMessage({
          id: label,
        })}
      />
    </Tippy>
  );
});
