import { StyledTd, Text, TextSize, Tippy, styled } from '@yarmill/components';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExpandButton } from './expand-button';
import { ExpandableTree } from './mobx/expandable-tree';
import { CsvTableAlignment, CsvTableFirstColumnItem } from './types';

export interface CsvTableFirstColumnCellProps {
  rowIndex: number;
  item: CsvTableFirstColumnItem;
  labelIsTranslateString?: boolean;
  expandable?: ExpandableTree<CsvTableFirstColumnItem>;
  alignment?: CsvTableAlignment | null;
}

interface FirstColumnCellProps {
  textColor?: string | null;
  backgroundColor?: string | null;
}

const FirstColumnCell = styled(StyledTd)<FirstColumnCellProps>`
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props => props.textColor && `color: ${props.textColor};`}
  white-space: nowrap;
  position: sticky;
  left: 0;
  background-color: #ffffff;
  padding: 4px 8px;

  tr:hover > & {
    background-color: inherit;
  }

  @media print {
    page-break-inside: avoid;
  }
`;

export function CsvTableFirstColumnCell(
  props: CsvTableFirstColumnCellProps
): JSX.Element {
  const { item, labelIsTranslateString, expandable, rowIndex, alignment } =
    props;

  const label = labelIsTranslateString ? (
    <FormattedMessage id={item.Label} />
  ) : (
    item.Label
  );

  const level = useMemo(
    () => (item.Level ? '\u00A0'.repeat(item.Level * 3) : ''),
    [item.Level]
  );

  const expandButton =
    expandable && expandable.isNodeExpandable(rowIndex) ? (
      <ExpandButton expandable={expandable} id={rowIndex} />
    ) : null;

  return (
    <FirstColumnCell
      backgroundColor={item.BackgroundColor}
      textColor={item.Color}
      align={alignment || 'left'}
    >
      {level}
      <Tippy
        tooltipContent={item.Tooltip}
        isEnabled={Boolean(item.Tooltip)}
        translateValue={false}
      >
        <Text size={TextSize.s12} bold={Boolean(item.IsBold)} inheritColor>
          {label}
        </Text>
      </Tippy>
      {expandButton}
    </FirstColumnCell>
  );
}
