import { PropsWithChildren, forwardRef } from 'react';
import { Text } from './text';
import { styled } from './theme-provider';

const StyledFormInputHint = styled(Text)`
  color: ${({ theme }) => theme.color.neutral_neutral_64};
`;

export const FormInputHint = forwardRef<HTMLDivElement, PropsWithChildren>(
  function FormInputHint({ children }, ref) {
    return (
      <StyledFormInputHint
        as="div"
        appearance="_10M"
        whiteSpace="noWrap"
        ref={ref}
      >
        {children}
      </StyledFormInputHint>
    );
  }
);
