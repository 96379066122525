import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { DocumentFormSectionRow } from '../components';
import { DocumentFormat, DocumentType, MetodejDocument } from '../types';
import { getDefaultFormLayout } from '../utils/get-default-form-layout';
import { FormFields } from './form-fields';
import { ShowAdditionalFieldButton } from './show-additional-field-button';

export const FormCiteFields = observer(function FormCiteFields() {
  const formik = useFormikContext<MetodejDocument>();

  const { documentType, documentFormat } = formik.values;

  const [visibleFormFields, setVisibleFormFields] = useState(
    getDefaultFormLayout(
      documentFormat as DocumentFormat,
      documentType as DocumentType,
      formik.values
    )
  );
  const showAdditionalField = useCallback((name: keyof MetodejDocument) => {
    setVisibleFormFields(visibleFields => [...visibleFields, name]);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (documentFormat && documentType) {
      const visibleFields = getDefaultFormLayout(
        documentFormat as DocumentFormat,
        documentType as DocumentType,
        formik.values
      );

      setVisibleFormFields(visibleFields);
    }
  }, [documentType, documentFormat]);

  return (
    <>
      <FormFields visibleFields={visibleFormFields} />
      <DocumentFormSectionRow columns={2}>
        <ShowAdditionalFieldButton
          documentType={documentType as DocumentType}
          visibleFields={visibleFormFields}
          showAdditionalField={showAdditionalField}
        />
      </DocumentFormSectionRow>
    </>
  );
});
