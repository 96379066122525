import {
  Button,
  ButtonAppearance,
  ExternalLink,
  LayerContext,
  RadioInput as RadioInputComponent,
} from '@yarmill/component-library';
import { FileUploadStore } from '@yarmill/types';
import { useConfig, useFileUploadService, useIntlStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { DragEvent, useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BreadcrumbsWrapper,
  DocumentFormUploadListItem,
  SingleUploadFrame,
} from '../components';
import {
  AgreementLayout,
  Radio2LabelLayout,
  UploadFormAgreementHeadline,
} from '../components/upload-form-step-2';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { AgreementType } from '../types';
import { formatAuthorAgreementUrl } from '../utils/format-author-agreement-url';
import { uploadAgreementFile } from '../utils/upload-agreement-file';
import { validateFileTypes } from '../utils/validate-file-types';
import { AgreementFile } from './agreement-file';
import { Breadcrumbs } from './breadcrumbs';

const acceptedFileTypes = ['application/pdf'];

export const UploadFormStep2 = observer(function UploadFormStep2() {
  const intl = useIntl();
  const uploadStore = useDocumentsUploadStore();
  const publicationTermsUrl = useConfig('metodejPublicationTermsUrl');
  const cc0Licence = useConfig('metodejCc0Licence');
  const ccByLicence = useConfig('metodejCcByLicence');
  const publicationTermsSectionAUrl = useConfig(
    'metodejPublicationTermsSectionAUrl'
  );
  const agreementFormUrl = useConfig('metodejAuthorAgreementFormUrl');
  const intlStore = useIntlStore();
  const inputRef = useRef<HTMLInputElement>(null);
  const fileUploadService = useFileUploadService();
  const [selectedAgreementType, setSelectedAgreementType] =
    useState<AgreementType | null>(null);
  const [agreementFile, setAgreementFile] = useState<FileUploadStore | null>(
    null
  );
  const [invalidAgreementFile, setInvalidAgreementFile] = useState<File | null>(
    null
  );

  const applicationId = useConfig('metodejApplicationId');
  const layerContext = useContext(LayerContext);

  async function handleFileChange() {
    const input = inputRef.current;
    if (input?.files?.length) {
      const newFile = input?.files.item(0);
      if (newFile) {
        const { validFiles } = await validateFileTypes(
          [newFile],
          acceptedFileTypes
        );
        if (validFiles.length) {
          await uploadAgreementFile(
            newFile,
            fileUploadService,
            setAgreementFile,
            applicationId
          );
        } else {
          setInvalidAgreementFile(newFile);
        }
      }
    }
  }

  async function handleFileDrop(event: DragEvent<HTMLLabelElement>) {
    event.preventDefault();

    let file: File | null;
    if (event.dataTransfer.items) {
      const files = Array.from(event.dataTransfer.items)
        .map(item => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            return item.getAsFile();
          }
          return null;
        })
        .filter<File>((f): f is File => Boolean(f));
      file = files[0] ?? null;
    } else {
      file = event.dataTransfer.files.item(0);
    }

    if (file) {
      await uploadAgreementFile(
        file,
        fileUploadService,
        setAgreementFile,
        applicationId
      );
    }
  }

  function goToNextStep() {
    if (!selectedAgreementType) {
      return;
    }
    uploadStore.setAgreementType(selectedAgreementType);
    if (agreementFile?.uploadedFile) {
      uploadStore.setAgreementFile(agreementFile.uploadedFile);
    }
    uploadStore.uploadFiles(fileUploadService, applicationId);
    layerContext?.closeLayer();
    uploadStore.resetFormStep();
  }

  function removeInvalidAgreementFile() {
    setInvalidAgreementFile(null);
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs activeItem={2} />
      </BreadcrumbsWrapper>
      <UploadFormAgreementHeadline appearance="_14B" as="h2">
        {intl.formatMessage(
          { id: 'metodej.upload.form.agreement.headline' },
          {
            filesCount: uploadStore.filesToUpload.length,
            fileName: uploadStore.filesToUpload[0].name,
          }
        )}
      </UploadFormAgreementHeadline>
      <AgreementLayout>
        <RadioInputComponent
          label={intl.formatMessage<any, any>(
            {
              id: 'metodej.upload.form.agreement.option1.text',
            },
            {
              licenceLink: (text: string) => (
                <ExternalLink
                  href={publicationTermsSectionAUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </ExternalLink>
              ),
              cc0LicenceLink: (text: string) => (
                <ExternalLink
                  href={cc0Licence}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </ExternalLink>
              ),
              ccByLicenceLink: (text: string) => (
                <ExternalLink
                  href={ccByLicence}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </ExternalLink>
              ),
            }
          )}
          id="agreement-radio-1"
          name="agreement"
          value="0"
          checked={selectedAgreementType === 'bearer'}
          onChange={() => setSelectedAgreementType('bearer')}
        />
        <RadioInputComponent
          label={
            <Radio2LabelLayout>
              <span>
                {intl.formatMessage<any, any>(
                  {
                    id: 'metodej.upload.form.agreement.option2.text',
                  },
                  {
                    licenceLink: (text: string) => (
                      <ExternalLink href={publicationTermsUrl} target="_blank">
                        {text}
                      </ExternalLink>
                    ),
                  }
                )}
              </span>
              {selectedAgreementType === 'signedAgreement' && (
                <span>
                  {intl.formatMessage<any, any>(
                    {
                      id: 'metodej.upload.form.agreement.option2.formText',
                    },
                    {
                      formLink: (content: string) => (
                        <ExternalLink
                          href={formatAuthorAgreementUrl(
                            agreementFormUrl,
                            intlStore.locale
                          )}
                          target="_blank"
                        >
                          {content}
                        </ExternalLink>
                      ),
                    }
                  )}
                </span>
              )}
            </Radio2LabelLayout>
          }
          id="agreement-radio-2"
          name="agreement"
          value="1"
          checked={selectedAgreementType === 'signedAgreement'}
          onChange={() => setSelectedAgreementType('signedAgreement')}
        />
        {selectedAgreementType === 'signedAgreement' &&
          !agreementFile &&
          !invalidAgreementFile && (
            <SingleUploadFrame
              buttonText={intl.formatMessage({
                id: 'metodej.upload.form.agreement.uploadFrame.buttonLabel',
              })}
              primaryText={intl.formatMessage({
                id: 'metodej.upload.form.agreement.uploadFrame.primaryText',
              })}
              secondaryText={intl.formatMessage({
                id: 'metodej.upload.form.agreement.uploadFrame.secondaryText',
              })}
              onChange={handleFileChange}
              onDrop={handleFileDrop}
              inputRef={inputRef}
              accept={acceptedFileTypes.join(',')}
            />
          )}
        {selectedAgreementType === 'signedAgreement' && agreementFile && (
          <AgreementFile
            agreementFile={agreementFile}
            setAgreementFile={setAgreementFile}
          />
        )}
        {invalidAgreementFile && (
          <DocumentFormUploadListItem
            label={invalidAgreementFile.name}
            error
            errorText={intl.formatMessage({
              id: 'metodej.upload.form.chooseFiles.uploadList.file.invalidType',
            })}
            handleCancelClick={removeInvalidAgreementFile}
          />
        )}
      </AgreementLayout>
      <Button
        appearance={ButtonAppearance.Primary}
        onClick={goToNextStep}
        disabled={
          !selectedAgreementType ||
          (selectedAgreementType === 'signedAgreement' &&
            !agreementFile?.uploadedFile)
        }
      >
        {intl.formatMessage({
          id: 'metodej.upload.form.agreement.button.text',
        })}
      </Button>
    </>
  );
});
