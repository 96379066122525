import { Link } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { BreadcrumbsWrapper } from '../components';
import { UploadingDocument } from '../components/assets/uploading-document';
import { InfoPageLayout } from '../components/info-page-layout';
import { getMetodejUrl } from '../utils/get-metodej-url';
import { Breadcrumbs } from './breadcrumbs';

export const UploadingDocuments = observer(function UploadingDocuments() {
  const intl = useIntl();

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs activeItem={2.5} />
      </BreadcrumbsWrapper>
      <InfoPageLayout
        image={<UploadingDocument />}
        headline={intl.formatMessage({
          id: 'metodej.upload.form.uploadingFiles.headline',
        })}
        text={intl.formatMessage<any, any>(
          {
            id: 'metodej.upload.form.uploadingFiles.text',
          },
          {
            libraryLink: (content: string) => (
              <Link to={getMetodejUrl('library')}>{content}</Link>
            ),
          }
        )}
      />
    </>
  );
});
