import { Canvas, PanelToolbar } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { CancelUploadButton } from '../buttons/cancel-upload-button';
import { FormBackButton } from '../buttons/form-back-button';
import { FormGuideButton } from '../buttons/form-guide-button';
import { UploadCanvasLayout } from '../components';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { UploadCanvasTitle } from './upload-canvas-title';
import { UploadForm } from './upload-form';

interface UploadLayerProps {
  readonly closeLayer: () => void;
}
export const UploadLayer = observer(function UploadLayer({
  closeLayer,
}: UploadLayerProps) {
  const uploadStore = useDocumentsUploadStore();
  const currentFormStep = uploadStore.currentFormStep;
  const showFormBackButton = currentFormStep === 2;

  useEffect(() => {
    if (currentFormStep) {
      window.scrollTo(0, 0);
    }
  }, [currentFormStep]);

  return (
    <Canvas
      header={
        <PanelToolbar
          left={<>{showFormBackButton && <FormBackButton />}</>}
          center={<UploadCanvasTitle />}
          right={
            <>
              <FormGuideButton />
              <CancelUploadButton closeLayer={closeLayer} />
            </>
          }
        />
      }
      footer={<PanelToolbar position="footer" />}
    >
      <UploadCanvasLayout>
        <UploadForm />
      </UploadCanvasLayout>
    </Canvas>
  );
});
