import { Text, css, styled } from '@yarmill/component-library';
import { PropsWithChildren, ReactElement } from 'react';

type LibraryOverviewSectionProps = PropsWithChildren<{
  readonly headline: string;
  readonly showMoreButton: ReactElement;
  readonly columns: number;
}>;

const Layout = styled.section`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.size.x6};
    }
  `};
`;

const SectionHeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    margin-bottom: ${theme.size.x25};
  `};
`;

const ContentWrapper = styled.div<{ readonly $columns: number }>`
  display: grid;
  align-items: center;
  ${({ theme, $columns }) => css`
    gap: ${theme.size.x25};
    grid-template-columns: repeat(${$columns},1fr);
  `};
`;

export function LibraryOverviewSection({
  headline,
  showMoreButton,
  children,
  columns,
}: LibraryOverviewSectionProps) {
  return (
    <Layout>
      <SectionHeadlineWrapper>
        <Text as="h2" appearance="_16B">
          {headline}
        </Text>
        <Text as="h2" appearance="_14M">
          {showMoreButton}
        </Text>
      </SectionHeadlineWrapper>
      <ContentWrapper $columns={columns}>{children}</ContentWrapper>
    </Layout>
  );
}
