import { StyledButton } from './button';
import { css, styled } from './theme-provider';

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

  & > ${StyledButton} {
    border-radius: 0;
  }
  ${({ theme }) => css`
    & > ${StyledButton}:first-child {
      border-top-left-radius: ${theme.borderRadius.x1};
      border-bottom-left-radius: ${theme.borderRadius.x1};
    }
    & > ${StyledButton}:last-child {
      border-top-right-radius: ${theme.borderRadius.x1};
      border-bottom-right-radius: ${theme.borderRadius.x1};
    }
  `}

`;
