export function UploadFrameImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="81"
      viewBox="0 0 144 81"
      fill="none"
    >
      <path
        d="M125.341 11.0199C123.916 11.0921 122.493 11.2442 121.08 11.4488L122.605 9.50127C123.247 8.68428 122.046 7.62227 121.4 8.44331C120.712 9.32057 120.024 10.1978 119.336 11.0751C119.102 10.0639 118.879 9.04822 118.675 8.02739C118.589 7.59957 118.036 7.3944 117.659 7.52332C117.203 7.67672 117.057 8.11681 117.143 8.54464C117.375 9.71195 117.632 10.8661 117.906 12.0267C116.141 12.4117 114.401 12.8901 112.694 13.4765C111.704 13.8154 112.208 15.3443 113.194 15.0019C114.505 14.5495 115.832 14.1682 117.173 13.8431C116.652 14.5071 116.127 15.1752 115.609 15.8427C114.967 16.6597 116.168 17.7217 116.814 16.9007C117.399 16.1561 117.984 15.404 118.569 14.6594C118.813 15.5786 119.065 16.4972 119.331 17.4071C119.625 18.3989 121.157 17.8817 120.863 16.8899C120.511 15.6891 120.186 14.4826 119.871 13.2715C121.708 12.9425 123.562 12.7225 125.43 12.6233C126.467 12.5703 126.374 10.9635 125.337 11.0164L125.341 11.0199Z"
        fill="#FC7B9B"
      />
      <path
        d="M131.942 38.6065C130.767 37.5959 129.57 36.6097 128.367 35.6392C130.049 35.4121 131.746 35.3058 133.422 35.5624C134.444 35.7198 134.767 34.1335 133.741 33.9802C131.615 33.652 129.473 33.8349 127.355 34.1683C127.298 32.7796 127.24 31.391 127.189 30.0018C127.148 28.9659 125.539 29.0775 125.58 30.1134C125.628 31.2631 125.676 32.4127 125.72 33.5588C124.767 32.8297 123.811 32.1121 122.84 31.4033C122.009 30.8026 120.933 32.015 121.768 32.6192C122.687 33.2898 123.595 33.965 124.496 34.6559C122.908 34.9373 121.325 35.2298 119.738 35.5111C119.308 35.5866 118.953 35.9005 118.98 36.3704C119.007 36.7681 119.401 37.1935 119.831 37.118C121.826 36.7627 123.82 36.3923 125.819 36.0481C125.876 37.4863 125.937 38.928 125.994 40.3662C126.035 41.4021 127.644 41.2905 127.603 40.2545C127.558 39.1655 127.515 38.0841 127.47 36.9951C128.614 37.9203 129.754 38.8572 130.869 39.8148C131.651 40.4873 132.723 39.2714 131.941 38.5989L131.942 38.6065Z"
        fill="#FC7B9B"
      />
      <path
        d="M87.0047 76.231C84.5815 76.231 82.1346 75.9297 79.7511 75.3033C70.5829 72.9087 66.2053 66.5416 62.6858 61.4352C59.5873 56.9315 57.1404 53.3792 51.6505 51.2463C49.9345 50.5802 44.7148 48.5504 39.8128 50.5485C34.1244 52.8638 31.8204 59.2071 31.2484 61.1022L16.0342 56.5271C17.1226 52.9352 21.6035 40.8353 33.8145 35.8716C40.9569 32.9616 48.9016 33.1678 57.4184 36.4822C67.4606 40.3833 72.2672 47.3689 75.7788 52.4674C78.8534 56.9315 80.4741 59.112 83.7712 59.9763C88.0216 61.0864 93.2413 59.7543 95.4023 57.0108C98.1273 53.5378 97.6507 45.9417 92.1211 39.2495L104.388 29.1636C114.208 41.0573 115.693 56.8839 107.915 66.7874C103.212 72.7819 95.2275 76.239 87.0047 76.239V76.231Z"
        fill="#4A4962"
      />
      <path
        d="M14.9449 58.5412C13.4592 61.9904 11.9656 65.4396 10.4799 68.8887C9.76487 70.5459 9.0419 72.1952 8.33482 73.8603C7.73896 75.2717 7.21461 76.8179 7.74691 78.3403C8.17592 79.5693 9.2723 80.6397 10.6229 80.6873C11.9735 80.7349 13.0222 79.8865 13.777 78.8557C14.7383 77.5474 15.3977 75.9695 16.1525 74.5422C17.074 72.7899 17.9877 71.0455 18.9093 69.2931L16.7324 69.0077C17.8924 71.7829 21.9124 72.7899 23.978 70.3318L22.1031 70.094C23.0723 71.5688 24.9394 71.886 26.4886 71.1248C28.2205 70.2763 28.8482 68.2861 29.2693 66.5576C29.6347 65.0748 27.3387 64.4405 26.9732 65.9232C26.7508 66.843 26.5442 67.9293 25.8768 68.6509C25.5193 69.0394 24.5501 69.4834 24.1608 68.8967C23.7715 68.3099 22.8102 68.0403 22.2858 68.6588C21.5072 69.5786 19.5608 69.6658 19.0205 68.3813C18.663 67.517 17.368 67.0967 16.8437 68.0958C15.4613 70.7204 14.0868 73.3449 12.7044 75.9615C12.3708 76.5959 11.7113 78.2848 10.7977 78.3165C9.59803 78.3561 9.90788 76.4214 10.1065 75.795C10.5276 74.455 11.1949 73.1705 11.7511 71.878C12.3072 70.5856 12.8792 69.2614 13.4433 67.9531C14.6271 65.2175 15.8029 62.482 16.9867 59.7464C17.5905 58.3588 15.5328 57.1457 14.929 58.5491L14.9449 58.5412Z"
        fill="#FC7B9B"
      />
      <path
        d="M104.244 27.2287C104.642 25.3336 105.198 23.3989 105.404 21.48C105.563 20.0211 105.007 18.3322 103.339 18.1419C101.829 17.9595 100.216 19.1092 99.1597 20.0845C97.9759 21.1708 97.1417 22.5822 96.673 24.1125H98.969C98.7545 22.7011 98.1189 21.4721 97.1735 20.4096C96.6412 19.8149 95.6878 20.0449 95.2985 20.6475C93.7096 23.1293 92.637 25.9124 92.1762 28.8224L94.52 29.1396C94.7027 27.5538 93.8605 26.0472 92.494 25.2543C91.9061 24.9134 91.2229 25.1512 90.8654 25.6825C88.3866 29.3299 87.8066 34.1112 89.3717 38.2343C89.912 39.6537 92.216 39.0352 91.6678 37.6C90.3092 34.016 90.7621 30.0594 92.9231 26.8798L91.2944 27.308C91.9935 27.7123 92.2319 28.3625 92.1365 29.1396C91.9856 30.4558 94.2657 30.7809 94.4802 29.4568C94.9172 26.7291 95.8785 24.1839 97.3642 21.8527L95.4892 22.0906C96.1645 22.8518 96.5141 23.7398 96.665 24.7389C96.8478 25.9679 98.6512 25.7618 98.9611 24.7389C99.2471 23.8112 99.6999 22.9549 100.359 22.2412C100.717 21.8527 101.106 21.4959 101.551 21.2025C101.821 21.0201 102.504 20.4968 102.83 20.5444C102.933 20.5603 102.981 20.4889 103.005 20.5047C103.061 20.5603 103.045 20.9805 103.045 21.0043C103.045 21.5118 102.894 22.0192 102.79 22.5188L101.94 26.6023C101.631 28.0929 103.927 28.7352 104.236 27.2366L104.244 27.2287Z"
        fill="#FC7B9B"
      />
      <path
        d="M77.8683 14.867C76.7878 16.2387 75.7073 17.6104 74.6268 18.9822C74.1104 19.6403 73.594 20.2984 73.0776 20.9565C72.5612 21.6147 71.9494 22.249 71.5681 22.9785C70.8054 24.4216 71.2105 26.0709 72.4658 27.1016C73.8164 28.2117 75.5563 28.2117 77.2168 28.069C77.8603 28.0135 78.4085 27.5615 78.4085 26.8796C78.4085 26.277 77.8603 25.6347 77.2168 25.6903C76.2237 25.7775 74.8651 25.9995 74.023 25.3176C73.7846 25.1194 73.5145 24.7863 73.5225 24.5009C73.5384 24.0172 74.0786 23.5097 74.3567 23.1529C76.0886 20.9486 77.8206 18.7522 79.5525 16.5479C79.9498 16.0405 80.0451 15.3586 79.5525 14.867C79.1394 14.4546 78.2655 14.3595 77.8683 14.867Z"
        fill="#FC7B9B"
      />
      <path
        d="M68.1654 16.437C68.1654 16.437 68.1654 16.3814 68.1733 16.3577L68.1336 16.6748C68.1574 16.54 68.1892 16.4052 68.2448 16.2784L68.1256 16.5638C68.1733 16.4607 68.2289 16.3735 68.2845 16.2784C68.3719 16.1436 68.1018 16.4925 68.1733 16.4211C68.1971 16.3973 68.213 16.3735 68.2369 16.3497C68.2766 16.3101 68.3163 16.2704 68.356 16.2387C68.3719 16.2229 68.3958 16.207 68.4116 16.1911C68.4832 16.1198 68.1336 16.3894 68.2686 16.2942C68.3163 16.2625 68.356 16.2308 68.4037 16.1991C68.507 16.1356 68.6103 16.0801 68.7215 16.0405L68.4355 16.1594C68.65 16.0722 68.8724 16.0167 69.1028 15.985L68.7851 16.0246C68.9996 16.0008 69.2141 16.0008 69.4365 16.0246L69.1187 15.985C69.3174 16.0088 69.508 16.0643 69.6908 16.1356L69.4047 16.0167C69.4921 16.0564 69.5795 16.1039 69.659 16.1515C69.7623 16.2229 69.8338 16.3418 69.5636 16.0722C69.5954 16.1039 69.6272 16.1277 69.659 16.1594C69.6908 16.1911 69.7146 16.2229 69.7464 16.2467C69.9132 16.4211 69.5875 16.0008 69.6749 16.1515C69.7305 16.2387 69.7781 16.3259 69.8258 16.4132L69.7066 16.1277L69.7225 16.1674C69.8497 16.4687 69.9768 16.7065 70.2707 16.881C70.525 17.0316 70.9063 17.0951 71.1923 16.9999C71.4783 16.9048 71.7564 16.7303 71.9073 16.4528C72.0583 16.1753 72.1457 15.8264 72.0265 15.533C71.8676 15.1683 71.7405 14.9146 71.4783 14.6053C71.3035 14.4071 71.089 14.2406 70.8666 14.0978C70.4058 13.8045 69.8735 13.6855 69.3412 13.638C68.364 13.5507 67.2914 13.9234 66.6002 14.6132C66.0997 15.1128 65.8534 15.7392 65.7819 16.429C65.7502 16.7145 65.9329 17.0792 66.1315 17.2695C66.3381 17.4757 66.6797 17.6343 66.9737 17.6184C67.2676 17.6025 67.6013 17.5074 67.8158 17.2695C68.0382 17.0237 68.1336 16.762 68.1654 16.429V16.437Z"
        fill="#FC7B9B"
      />
      <path
        d="M86.7512 17.2143C86.7512 17.1667 86.7512 17.1271 86.7671 17.0795L86.7274 17.3967C86.7512 17.2619 86.783 17.135 86.8386 17.0081L86.7194 17.2936C86.775 17.1667 86.8465 17.0557 86.926 16.9447L86.7433 17.1826C86.8386 17.0637 86.9419 16.9526 87.0611 16.8575L86.8227 17.0399C86.9578 16.9368 87.0928 16.8575 87.2517 16.7861L86.9657 16.9051C87.0928 16.8575 87.22 16.8178 87.355 16.802L87.0372 16.8416C87.1564 16.8258 87.2756 16.8258 87.3947 16.8416L87.0769 16.802C87.212 16.8178 87.3391 16.8575 87.4662 16.9051L87.1802 16.7861C87.3153 16.8496 87.4424 16.9209 87.5695 17.0161L87.3312 16.8337C87.4503 16.9289 87.5536 17.0399 87.649 17.1588L87.4662 16.9209C87.5536 17.0399 87.6331 17.1747 87.6887 17.3095L87.5695 17.024C87.6172 17.1509 87.6569 17.2777 87.6807 17.4125L87.641 17.0954C87.6569 17.2302 87.6569 17.365 87.641 17.4998C87.6092 17.7852 87.7999 18.1499 87.9906 18.3402C88.1972 18.5464 88.5388 18.705 88.8327 18.6891C89.1267 18.6733 89.4604 18.5781 89.6749 18.3402L89.8576 18.1024C89.9688 17.92 90.0165 17.7138 90.0165 17.4998C90.1039 16.7465 89.7702 16.0091 89.3015 15.4382C88.8327 14.8673 88.0939 14.5263 87.3471 14.4708C86.6003 14.4153 85.8614 14.7483 85.3053 15.2399C84.7491 15.7316 84.4313 16.469 84.3837 17.2143C84.3439 17.8328 84.9557 18.4275 85.5754 18.4037C86.2507 18.372 86.7274 17.8804 86.7671 17.2143H86.7512Z"
        fill="#FC7B9B"
      />
      <path
        d="M82.9541 31.4946C82.9382 31.566 82.8111 31.9148 82.8667 31.788C82.7952 31.9386 82.7157 32.0734 82.6283 32.2082C82.6045 32.2479 82.5171 32.3589 82.5966 32.2637C82.5489 32.3272 82.4933 32.3906 82.4377 32.4461C82.3264 32.565 82.2073 32.676 82.0801 32.787C82.1755 32.7078 82.0563 32.8029 82.0245 32.8267C81.953 32.8743 81.8895 32.9218 81.818 32.9694C81.675 33.0566 81.524 33.1359 81.381 33.2152C81.3492 33.2311 81.2062 33.3024 81.3174 33.2549C81.238 33.2866 81.1586 33.3183 81.0791 33.3421C80.8964 33.4055 80.7136 33.461 80.5309 33.5007C78.9579 33.8733 77.1465 33.3262 75.5654 32.8743C74.9458 32.6998 74.2704 33.0884 74.0957 33.7068C73.9209 34.3253 74.3102 34.9914 74.9299 35.1737C77.0114 35.7605 79.2598 36.3472 81.4048 35.7367C83.1765 35.2292 84.7575 33.9526 85.2501 32.1289C85.4169 31.5105 85.0435 30.8365 84.4159 30.662C83.7883 30.4876 83.1209 30.8682 82.9461 31.4946H82.9541Z"
        fill="#FC7B9B"
      />
      <path
        d="M64.5348 27.6413C63.4304 32.9459 66.7593 38.5994 71.979 40.1456C73.4488 40.5817 74.0844 38.2902 72.6146 37.8541C68.6581 36.6806 66.0125 32.2323 66.8308 28.2756C66.9579 27.6492 66.6481 26.9911 65.9966 26.8088C65.4087 26.6502 64.6619 27.007 64.5268 27.6413H64.5348Z"
        fill="#FC7B9B"
      />
      <path
        d="M68.5639 10.1572C68.4526 7.75464 68.8578 5.45519 69.7794 3.22709L67.7853 3.75042C68.7863 4.95565 69.7794 6.16088 70.7804 7.37404C71.5352 8.29382 72.3932 9.61799 73.7836 9.31668C75.0309 9.04709 75.7618 7.50091 76.4371 6.56527C77.4143 5.20938 78.3915 3.84557 79.3608 2.48968H77.3031C78.463 4.84464 79.6309 7.1996 80.7908 9.54663C81.1166 10.2127 82.1732 10.3316 82.6658 9.7845C84.7155 7.49298 86.5905 5.05873 88.2827 2.48968L86.1059 2.20423C86.9877 4.51162 87.8617 6.819 88.7435 9.11846C89.0772 9.99859 90.0067 10.2365 90.7377 9.64178C92.4776 8.21453 94.3287 6.93001 96.2672 5.78821L94.5194 5.07459C94.9563 7.35818 95.0914 9.65764 94.9722 11.973C94.8928 13.5033 97.2762 13.4953 97.3556 11.973C97.4907 9.44355 97.3 6.93001 96.8234 4.44025C96.6803 3.67906 95.6952 3.36189 95.0755 3.72663C92.9622 4.97944 90.9601 6.39083 89.0613 7.95287L91.0555 8.47619C90.1736 6.16881 89.2997 3.86143 88.4178 1.56197C88.0523 0.602543 86.8288 0.380527 86.2409 1.27652C84.6599 3.67113 82.9121 5.94679 80.9974 8.08767L82.8724 8.32554C81.7124 5.97058 80.5445 3.61562 79.3846 1.26859C78.9556 0.396385 77.8036 0.602543 77.3269 1.26859C76.6516 2.21216 75.9763 3.1478 75.301 4.09137C74.9594 4.55919 74.6257 5.03494 74.2841 5.50276C74.0616 5.812 73.6008 6.7635 73.2671 6.91415C73.5532 6.78728 73.4181 6.9538 73.3148 6.68421C73.2433 6.49391 72.9652 6.26396 72.8381 6.10538C72.4806 5.66927 72.1231 5.23317 71.7576 4.805C71.0029 3.88521 70.2402 2.97336 69.4854 2.05358C68.8499 1.28445 67.7932 1.84742 67.4913 2.5769C66.5062 4.97151 66.0692 7.54848 66.1963 10.1413C66.2678 11.6637 68.6512 11.6716 68.5797 10.1413L68.5639 10.1572Z"
        fill="#FC7B9B"
      />
      <path
        d="M15.6341 33.1411C14.0597 33.2209 12.4862 33.389 10.9245 33.6151L12.61 31.4626C13.3196 30.5597 11.9923 29.3859 11.2787 30.2933C10.5182 31.2629 9.7577 32.2325 8.9972 33.2021C8.7385 32.0845 8.49215 30.9619 8.26652 29.8336C8.17165 29.3608 7.56053 29.134 7.14353 29.2765C6.63967 29.4461 6.47847 29.9325 6.57334 30.4053C6.82944 31.6955 7.11417 32.9711 7.41615 34.2538C5.46604 34.6793 3.54298 35.2081 1.65634 35.8563C0.562264 36.2308 1.11952 37.9206 2.20916 37.5422C3.65807 37.0422 5.12418 36.6208 6.60654 36.2614C6.03023 36.9953 5.44998 37.7337 4.87811 38.4715C4.16847 39.3745 5.49577 40.5483 6.20935 39.6408C6.85628 38.8179 7.50271 37.9866 8.14963 37.1637C8.41925 38.1796 8.69726 39.1948 8.99156 40.2006C9.31638 41.2967 11.0096 40.7251 10.6847 39.6289C10.2959 38.3017 9.93627 36.9682 9.58894 35.6297C11.6184 35.266 13.6676 35.0229 15.7329 34.9132C16.8791 34.8547 16.7758 33.0787 15.6296 33.1372L15.6341 33.1411Z"
        fill="#FC7B9B"
      />
      <path
        d="M19.7517 15.731C19.2828 14.6196 18.8346 13.5026 18.3781 12.3861C19.1795 12.8436 19.9695 13.3229 20.7363 13.8374C21.1308 14.1044 21.6469 14.1443 21.9945 13.7501C22.2829 13.4231 22.3204 12.7644 21.9214 12.4935C21.4174 12.1542 20.9061 11.8322 20.3907 11.5147C21.3868 11.5549 22.3809 11.6331 23.3665 11.7834C24.4923 11.9535 24.8537 10.2041 23.7196 10.0347C21.9821 9.7725 20.2155 9.65956 18.4536 9.7019C19.1864 8.83517 19.9414 7.98793 20.7363 7.17577C21.5475 6.35406 20.2123 5.18923 19.4051 6.00646C18.5385 6.89088 17.7115 7.802 16.9244 8.7482C16.5048 7.68806 16.0852 6.62793 15.6824 5.56663C15.2752 4.50143 13.5776 5.06922 13.9892 6.13831C14.3221 7.01101 14.6713 7.87416 15.0081 8.74237C14.3477 8.46437 13.6794 8.19532 13.0002 7.95646C12.5488 7.79853 12.0332 7.91 11.8034 8.37594C11.6078 8.77644 11.7538 9.40555 12.2096 9.56739C12.76 9.76471 13.3115 9.97878 13.855 10.2018C13.6675 10.2359 13.4879 10.2609 13.3004 10.295C12.3515 10.487 11.4297 10.7822 10.5005 11.0234C9.74383 10.7562 8.82129 11.3291 9.11272 12.2131C9.43072 13.192 10.51 12.8521 11.2216 12.6345C12.3141 12.3064 13.4328 12.0647 14.5544 11.8734C14.135 12.4828 13.7206 13.1046 13.3275 13.7291C13.0691 14.1381 13.3307 14.7256 13.7336 14.9205C14.2017 15.1488 14.6681 14.9145 14.9304 14.501C15.4045 13.749 15.9009 13.0164 16.4234 12.2988C16.9642 13.6323 17.5094 14.9697 18.0669 16.3021C18.2525 16.7434 18.7067 17.0231 19.1899 16.8592C19.6192 16.7117 19.9502 16.1756 19.7601 15.7304L19.7517 15.731Z"
        fill="#FC7B9B"
      />
      <path
        d="M48.025 26.8869C47.5446 26.0833 47.0967 25.2606 46.6775 24.4234C47.3448 24.7472 48.0121 25.071 48.6833 25.3903C49.7101 25.8868 50.5052 24.2798 49.4699 23.7838C48.8416 23.4784 48.2093 23.1774 47.581 22.8719C48.6103 22.6869 49.6362 22.5148 50.6719 22.3673C51.148 22.3006 51.5385 21.9202 51.5093 21.4176C51.483 20.9652 51.0487 20.5201 50.5686 20.5913C49.4618 20.748 48.361 20.9337 47.265 21.1317C47.7889 20.5823 48.3389 20.0478 48.9077 19.5458C49.272 19.2219 49.1629 18.5776 48.8346 18.2892C48.4396 17.9423 47.9408 18.0526 47.5764 18.3765C46.7115 19.143 45.9047 19.9686 45.1507 20.8326C45.1507 20.8326 45.1507 20.8326 45.1462 20.8287C44.9503 20.283 44.6537 19.6727 44.7962 19.0824C45.064 17.9662 43.3182 17.6331 43.0504 18.7494C42.8842 19.438 43.0336 20.1258 43.2404 20.7843C42.5386 20.4461 41.8367 20.1079 41.1349 19.7697C40.1081 19.2732 39.3131 20.8802 40.3483 21.3761C40.9332 21.6593 41.5181 21.9426 42.1031 22.2258C41.1482 22.4603 40.1939 22.7032 39.2494 22.9706C38.139 23.2831 38.6958 24.9646 39.8022 24.6565C40.8133 24.3761 41.8298 24.1163 42.8474 23.8733C42.4142 24.5341 42.0071 25.2101 41.6351 25.9088C41.0877 26.9224 42.6862 27.6905 43.2336 26.6768C43.6669 25.8729 44.1485 25.1035 44.6606 24.353C45.2192 25.5591 45.8395 26.7399 46.525 27.8826C47.1168 28.8635 48.6045 27.8728 48.0216 26.8997L48.025 26.8869Z"
        fill="#FC7B9B"
      />
    </svg>
  );
}
