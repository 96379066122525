import { MouseEventHandler, ReactElement } from 'react';
import { css, styled } from './theme-provider';

interface TotemProps {
  readonly logo: ReactElement;
  readonly avatar: ReactElement;
  readonly sideMenuToggle: ReactElement;
  readonly handleAvatarClick?: MouseEventHandler;
  readonly avatarAriaLabel?: string;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
`;

const TopContainer = styled.div`
  flex-grow: 0;
  ${({ theme }) => css`
    background-color: ${({ theme }) => theme.color.background_background_04};
    padding: ${theme.size.x3} ${theme.size.x2} ${theme.size.x4};
    border-bottom-right-radius: ${theme.size.x1};
  `};
`;

const LogoWrapper = styled.div`
  ${({ theme }) => css`
    border: 0;
    width: ${theme.size.x4};
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x1};
  `};
`;

export const TotemButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: relative;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x1};
    padding: ${theme.size.x1} ${theme.size.x15};
    color: ${({ theme }) => theme.color.neutral_neutral};
    background-color: ${theme.color.background_background_03};
    width: ${({ theme }) => theme.size.x5};

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }

    :hover {
      color: ${({ theme }) => theme.color.neutral_neutral_dark};
    }
  `};
`;

const SideMenuToggleWrapper = styled.div`
  flex-grow: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideMenuToggleBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  ${({ theme }) => css`
    width: ${theme.size.x5};
    height: ${theme.size.x5};
    background-color: ${({ theme }) => theme.color.background_background_04};
  `};`;

const BottomContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.size.x3} ${theme.size.x2};
    border-top-right-radius: ${theme.size.x1};
    background-color: ${({ theme }) => theme.color.background_background_04};
  `};
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  cursor: pointer;

  ${({ theme }) => css`
    padding: 0;
    border: 0;
    width: ${theme.size.x4};
    height: ${theme.size.x4};

    :hover {
      background-color: ${theme.color.background_background_05};
    }
  `};
`;

export function Totem({
  logo,
  avatar,
  sideMenuToggle,
  handleAvatarClick,
  avatarAriaLabel,
}: TotemProps): ReactElement {
  return (
    <Layout>
      <TopContainer>
        <LogoWrapper>{logo}</LogoWrapper>
      </TopContainer>
      <SideMenuToggleWrapper>
        <SideMenuToggleBackground />
        {sideMenuToggle}
      </SideMenuToggleWrapper>
      <BottomContainer>
        <AvatarWrapper
          role="button"
          onClick={handleAvatarClick}
          aria-label={avatarAriaLabel}
        >
          {avatar}
        </AvatarWrapper>
      </BottomContainer>
    </Layout>
  );
}
