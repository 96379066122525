import { Multiply } from '@yarmill/icon-library';
import { MouseEventHandler } from 'react';
import { ToastOptions, toast as reactToastify } from 'react-toastify';
import { Badge, BadgeAppearance } from './badge';
import { MiniButton } from './mini-button';
import { Text } from './text';
import { ThemeProvider, css, styled } from './theme-provider';

const TOAST_MESSAGE_WIDTH = 292;

interface ToastMessageProps {
  readonly text: string;
  readonly appearance: BadgeAppearance;
  readonly handleCloseClick: MouseEventHandler;
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: ${TOAST_MESSAGE_WIDTH}px;

  ${({ theme }) => css`
    column-gap: ${theme.size.x1};
    border-radius: ${theme.size.x1};
    background-color: ${theme.color.neutral_neutral_darker};
    color: ${theme.color.neutral_neutral_2};
    padding: ${theme.size.x2} ${theme.size.x25};
  `};
`;

const CloseButton = styled(MiniButton)`
  ${({ theme }) => css`
    margin-left: ${theme.size.x15};
  `};
`;

export function ToastMessage({
  appearance,
  text,
  handleCloseClick,
}: ToastMessageProps) {
  return (
    <Layout>
      <Badge size={16} appearance={appearance} />
      <Text appearance="_12M">{text}</Text>
      <CloseButton type="button" onClick={handleCloseClick}>
        <Multiply />
      </CloseButton>
    </Layout>
  );
}

const defaultOptions: ToastOptions = {
  autoClose: 5000,
  closeOnClick: true,
  icon: false,
  position: 'bottom-right',
  closeButton: false,
  hideProgressBar: true,
};

export function showToastMessage(text: string, appearance: BadgeAppearance) {
  return reactToastify(
    ({ closeToast }) => (
      <ThemeProvider theme="master" dark>
        <ToastMessage
          text={text}
          appearance={appearance}
          handleCloseClick={closeToast}
        />
      </ThemeProvider>
    ),
    defaultOptions
  );
}
